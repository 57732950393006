import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';


export const useIsSearchInDocs = (filterDefaultValues, resource) => {
	const isSearchInDocs = useSelector(state => (state.admin.resources[resource] &&
		!isEmpty(state.admin.resources[resource].list.params.filter) ?
		state.admin.resources[resource].list.params.filter["is_search_in_docs"] :
		filterDefaultValues && filterDefaultValues["is_search_in_docs"]))
	return isSearchInDocs;
};
