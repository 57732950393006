import React, { useState } from "react";
import {
	List, Datagrid, TextField, 
    Pagination, Button, Confirm,
    useRefresh, useNotify, 
} from 'react-admin';

import { Grid } from "@material-ui/core"

import useUpdatePartial from "../../../components/_extensions/react-admin/core/dataProvider/useUpdatePartial";

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DocumentItem } from "../../../components/fields/DocumentsField/Item"
import { stylesForColorInheritInList } from "../../../components/_helpers/useStyles";
import { SelectEnumField } from "../../../components/fields/SelectEnumField"
import { LinkFieldToOther } from '../../../components/fields/LinkField';

import { ProcessStatus, ParsedStatus } from "../../../entities/"
import { EntityDocumentStatusFilter } from "./ListFilters"


const RunParseButton = ({ record }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const stringResource =  "osfc.dialogs.confirm_run_parse";
   
    const [update, { loading }] = useUpdatePartial(
        "entity-document-status",
        record["id"],
        {process_status: ProcessStatus.running},
        record,
        {
            withDeclarativeSideEffectsSupport: true,
            onSuccess: ({ data }) => {
                refresh();
                notify(stringResource + ".success", "info");
            },
            onFailure: (error) => {
                notify(error.message, "error");
            }
        },
        "updatePartial"
    );

    const handleClick = (e) => {
        e.stopPropagation();
		if (record.parsed_status === ParsedStatus.done){
			setOpen(true);
		} else {
			update();	
		}
        
    };
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        update();
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                label="osfc.buttons.run_parse"
				disabled={record.process_status == ProcessStatus.running}
                onClick={handleClick}
                color="primary" variant="outlined"
            />
            <Confirm
                isOpen={open}
                loading={loading}
                confirm="osfc.buttons.confirm"
                title={stringResource + ".confirm.title"}
                content={stringResource + ".confirm.content"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </React.Fragment>
    );
};

const DocumentExpander = ({ record }) => (
    <Grid key={record.id} item xs={12} sm={10} md={8} lg={6}>
        <DocumentItem 
            isReadOnly={true} 
            data={{ ...record, relatedEntityId: record.entity_id }}
            resource="entities"
        />
    </Grid>
);


export const EntityDocumentStatusList = (props) => {
	const classes = stylesForColorInheritInList();
		
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 200, 500]} />}
			filters={<EntityDocumentStatusFilter />}
			classes={classes}
		>
			<ScrollingWrapper>
				<Datagrid
					expand={DocumentExpander}
				>
					<TextField source="id" />
                    <LinkFieldToOther source="entity_id" otherResource="entitys" otherIdProp="entity_id" />
					<SelectEnumField source="parsed_status" enumName="parsedstatus" enumObj={ParsedStatus} />
					<SelectEnumField source="process_status" enumName="processstatus" enumObj={ProcessStatus} />
					<RunParseButton />
				</Datagrid>
			</ScrollingWrapper> 
		</List>
	);
};
