import React from 'react';
import { useInput, useTranslate, useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { filterColors } from '../../utils/constants';
import { FilterToggleButtonGroup, StyledToggleButton } from './FilterToggleInput';


const useStyles = makeStyles({
	filterTerminal: {
		backgroundColor: filterColors.terminal
	},
});


export const TERMINAL_ALL_VALUE = "all";


export const ToggleTerminalsFilter = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { input } = useInput(props);
	const dataProvider = useDataProvider();
	const [ terminalData, setTerminalData ] = React.useState([]);
	const value = input.value || [TERMINAL_ALL_VALUE];

	React.useEffect(() => {
		dataProvider.getList("terminal", {
			pagination: {page: 0, perPage: -1},
			sort: {field: 'id', order: 'ASC'},
			filter: {},
		}).then((data) => {
			setTerminalData(data.data);
		});
	}, [ dataProvider ]);

	const handleChange = (e, _newValues) => {
		input.onChange([_newValues]);
	};

	return (
		<React.Fragment>
			<FilterToggleButtonGroup 
				label={input.label} 
				className={classes.filterTerminal} 
				value={value} 
				exclusive
				onChange={handleChange} 
			>              
				{terminalData.map((terminal) => (
					<StyledToggleButton value={terminal.id}>
						{translate(`osfc.filters.terminal.${terminal.name.toLowerCase()}`)}
					</StyledToggleButton>
				))}
				{!props.exclude_all && <StyledToggleButton value={TERMINAL_ALL_VALUE}>
					{translate('osfc.filters.terminal.all')}
				</StyledToggleButton>}				
			</FilterToggleButtonGroup>
		</React.Fragment>
	);
};
