import React from 'react';

import { Filter, useTranslate, useListContext } from 'react-admin';

import { ToggleTerminalsFilter } from '../../../components/filters/ToggleTerminalsFilter';
import { GroupingPeriodFilter } from './GroupingPeriodFilter';
import {DateInput} from '../../../components/inputs/DateTimeInput';
import { getDateWithDayOffset, getDateWithMonthOffset } from '../../../utils/datetime';


export const RevenueAndExpensesChartFilter = (props) => {
	const translate = useTranslate()
	const { filterValues, setFilters } = useListContext();

	const onGroupingPeriodChange = (groupingPeriod) => {
		let newDates = {};

		if (groupingPeriod[0] === 'month') {
			newDates = {
				"date_from": getDateWithMonthOffset(12, true),
				"date_to": getDateWithMonthOffset(),
			};
		} else if (groupingPeriod[0] === 'week') {
			newDates = {
				"date_from": getDateWithDayOffset(7 * 12, true),
				"date_to": getDateWithDayOffset(),
			};
		} else if (groupingPeriod[0] === 'day') {
			newDates = {
				"date_from": getDateWithDayOffset(7, true),
				"date_to": getDateWithDayOffset(),
			};
		}

		setFilters({
			...filterValues,
			grouping_period: groupingPeriod, 
			...newDates,
		});
	};

	return ( <Filter {...props}>
		<GroupingPeriodFilter source="grouping_period" defaultValue={null} onChange={onGroupingPeriodChange} alwaysOn key={"group_period"}/>
		<ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"}/>
		{[
			<DateInput 
				source="date_from"
				label={translate("osfc.filters.dates.date_from")}
				margin="dense"
				key={"date_from"}
				alwaysOn
			/>,
			<DateInput
				source="date_to"
				label={translate("osfc.filters.dates.date_to")}
				margin="dense"
				key={"date_to"}
				alwaysOn
			/>,
		 ]
		}
	</Filter>
)};
