import React from 'react';

import Popover from '@material-ui/core/Popover';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { useTranslate } from 'react-admin';


export const LoadTimePopover = (props) => {
	const translate = useTranslate();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		e.preventDefault();
		e.stopPropagation();
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div style={{ textAlign: "center" }}>
			<IconButton size="small" aria-describedby={id} color="default" onClick={handleClick}>
				<AccessAlarmIcon fontSize="inherit" />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
				}}
				transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
				}}
			disableScrollLock={true} 

			>
				<DialogContent>
					<Table size="small">
						<TableBody>
							{Object.keys(props.data).map((key, index) => (
								<TableRow key={index}>
								<TableCell font>{translate("resources.order-pricelist.load_time_popover.fields."+key)}</TableCell>
								<TableCell>{props.data[key]}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
			</Popover>
		</div>
	);
};
