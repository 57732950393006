import React from 'react';
import { SelectInput } from 'react-admin';
import { 
  FlagEN, 
  FlagNO, 
  FlagDE, 
  FlagRU, 
  FlagPL, 
  FlagFR, 
  FlagES, 
  FlagIT, 
  FlagDA, 
  FlagNL, 
} from '../icons/flags';
import { makeStyles } from '@material-ui/core/styles';
import { Languages } from '../../entities/Languages';

const useStyles = makeStyles({
    selectContent: {
      display: 'flex',
      alignItems: 'center',
    },
  });

const SelectComponentContent = ({icon, text}) => {
  const classes = useStyles();
  return (
    <div className={classes.selectContent}>
      {icon}
      <span style={{marginLeft: "10px"}}>{text}</span>
    </div>
  );
};

const choices= [
    { id: Languages.en, name: <SelectComponentContent icon={<FlagEN />} text={"English"}/> },
    { id: Languages.no, name: <SelectComponentContent icon={<FlagNO />} text={"Norwegian"}/> },
    { id: Languages.de, name: <SelectComponentContent icon={<FlagDE />} text={"German"}/> },
    { id: Languages.ru, name: <SelectComponentContent icon={<FlagRU />} text={"Russian"}/> },
    { id: Languages.pl, name: <SelectComponentContent icon={<FlagPL />} text={"Polish"}/> },
    { id: Languages.fr, name: <SelectComponentContent icon={<FlagFR />} text={"French"}/> },
    { id: Languages.es, name: <SelectComponentContent icon={<FlagES />} text={"Spanish"}/> },
    { id: Languages.it, name: <SelectComponentContent icon={<FlagIT />} text={"Italian"}/> },
    { id: Languages.da, name: <SelectComponentContent icon={<FlagDA />} text={"Danish"}/> },
    { id: Languages.nl, name: <SelectComponentContent icon={<FlagNL />} text={"Dutch"}/> },
]

const LanguageSelect = ( props ) => {
  return (
    <SelectInput source="locale" choices={choices} autoFocus {...props} />
  );
};


export default LanguageSelect;