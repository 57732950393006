import React from "react";
import { List,
    Datagrid,
    TextField,
 } from "react-admin";
import { DeleteButton } from "react-admin";

const UserManual = (props) => {
    return (
        <List {...props}
        bulkActionButtons={false}
        exporter={false}
        >
            <Datagrid>
                <TextField source="id" />
                <TextField source="page_url" sortable={false}/>
                <TextField source="locale"/>
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

export default UserManual;