import React from "react";
import { Edit, CloneButton } from 'react-admin';

import Box from '@material-ui/core/Box';

import { useMutateForm } from '../../../api/useMutateForm';

import { SimpleFormAdv } from "../../../components/forms/SimpleFormAdv";
import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { DocumentsFieldBlock } from "../../../components/fields/DocumentsField";
import { usePermissions } from "react-admin";
import TaskForm from '../forms/TaskForm';
import { AppManagerRoles, UserRole } from "../../../entities";
import { is } from "date-fns/locale";
import { isInArray } from "../../../utils/general";

const ExtraActionButtons = ({ data, hasCloneRecurring, cloneMutableValues }) => (
    hasCloneRecurring && <Box ml={1} display="flex">
        <CloneButton 
            label="Recurring" 
            variant="outlined" 
            basePath='/recurring-tasks/create'
            record={{...data, ...cloneMutableValues}} 
        />
    </Box>
);


export const TaskEdit = (props) => {
    const {permissions} = usePermissions();
	const [mutateForm, state] = useMutateForm("update", 
        props.resource, props.basePath, props.id, {}); // eslint-disable-line

    if(!permissions) return null;

    const actionsProps = {
        hasShow: true,
        hasList: true,
        hasClone: true,
        hasCloneRecurring: permissions && isInArray(AppManagerRoles, permissions.role)
    };

    return (
        <React.Fragment>
            <Edit 
                {...props} undoable={false}
                actions={
                    <ActionsWithBackButton 
                        {...actionsProps} 
                        ExtraActionButtons={ExtraActionButtons}
                        cloneMutableValues={{
                            reference: null,
                            origin_id: props.id
                        }}
                    />
                }
            >
                <SimpleFormAdv save={mutateForm}>
                    <TaskForm isEditForm />
                </SimpleFormAdv>
            </Edit>
            <Edit {...props} undoable={false} actions={null}>
                <DocumentsFieldBlock variant="elevation"/>
            </Edit>
        </React.Fragment>
    );
};
