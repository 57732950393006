import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { DragStoreProvider, useDragStoreContext } from './DragStoreContext';


const DragAndDropWrapper = ({ children, onDragEnd, ...props }) => {
	const { dragRecordsState, recordsState, setRecordsState } = useDragStoreContext();
	const { resource } = props;
	const recordsDataById = useSelector(state => state.admin.resources[resource]);

	useEffect(() => {
		setRecordsState({
			ids: recordsDataById.list.ids,
			data: recordsDataById.data,
		});
	}, [ resource, recordsDataById?.list?.ids, recordsDataById?.data, setRecordsState ]);

	const handleOnDragEnd = useCallback((onDragProps) => {
		const { destination, source, draggableId } = onDragProps;

		if (!destination || (destination?.index === source?.index && 
			destination?.droppableId === source?.droppableId)) {
			return;
		}

		const foundRecordId = dragRecordsState[destination.index];
		const dropRecord = recordsState.data[foundRecordId];
		const dragRecord = recordsState.data[draggableId];

		onDragEnd && onDragEnd({ 
			...onDragProps, 
			resource,
			dropRecord,
			dragRecord,
		});
	}, [ resource, onDragEnd, dragRecordsState, recordsState?.data ]);

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable" type="ORDER">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {React.cloneElement(children, props)}
						{provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};


const WrapperWithDragAndDrop = (props) => {
	const { isDraggable, children, onDragEnd, ...rest } = props;

	if (!isDraggable) return React.cloneElement(children, rest);

	return (
		<DragStoreProvider isDraggable={isDraggable}>
			<DragAndDropWrapper {...props} />
		</DragStoreProvider>
	);
};


export { 
	WrapperWithDragAndDrop as default,
};
