import React from "react";
import { Create, required } from 'react-admin';
import { DateInput } from "../../../components/inputs";
import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm/';
import { SimpleFormWithWarningsDialog } from "../../../components/forms/SimpleFormWithWarningsDialog";
import { WarningPopover } from "../../../components/popovers/WarningPopover";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useSelector } from 'react-redux';


export const isInvoicesSelector = (state) => {
    return state.admin.resources['invoices'] &&
           state.admin.resources['invoices'].data &&
           Object.keys(state.admin.resources['invoices'].data).length > 0;
}

const InvoiceForm = ({...props}) => {
    const is_record = useSelector(isInvoicesSelector);

    return(
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput sm={6} component={DateInput} source="date_from" validate={[!is_record && required()]} autoFocus />
                <GridInput sm={6} component={DateInput} source="date_to" />
            </FormGroupGrid>
        </GridForm>
    )
}


const LinkButton = ({resource, id}) => {
    return (
        <Link href={'/#/' + resource + '/'+ id + '/show'} target="_blank">
            {id}
        </Link>
    )
}


export const PopupContent = ({data, ...props}) => {
    return(
        <List>
            {data.map(item => {
                      return(
                           <ListItem>
                               <Grid container spacing={3}>
                                   <Grid item >
                                       <ListItemText secondary={<LinkButton id={item.order_id} resource={"orders"}/>} /> 
                                   </Grid>
                                   <Grid item >
                                       <ListItemText primary={item.warning_message} />
                                   </Grid>
                               </Grid>
                           </ListItem>)
                       })}
        </List>    
    )
}


export const InvoiceCreate = ({permissions, ...props}) => {
    
    return (
		<Create {...props}>
            <SimpleFormWithWarningsDialog 
                Form={InvoiceForm}
                Dialog={WarningPopover}
                PopupContent={PopupContent}
                redirect_url={'list'}
                title={'osfc.dialogs.invoices.title'}
                {...props}
            />
		</Create>
	);
}