import React from 'react';
import { useInput, useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { filterColors } from '../../utils/constants';
import { FilterToggleButtonGroup, StyledToggleButton } from './FilterToggleInput';
import { NotifTypeVeluesList } from '../../entities/NotifType';

const useStyles = makeStyles({
	filterNotifLog: {
		backgroundColor: filterColors.terminal
	},
});


export const NOTIF_LOG_ALL_VALUE = "all";

// TODO: think about some shared component for this one and ToggleTerminalsFilter
export const ToggleNotifTypeFilter = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { input } = useInput(props);
	const value = input.value || [NOTIF_LOG_ALL_VALUE];

	const handleChange = (e, _newValues) => {
		input.onChange([_newValues]);
	};

	return (
		<React.Fragment>
			<FilterToggleButtonGroup 
				label={input.label} 
				className={classes.filterNotifLog} 
				value={value} 
				exclusive
				onChange={handleChange} 
			>              
				{NotifTypeVeluesList.map((item) => (
					<StyledToggleButton value={item.id}>
						{item.value.toLowerCase()}
					</StyledToggleButton>
				))}
				{!props.exclude_all && <StyledToggleButton value={NOTIF_LOG_ALL_VALUE}>
					{translate('osfc.filters.terminal.all')}
				</StyledToggleButton>}				
			</FilterToggleButtonGroup>
		</React.Fragment>
	);
};
