import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { TerminalsEditForm } from './EditForm';


export const TerminalsEdit = ( props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
            <TerminalsEditForm />
        </SimpleForm>
    </Edit>
);
