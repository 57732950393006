// INFO: sequential is important!
const Languages = {
	en: 'en',
	no: 'no',
	de: "de",
	ru: "ru",
	pl: "pl",
	fr: "fr",
	es: "es",
	it: "it",
	da: "da",
	nl: "nl",
};

// INFO: sequential is important!
const locales = Object.keys(Languages);


export {
	Languages,
	locales,
};
