import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DATE_TIME_FORMAT, DATE_FORMAT, format, parseISO } from '../../../components/_helpers/dateTime';
import { isNullUndEmpty }from "../../../utils/general"
import { toPrice } from '../../../components/fields/PriceField';

const prepareDatetime = (datetime) =>{
    return format(parseISO(datetime), DATE_TIME_FORMAT);
}  

const prepareDate = (datetime) =>{
    return format(parseISO(datetime), DATE_FORMAT);
}  

const prepareTime = (datetime) =>{
    if (! isNullUndEmpty(datetime)){
        return new Date(datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return datetime
}  

const prepareTotalCostWithCalculationInfo = (mainCostPerHour, additionaCost, totalCost, totalHours) => {
	if (totalCost === null || totalCost === undefined) {
		return totalCost;
	}
	return 'Nkr (' +  mainCostPerHour + ' * ' + totalHours + ' + ' + additionaCost + ') * 1.141 * 1.102 * 1.02 = ' + totalCost ;
}


export const TisbankenPlaningDatagrid = ({tisbankenPlaningData}) => {
    const translate = useTranslate()

    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');

    React.useEffect(()=>{
        setOrderBy('date')
    }, [tisbankenPlaningData])

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedTisbankenPlaningData = tisbankenPlaningData.slice().sort((a, b) => {   
        if (orderBy === 'employee_id') {
            return orderDirection === 'asc'
                ? a.employee_id - b.employee_id
                : b.employee_id - a.employee_id;
        } else {   
            return orderDirection === 'asc'
                ? a[orderBy].toString().localeCompare(b[orderBy].toString())
                : b[orderBy].toString().localeCompare(a[orderBy].toString());
        }
    });

    const totalHours = tisbankenPlaningData.reduce((sum, item) => {
        if (item.employee_id === 0 || item.work_type >= 100){
            return sum
        }
        return sum + item.total_hours
    }, 0);
    
    const totalPauseHours = tisbankenPlaningData.reduce((sum, item) => {
        if (item.employee_id === 0 || item.work_type >= 100){
            return sum
        }
        return sum + item.total_pause_hours
    }, 0);

    const totalMainCost = tisbankenPlaningData.reduce((sum, item) => {
        if (item.employee_id === 0 || item.work_type >= 100){
            return sum
        }
        return sum + item.main_cost
    }, 0);

    const totalAdditionalCost = tisbankenPlaningData.reduce((sum, item) => {
        if (item.employee_id === 0 || item.work_type >= 100){
            return sum
        }
        return sum + item.additional_cost
    }, 0);

    const totalCost = tisbankenPlaningData.reduce((sum, item) => {
        if (item.employee_id === 0 || item.work_type >= 100){
            return sum
        }
        return sum + item.total_cost
    }, 0);
    
    return (
        <Table  size="small" aria-label="a dense table">
            <TableHead>
            <TableRow style={{backgroundColor:"#d8e6db"}}>
                <TableCell  align="left">
                    <TableSortLabel
                                    active={orderBy === 'date'}
                                    direction={orderBy === 'date' ? orderDirection : 'asc'}
                                    onClick={() => handleSortRequest('date')}
                                >
                        {translate('resources.tisbanken-planing-data.fields.date')}
                    </TableSortLabel>
                </TableCell>
                <TableCell  align="left">
                    <TableSortLabel
                                    active={orderBy === 'employee_id'}
                                    direction={orderBy === 'employee_id' ? orderDirection : 'asc'}
                                    onClick={() => handleSortRequest('employee_id')}
                                >
                        {translate('resources.tisbanken-planing-data.fields.employee_id')}
                    </TableSortLabel>
                </TableCell>
                <TableCell  align="left">
                    <TableSortLabel
                                    active={orderBy === 'employee_name'}
                                    direction={orderBy === 'employee_name' ? orderDirection : 'asc'}
                                    onClick={() => handleSortRequest('employee_name')}
                                >
                        {translate('resources.tisbanken-planing-data.fields.employee_name')}
                    </TableSortLabel>
                </TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break1_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break1_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break2_from')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.clock_break2_to')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.department_code')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.work_type')}</TableCell>
                <TableCell align="left">{translate('resources.tisbanken-planing-data.fields.work_type_name')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.total_hours')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.total_pause_hours')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.main_cost')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.additional_cost')}</TableCell>
                <TableCell align="right">{translate('resources.tisbanken-planing-data.fields.total_cost')}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {sortedTisbankenPlaningData.map((row) => (
                <TableRow key={row.name}>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareDate(row.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {row.employee_id}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {row.employee_name}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareDatetime(row.clock_from_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareDatetime(row.clock_to_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareTime(row.clock_break1_from_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareTime(row.clock_break1_to_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareTime(row.clock_break2_from_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {prepareTime(row.clock_break2_to_with_ttz)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {row.department_code}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {row.work_type}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}}>
                        {row.work_type_name}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}} align="right">
                        {row.total_hours}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}} align="right">
                        {row.total_pause_hours}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}} align="right">
                        {toPrice(row.main_cost)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}} align="right">
                        {toPrice(row.additional_cost)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || row.work_type >= 100 ? {textDecoration: "line-through"} : {}} align="right">
                        {prepareTotalCostWithCalculationInfo(row.main_cost, row.additional_cost, row.total_cost, row.total_hours)}
                    </TableCell>
                </TableRow>
            ))}
            <TableRow style={{fontWeight: "bold", backgroundColor: "#dadfed"}}>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}}>{translate("resources.tisbanken-planing-data.total")}</TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{totalHours.toFixed(2)}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{totalPauseHours.toFixed(2)}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{toPrice(totalMainCost.toFixed(2))}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{toPrice(totalAdditionalCost.toFixed(2))}</TableCell>
                <TableCell component="th" scope="row" style={{fontWeight: "bold", backgroundColor: "#dadfed"}} align="right">{toPrice(totalCost.toFixed(2))}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}