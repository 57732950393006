import React from "react";

import { Edit, SimpleForm } from 'react-admin';

import { AppManagerRoles, UserRole } from '../../../entities/';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { TrucksEditForm } from './EditForm';
import { isInArray } from "../../../utils/general";


export const TrucksEdit = ({permissions, ...props}) => {
	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Edit {...props} undoable={false}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
				<TrucksEditForm isAdminOrOSFCManager={isAdminOrOSFCManager} />
			</SimpleForm>
		</Edit>
	);
};
