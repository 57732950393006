import React from 'react';
import { Filter } from 'react-admin';
import { ToggleTerminalsFilter, } from '../../../components/filters/ToggleTerminalsFilter';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { DirectionType } from '../../../entities/DirectionType';
import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';

export const TrucksTrafficFilters = (props) => {
    return (
        <Filter {...props}>
            <ToggleTerminalsFilter source="terminal_id" defaultValue={null} alwaysOn key={"terminal_id"} />
            <SelectEnumInput
            source="vehicle_direction_type"
            key={"vehicle_direction_type"}
            enumName="vehicle_direction_type"
            resettable
            enumObj={DirectionType}
            />
            <TextInputEx label="License plate" source="vehicle_license_plate" alwaysOn={false} resettable key={"Truck"}/>
        </Filter>
    );
}