export const CountryCameraCodes = [
    { id: 1, name: 'Czech Republic' },
    { id: 2, name: 'France' },
    { id: 3, name: 'Germany' },
    { id: 4, name: 'Spain' },
    { id: 5, name: 'Italy' },
    { id: 6, name: 'Netherlands' },
    { id: 7, name: 'Poland' },
    { id: 8, name: 'Slovakia' },
    { id: 9, name: 'Belarus' },
    { id: 10, name: 'Moldova' },
    { id: 11, name: 'Russia' },
    { id: 12, name: 'Ukraine' },
    { id: 13, name: 'Belgium' },
    { id: 14, name: 'Bulgaria' },
    { id: 15, name: 'Denmark' },
    { id: 16, name: 'Finland' },
    { id: 17, name: 'United Kingdom' },
    { id: 18, name: 'Greece' },
    { id: 19, name: 'Croatia' },
    { id: 20, name: 'Hungary' },
    { id: 21, name: 'Israel' },
    { id: 22, name: 'Luxembourg' },
    { id: 23, name: 'Macedonia (changed to North Macedonia in 2018)' },
    { id: 24, name: 'Norway' },
    { id: 25, name: 'Portugal' },
    { id: 26, name: 'Romania' },
    { id: 27, name: 'Serbia' },
    { id: 28, name: 'Azerbaijan' },
    { id: 29, name: 'Georgia' },
    { id: 30, name: 'Kazakhstan' },
    { id: 31, name: 'Lithuania' },
    { id: 32, name: 'Turkmenistan' },
    { id: 33, name: 'Uzbekistan' },
    { id: 34, name: 'Latvia' },
    { id: 35, name: 'Estonia' },
    { id: 36, name: 'Albania' },
    { id: 37, name: 'Austria' },
    { id: 38, name: 'Bosnia and Herzegovina' },
    { id: 39, name: 'Ireland' },
    { id: 40, name: 'Iceland' },
    { id: 41, name: 'Vatican' },
    { id: 42, name: 'Malta' },
    { id: 43, name: 'Sweden' },
    { id: 44, name: 'Switzerland' },
    { id: 45, name: 'Cyprus' },
    { id: 46, name: 'Turkey' },
    { id: 47, name: 'Slovenia' },
    { id: 48, name: 'Montenegro' },
    { id: 49, name: 'Kosovo' },
    { id: 50, name: 'Andorra' },
    { id: 51, name: 'Armenia' },
    { id: 52, name: 'Monaco' },
    { id: 53, name: 'Liechtenstein' },
    { id: 54, name: 'San Marino' },
    { id: 55, name: 'Reserved' },
    { id: 59, name: 'China' },
    { id: 60, name: 'Bahrain' },
    { id: 61, name: 'South Korea' },
    { id: 62, name: 'Lebanon' },
    { id: 63, name: 'Nepal' },
    { id: 64, name: 'Thailand' },
    { id: 65, name: 'Pakistan' },
    { id: 66, name: 'United Arab Emirates' },
    { id: 67, name: 'Bhutan' },
    { id: 68, name: 'Oman' },
    { id: 69, name: 'North Korea' },
    { id: 70, name: 'Philippines' },
    { id: 71, name: 'Cambodia' },
    { id: 72, name: 'Qatar' },
    { id: 73, name: 'Kyrgyzstan' },
    { id: 74, name: 'Maldives' },
    { id: 75, name: 'Malaysia' },
    { id: 76, name: 'Mongolia' },
    { id: 77, name: 'Saudi Arabia' },
    { id: 78, name: 'Brunei' },
    { id: 79, name: 'Laos' },
    { id: 80, name: 'Japan' },
    { id: 82, name: 'Palestinian' },
    { id: 83, name: 'Tajikistan' },
    { id: 84, name: 'Kuwait' },
    { id: 85, name: 'Syria' },
    { id: 86, name: 'India' },
    { id: 87, name: 'Indonesia' },
    { id: 88, name: 'Afghanistan' },
    { id: 89, name: 'Sri Lanka' },
    { id: 90, name: 'Iraq' },
    { id: 91, name: 'Vietnam' },
    { id: 92, name: 'Iran' },
    { id: 93, name: 'Yemen' },
    { id: 94, name: 'Jordan' },
    { id: 95, name: 'Myanmar' },
    { id: 96, name: 'Sikkim' },
    { id: 97, name: 'Bangladesh' },
    { id: 98, name: 'Singapore' },
    { id: 99, name: 'Democratic Republic of Timor-Leste' },
    { id: 104, name: 'Egypt' },
    { id: 105, name: 'Libya' },
    { id: 106, name: 'Sudan' },
    { id: 107, name: 'Tunisia' },
    { id: 108, name: 'Algeria' },
    { id: 109, name: 'Morocco' },
    { id: 110, name: 'Ethiopia' },
    { id: 111, name: 'Eritrea' },
    { id: 112, name: 'Somalia Democratic' },
    { id: 113, name: 'Djibouti' },
    { id: 114, name: 'Kenya' },
    { id: 115, name: 'Tanzania' },
    { id: 116, name: 'Uganda' },
    { id: 117, name: 'Rwanda' },
    { id: 118, name: 'Burundi' },
    { id: 119, name: 'Seychelles' },
    { id: 120, name: 'Chad' },
    { id: 121, name: 'Central African' },
    { id: 122, name: 'Cameroon' },
    { id: 123, name: 'Equatorial Guinea' },
    { id: 124, name: 'Gabon' },
    { id: 125, name: 'Congo' },
    { id: 126, name: 'Democratic Republic of the Congo' },
    { id: 127, name: 'Sao Tome and Principe' },
    { id: 128, name: 'Mauritania' },
    { id: 129, name: 'Western Sahara' },
    { id: 130, name: 'Senegal' },
    { id: 131, name: 'Gambia' },
    { id: 132, name: 'Mali' },
    { id: 133, name: 'Burkina Faso' },
    { id: 134, name: 'Guinea' },
    { id: 135, name: 'Guinea-Bissau' },
    { id: 136, name: 'Cape Verde' },
    { id: 137, name: 'Sierra Leone' },
    { id: 138, name: 'Liberia' },
    { id: 139, name: 'Ivory Coast' },
    { id: 140, name: 'Ghana' },
    { id: 141, name: 'Togo' },
    { id: 142, name: 'Benin' },
    { id: 143, name: 'Niger' },
    { id: 144, name: 'Zambia' },
    { id: 145, name: 'Angola' },
    { id: 146, name: 'Zimbabwe' },
    { id: 147, name: 'Malawi' },
    { id: 148, name: 'Mozambique' },
    { id: 149, name: 'Botswana' },
    { id: 150, name: 'Namibia' },
    { id: 151, name: 'South Africa' },
    { id: 152, name: 'Lesotho' },
    { id: 153, name: 'Swaziland' },
    { id: 154, name: 'Comoros' },
    { id: 155, name: 'Madagascar' },
    { id: 156, name: 'Mauritius' },
    { id: 157, name: 'Libya' },
    { id: 158, name: 'Somalia' },
    { id: 159, name: 'Afghanistan' },
    { id: 160, name: 'Central African Republic' },
    { id: 161, name: 'Saudi Arabia' },
    { id: 162, name: 'Lesotho' },
    { id: 163, name: 'Chad' },
    { id: 164, name: 'Mauritius' },
    { id: 165, name: 'Western Sahara' },
    { id: 166, name: 'Cameroon' },
    { id: 167, name: 'Mauritania' },
    { id: 168, name: 'Niger' },
    { id: 169, name: 'Sao Tome' },
    { id: 170, name: 'Sierra Leone' },
    { id: 171, name: 'Central African Republic' },
    { id: 172, name: 'Tunisia' },
    { id: 173, name: 'Mali' },
    { id: 174, name: 'Swaziland' },
    { id: 175, name: 'Uganda' },
    { id: 176, name: 'Comoros' },
    { id: 177, name: 'Botswana' },
    { id: 178, name: 'Malawi' },
    { id: 179, name: 'Namibia' },
    { id: 180, name: 'Zambia' },
    { id: 181, name: 'Somalia' },
    { id: 182, name: 'Eritrea' },
    { id: 183, name: 'Ethiopia' },
    { id: 184, name: 'Mozambique' },
    { id: 185, name: 'Angola' },
    { id: 186, name: 'Zimbabwe' },
    { id: 187, name: 'Tanzania' },
    { id: 188, name: 'Madagascar' },
    { id: 189, name: 'Seychelles' },
    { id: 190, name: 'Mauritius' },
    { id: 191, name: 'Djibouti' },
    { id: 192, name: 'Rwanda' },
    { id: 193, name: 'Burundi' },
    { id: 194, name: 'Kenya' },
    { id: 195, name: 'Gabon' },
    { id: 196, name: 'Liberia' },
    { id: 197, name: 'Democratic Republic of the Congo' },
    { id: 198, name: 'Burkina Faso' },
    { id: 199, name: 'Benin' },
    { id: 200, name: 'Ivory Coast' },
    { id: 201, name: 'Ghana' },
    { id: 202, name: 'Togo' },
    { id: 203, name: 'Gambia' },
    { id: 204, name: 'Sierra Leone' },
    { id: 205, name: 'Guinea-Bissau' },
    { id: 206, name: 'Cape Verde' },
    { id: 207, name: 'Sao Tome and Principe' },
    { id: 208, name: 'Angola' },
    { id: 209, name: 'Botswana' },
    { id: 210, name: 'Lesotho' },
    { id: 211, name: 'Malawi' },
    { id: 212, name: 'Mauritius' },
    { id: 213, name: 'Mozambique' },
    { id: 214, name: 'Namibia' },
    { id: 215, name: 'South Africa' },
    { id: 216, name: 'Swaziland' },
    { id: 217, name: 'Zambia' },
    { id: 218, name: 'Zimbabwe' },
    { id: 257, name: 'Macau (China)' },
    { id: 258, name: 'Macau (China)' },
]