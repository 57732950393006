import React from 'react';

import { List } from 'react-admin';

import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';
import { RevenueAndExpensesChartFilter } from './ListFilters';
import { FinancialCharts } from './FinancialCharts';
import { GroupingPeriodEnum, GroupingPeriodFilter } from './GroupingPeriodFilter';
import { TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { getDateWithMonthOffset } from '../../../utils/datetime';

const defaultFilters = {
	"grouping_period":[GroupingPeriodEnum.MONTH],
	"terminal_id": [TERMINAL_ALL_VALUE],
	"date_from": getDateWithMonthOffset(12, true),
	"date_to": getDateWithMonthOffset(),
}

export const RevenueAndExpensesList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList();

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={<RevenueAndExpensesChartFilter />}
			classes={classes}
			pagination={false}
			// empty={<Empty/>}
			filterDefaultValues={defaultFilters}
		>
			<FinancialCharts {...props}/>	
		</List>
	);
};
