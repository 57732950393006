import React from 'react';
import { Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import {
	ordersResource,
	OrderEditByTransporter, OrderShowTabsWrapper
} from '../../resources/orders/';
import Messages from '../../resources/messages/List/List';
import { CompanySettingsPage } from '../../pages/settings/';


export const transporterEmployeeResources = [
	<Resource name="orders" list={ordersResource.list} edit={OrderEditByTransporter} show={OrderShowTabsWrapper}/>,
	<Resource name="ports" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	// <Resource name="drivers" {...customerDriversResource} />,
];

export const transporterEmployeeRoutes = [
	<Route exact path="/messages" component={Messages} />,
];
