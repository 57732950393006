import { useState,  useEffect } from "react";


export const useLocalStorage = (isRaw = false) => {
    const [storageData, setStorageData] = useState({});

    const initStorageData = (storage) => {
        let parsedData = {...storage}; 
        if (!isRaw) {
            Object.entries(storage).forEach(([key, value]) => {
                try {
                    parsedData[key] = JSON.parse(value);
                } catch {
                    parsedData[key] = value;
                };
            });
        }
        setStorageData(parsedData);
    };

    useEffect(() => {
        initStorageData(localStorage);

        const storageEventHandler = (e) => {
            initStorageData(e.target.localStorage);
        };

        window.addEventListener("storage", storageEventHandler);
        return () => {
            window.removeEventListener("storage", storageEventHandler);
        };
    }, []);

    return storageData;
};
