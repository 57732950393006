import React from 'react';
import { useInput, useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { filterColors } from '../../utils/constants';

import { FilterToggleButtonGroup, StyledToggleButton } from './FilterToggleInput';


export const ToggleDatesEnum = {
	LAST_WEEK: "last_week",
	YESTERDAY: "yesterday",
	THIS_MONTH: "this_month",
	THIS_WEEK: "this_week",
	TODAY: "today",
	TOMORROW: "tomorrow",
	ALL: "all",
};


const ALL_VALUE = [ToggleDatesEnum.ALL];

const useStyles = makeStyles({
	filterPeriod: {
		backgroundColor: filterColors.period
	},
	filterDay: {
		backgroundColor: filterColors.day
	},
	filterToday: {
		backgroundColor: filterColors.today
	},
	firstChild: {
		borderRadius: 0,
	},
	lastChild: {
		borderRadius: 0,
		borderRightWidth: 0,
	},
});


export const ToggleDatesFilter = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { input } = useInput(props);
	const value = input.value || ALL_VALUE;

	const handleChangeSingle = (e, newValues) => {
		input.onChange([newValues]);
	};

	return (
		<React.Fragment>
			<FilterToggleButtonGroup value={value} onChange={handleChangeSingle} label={input.label} exclusive className={classes.filterPeriod} >
				<StyledToggleButton value={ToggleDatesEnum.LAST_WEEK}>
					{translate('osfc.filters.dates.last_week')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesEnum.THIS_MONTH}>
					{translate('osfc.filters.dates.this_month')}
				</StyledToggleButton>
				<StyledToggleButton className={classes.lastChild} value={ToggleDatesEnum.THIS_WEEK}>
					{translate('osfc.filters.dates.this_week')}
				</StyledToggleButton>
				<StyledToggleButton className={classes.firstChild} value={ToggleDatesEnum.YESTERDAY}>
					{translate('osfc.filters.dates.yesterday')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesEnum.TODAY} className={classes.filterToday}>
					{translate('osfc.filters.dates.today')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesEnum.TOMORROW}>
					{translate('osfc.filters.dates.tomorrow')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesEnum.ALL}>
					{translate('osfc.filters.dates.all')}
				</StyledToggleButton>
			</FilterToggleButtonGroup>
		</React.Fragment>
	);
};
