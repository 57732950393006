import React from "react";

import { Create, SimpleForm } from 'react-admin';

import { AppManagerRoles, UserRole } from '../../../entities/';
import { usePermissions } from "react-admin";
import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { isInArray } from "../../../utils/general";
import { CreateVehicleAnprForm } from "./CreateForm";

export const TrucksCreate = (props) => {
    const { permissions } = usePermissions();
	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Create {...props}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
                <CreateVehicleAnprForm {...props} />
			</SimpleForm>
		</Create>
	);
};