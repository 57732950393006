export const SysNotifTypeNew = {
	email: 0, 
	sms: 1, 
    popup: 2,
};


export const SysNotifTypeChoicesNew = [
	{
		id: 2, 
		name: 'Popup', 
	}, 
	{
		id: 0, 
		name: 'Email', 
	}, 
	{
		id: 1, 
		name: 'SMS', 
	}, 
];


