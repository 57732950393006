import React from 'react';

import { Confirm } from 'react-admin';
import { useFormState } from 'react-final-form';

import SaveButtonEx from '../_extensions/react-admin/ra-ui-materialui/src/button/SaveButtonEx';
import { useTranslate } from 'react-admin';

const translateWarnings = (warnings, translate) => {
    return warnings.map(warning => translate(warning));
};

const getWarningsIfExist = (values, initialValues, warningValidations) => {
    const warnings = [];
    for (const [key, validator] of Object.entries(warningValidations)) {      
        const warningMsg = validator(values, initialValues);
        if (warningMsg) warnings.push(warningMsg);
    }
    return warnings ? warnings : null;
};

export const SaveWithWarningsButton = ({warningValidations, ...props}) => {
    const translate = useTranslate();
    const { values, initialValues } = useFormState();
    const [ confirmOpen, setConfirmOpen ] = React.useState(false);
    const [ warningsInfo, setWarningsInfo ] = React.useState();
    const [ customClickProp, setCustomClickProp ] = React.useState();
    const [ handleSubmitProps, setHandleSubmitProps ] = React.useState();
    const handleConfirmSave = () => {
		setConfirmOpen(false);
        setCustomClickProp(handleSubmitProps);
	};

    const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

    const handleSubmitWithWarnings = (onSaveProps) => {
        const warningsContent = getWarningsIfExist(values, initialValues, warningValidations);
        
        if (warningsContent && warningsContent.length) {            
            setWarningsInfo(translateWarnings(warningsContent, translate).join('\n'));
            setHandleSubmitProps(onSaveProps);
            setConfirmOpen(true);
        } else {
            setCustomClickProp(onSaveProps);
        }
    };

    return (
        <React.Fragment>
            <SaveButtonEx {...props} customHandleClick={handleSubmitWithWarnings} customClickProp={customClickProp} />
			<Confirm
				isOpen={confirmOpen}
				content={warningsInfo}
				title="osfc.dialogs.order_warnings.title"
                confirm="osfc.dialogs.order_warnings.actions.confirm"
                cancel="osfc.dialogs.order_warnings.actions.cancel"
				onConfirm={handleConfirmSave}
				onClose={handleConfirmClose}
			/>
        </React.Fragment>
    );
};
