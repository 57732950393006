import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

import { AutocompleteInput } from 'ra-ui-materialui/lib/input/AutocompleteInput';

import { DateInput } from '../../../components/inputs/DateTimeInput';
import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';


export const BackupLogPricelistFilter = (props) => (
	<Filter {...props}>
		<TextInputEx source='id' />

		<TextInputEx source='version' />
		<DateInput source='created_at' margin='dense' resettable />

		<ReferenceInput 
			source='created_by' 
			reference='users' 
			sort={{ field: 'first_name', order: 'ASC' }} 
			{...props} 
			filter={{ no_range: true, ...props.filter }}
		>
			<AutocompleteInput optionText='first_name' resettable />
		</ReferenceInput>

		<ReferenceInput 
			source='terminal_id' 
			reference='terminal' 
			resettable 
			filter={{ no_range: true }}
		>
				<SelectInput resettable />
			</ReferenceInput>

		<TextInputEx source='file_path' />
	</Filter>
);