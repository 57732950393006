import React from 'react';
import { FlagIcon } from './FlagIcon';

export const FlagRU = props => (
    <FlagIcon {...props} viewBox="0 0 512 512">
        <rect width="512" height="170.67" fill="#F0F0F0" />
        <rect width="512" height="170.67" y="170.67" fill="#0052B4" />
        <rect width="512" height="170.67" y="341.33" fill="#D80027" />
    </FlagIcon>
);
