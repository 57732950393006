import React from 'react';
import { Button, useTranslate } from 'react-admin';
import { ArrayChipField } from '../../../components/fields/ArrayChipField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	popover: {
		width:"65%"
	},
	dialogContent: {
		padding: "0"
	},
});


export const ReferenceArrayDialog = ({record}) => {
    const classes = useStyles();
    const translate = useTranslate()

	// const popoverAnchor = React.useRef(null);
    const [isOpenedPopover, setIsOpenedPopover] = React.useState(false)
	const handleClick = (e) => {
		// setAnchorEl(e.currentTarget);
        // popoverAnchor.current = e.currentTarget
        setIsOpenedPopover(true)
		e.preventDefault();
		e.stopPropagation();
	};
	const handleClose = (e) => {
		// setAnchorEl(null);
        // popoverAnchor.current = null
        setIsOpenedPopover(false)
		e.preventDefault();
		e.stopPropagation();
	};
	// const open = Boolean(anchorEl);
	// const id = isOpenedPopover ? 'simple-popover' : undefined;
	return (
		<div>
            <Button
                label='osfc.buttons.show'
                variant="outlined"
                color="secondary"
                onClick={handleClick}
                style={{paddingRight:"12px"}}
                // ref={popoverAnchor}
            />
            {/* INFO: We did'n use the Popover component due to incorrect positioning relative to the button with a large amount of data  */}
			{/* <Popover
				id={id}
				open={isOpenedPopover}
				anchorEl={popoverAnchor.current}
				onClose={handleClose}
				anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableScrollLock={true}
                className={classes.popover}
			> */}
            <Dialog open={isOpenedPopover} onClose={handleClose}>
                <DialogTitle>{translate('osfc.dialogs.invoices.title')} [{record.date_from}, {record.date_to}]</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <ArrayChipField resource={"orders"} ids={record['order_ids']}/>
                </DialogContent>
            </Dialog>        
			{/* </Popover> */}
		</div>
	);
};