import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

import { CompareArticlesPreviewButton } from '../../../../components/dialogs/CompareListsPreviewButton';


export const CompareWithPrevious = ({ data: record }) => {
    const [ previousRecord, setPreviousRecord ] = useState(null);

    const dataProvider = useDataProvider();

    const identityFields = {
        terminal_id: record.terminal_id,
    };

    useEffect(() => {
        dataProvider.getList('backup-log-pricelist', {
            pagination: {
                page: 0, 
                perPage: -1
            },
            sort: {
                field: 'id', 
                order: 'DESC'
            },
            filter: identityFields,
        }).then((data) => {
            if (data?.data) {
                const currentRecordIndex = data.data.indexOf(data.data.find(iterRecord => iterRecord.id === record.id));
                setPreviousRecord(data.data[currentRecordIndex + 1]);
            };
        });
    }, []);

    if (!previousRecord) return null;

    return (
        <CompareArticlesPreviewButton 
            compareButtonLabel='osfc.buttons.compare-backup-pricelist-with-previous'
            compareLeftPayload={{
                'endpoint': `backup-pricelist/${previousRecord.id}/articles-with-special`,
                'filter': { 'terminal_id': identityFields.terminal_id },

            }}
            compareRightPayload={{
                'endpoint': `backup-pricelist/${record.id}/articles-with-special`,
                'filter': { 'terminal_id': identityFields.terminal_id },
            }}
        />
    );
};
