import React from 'react';

import { Filter } from 'react-admin';

import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';
import { TimeZoneSelect } from '../../../components/inputs/TimeZoneSelect';


export const TerminalsFilter = (props) => (
    <Filter {...props}>
        <TextInputEx source='id' />
        <TextInputEx source='name' />
        <TextInputEx source='short_name' />
        <TextInputEx source='account_code' />
        <TimeZoneSelect source='time_zone' />
    </Filter>
);
