import React from 'react';

import { useLocation } from 'react-router-dom';
import { usePermissions, useRedirect, useGetList } from 'react-admin';

import { CircularProgress, makeStyles } from '@material-ui/core';

import { UserRole, OsfcRoles, AppRoles } from '../../../entities/UserRole';

import { OSFCEditPage } from './OSFCPage';


const useStyles = makeStyles(theme => ({
	loadingContainer: {
		position: 'relative', 
		top: '50%', 
		left: '50%',
	},
}));


export const OrdersMultipleChangesPage = (props) => {
	const { permissions } = usePermissions();
	const redirect = useRedirect();
	const location = useLocation();
	const classes = useStyles();

	const queryParams = new URLSearchParams(location.search);
	const selectedIdsParam = queryParams.get("selected_ids");
	const selectedIds = selectedIdsParam ? selectedIdsParam.split(',').map(Number) : [];
	
	if (selectedIds.length === 0) redirect('/');

	const resource = location.pathname.split("/")[1];
	const basePath = `/${resource}`;	

	const { data, loading } = useGetList(
		resource, 
		{ page: 0, perPage: -1 },
		{ field: 'id', order: 'DESC' },
		{ selected_ids: selectedIds },
	);

	if (loading) { 
		return (
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	if (!permissions) return null;

	const isOSFCUser = (OsfcRoles.indexOf(permissions.role) !== -1 || AppRoles.indexOf(permissions.role) !== -1);
	if (isOSFCUser) {
		return <OSFCEditPage basePath={basePath} resource={resource} 
							 selectedIds={selectedIds} recordsByIds={data} 
							 permissions={permissions} />
	}

	return null;
};
