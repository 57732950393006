import React, { useMemo } from 'react';
import { useTranslate, useListContext } from 'react-admin';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

import Box from '@material-ui/core/Box';

Chart.register(CategoryScale);


export const RevenueAndExpensesChart = ({chartData, ...props}) => {
    const translate = useTranslate()

    return (
      <Box maxHeight="750px" display={"flex"} justifyContent="center">
        <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                  title: {
                    display: true,
                    text: translate("resources.financial-statements.revenue_and_labor_cost_chart.label"),
                    font: {
                      size: 24 
                    }
                  },
              },
              scales: {
                y: {
                  type: 'linear', 
                  position: 'left',
                },
                y2: {
                  type: 'linear', 
                  position: 'right',
                }
            }
            }}
        />
    </Box>
    )
};
