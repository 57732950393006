import React from 'react';
import { useNotify } from 'react-admin';

export const useNotifySuccess = () => {
	var notify = useNotify();

    return React.useCallback((msg) => {
		notify(msg, {type: "success", autoHideDuration: 10000});
	}, [notify]);
}

