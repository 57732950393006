import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import { EntityDocumentStatusList } from './List/List';


export { EntityDocumentStatusList, };


export const entityDocumentStatusResource = {
	list: EntityDocumentStatusList,	
	icon: ViewColumnIcon,
};
