import React from "react";
import { Grid } from "@material-ui/core"

import { DocumentItem } from "./fields/DocumentsField/Item"


export const SearchResultDocumentsRowExpand = (props) => {	
	const data = props.record["documents"];
	const relatedEntityId = props.record["id"];

	return (		
		<Grid container spacing={2}>
			{data && data.map(item => 
				<Grid key={item.id} item xs={12} sm={6} md={3}>
					<DocumentItem 
						isReadOnly={true} 
						data={{ ...item, relatedEntityId: relatedEntityId, parsed_status: 1 }} 
						resource={props.resource} 
					/>
				</Grid>
			)}
		</Grid>
	);
};
