import React from "react";
import { TextInput, NumberInput } from 'react-admin';
import { required, minValue, minLength, maxLength } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { validateInteger } from "../../../utils/validation/general";
import { validateRequiredLoadKilo, validateRequiredLoadPallet  } from "../../../utils/validation/pricelistArticleTemplate";


const templateParse = (v) => {
	if (v) {
		v = v.substring(0, 4);
		const match = v.match(/[\d/*]{1,4}/g);
		// const match = v.match(/\d|x/gi);
		// const match = v.match(/^([\dxX]{1,4}).*$/gi);
		return match && match.join('');
	}
	return v;
};


export const ArticleTemplateBaseForm = props => {
	const { values } = useFormState();

	const isLoadTimeKiloEmpty =  !values['load_time_kilo'] && values['load_time_kilo'] !== 0;
    const isLoadTimePalletEmpty = !values['load_time_pallet'] && values['load_time_pallet'] !== 0;

    const isLoadTimeKiloDisabled = isLoadTimeKiloEmpty && !isLoadTimePalletEmpty;
    const isLoadTimePalletDisabled = isLoadTimePalletEmpty && !isLoadTimeKiloEmpty;

	return (
		<GridForm {...props}>		
			<GridInput sm={3} component={TextInput} source="template" parse={templateParse} validate={[required(), minLength(4), maxLength(4)]} autoFocus />
			<GridInput sm={3} component={NumberInput} source="load_time" min={0} step={0.05} validate={[required(), minValue(0)]} />
			<GridInput sm={3} component={NumberInput} disabled={isLoadTimeKiloDisabled} source="load_time_kilo" min={0} step={0.05}
					   validate={[validateRequiredLoadKilo(isLoadTimeKiloDisabled), minValue(0)]} />
			<GridInput sm={3} component={NumberInput} disabled={isLoadTimePalletDisabled} source="load_time_pallet" min={0} step={0.05} 
					   validate={[validateRequiredLoadPallet(isLoadTimePalletDisabled), minValue(0)]} />		
		</GridForm>
)};
