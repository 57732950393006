export const PopupMessageReason = {
    truck_arrival_ramp: 0,
    both_trucks_in_terminal: 1,
    truck_arrival_need_port: 2,
};

export const PopupMessageReasonOptions = [
    { id: PopupMessageReason.truck_arrival_ramp, name:  "truck_arrival_ramp"},
    { id: PopupMessageReason.both_trucks_in_terminal, name: "both_trucks_in_terminal"},
    { id: PopupMessageReason.truck_arrival_need_port, name: "truck_arrival_need_port"},
];

export const PopupMessageReasonNames = {
    [PopupMessageReason.truck_arrival_ramp]: "truck_arrival_ramp",
    [PopupMessageReason.both_trucks_in_terminal]: "both_trucks_in_terminal",
    [PopupMessageReason.truck_arrival_need_port]: "truck_arrival_need_port",
};