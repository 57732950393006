import React from 'react';
import { useDataProvider } from 'react-admin';

import { useDebouncedCallback } from 'use-debounce';


export const useAsyncColumnsStorage = (reference) => {
	const dataProvider = useDataProvider();
	const [columns, setColumns] = React.useState(undefined);

	React.useEffect(() => {
		dataProvider.getOne("user-data", {
			id: reference,
		}).then(({ data }) => {
			setColumns(data.data);
		});
	}, [dataProvider, reference]);

	const updateDatabase = useDebouncedCallback((resource, data) => {
		dataProvider.update("user-data", {
			id: reference,
			data: { data }
		});
	}, 3000);

	return React.useMemo(() => ({
		columns: columns,
		get() {
			return columns;
		},
		set(resource, data) {
			updateDatabase(resource, data);
		}
	}), [columns, updateDatabase]);
};
