import React from 'react';
import { Show, TabbedShowLayout } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';

import { GeneralTab, HistoryTab } from './Tabs'

import { DocumentsTab } from './Tabs';

const tabbedShowLayoutPadding = 8;

const EntityShow = (props) => (
	<TabbedShowLayout 
		style={{paddingBottom: tabbedShowLayoutPadding}} 
		{...props}
	>
		<GeneralTab 
			permissions={props.permissions} 
			label="tab.geneneral.name"
		/>

		<HistoryTab 
			permissions={props.permissions} 
			label="tab.history.name"
		/>

		<DocumentsTab 
			permissions={props.permissions} 
			label="tab.documents.name"
		/>
	</TabbedShowLayout>
);


export const EntityShowTabsWrapper = ({ permissions, ...props }) => {	
	if (!permissions) return null;

	return (
		<Show 
			actions={<ActionsWithBackButton hasList={true} />} 
			{...props}
		>
			<EntityShow permissions={permissions} />
		</Show>
	);
};
