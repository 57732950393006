import React from "react";
import {
	List, Datagrid, TextField, EmailField, EditButton,
} from 'react-admin';

import { PhoneField } from '../../../components/fields/PhoneField';
import { PriceCategoryField } from '../../../components/fields/PriceCategoryField';
import { YesNoField } from '../../../components/fields/YesNoField';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { CompaniesFilter } from './ListFilters';
// import { CompanyExpandPanel } from './ItemExpand';
import BlockUnblockButton from '../../../components/toolbars/BlockUnblockButton';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { CustomerCompanyActions } from "./Actions";

const defaultSort = { field: 'id', order: 'DESC' };


export const CompaniesList = props => {
	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={<CompaniesFilter />}
			sort={defaultSort}
			perPage={22}
			actions={<CustomerCompanyActions/>}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					// expand={<CompanyExpandPanel />}
					rowClick="show"
				>
					<TextField source="id" />
					<TextField source="full_name" />
					<TextField source="short_name" />
					<TextField source="accountancy_number" />
					<EmailField source="email" />
					<PhoneField source="phone" />
					<PriceCategoryField  source="price_category" />
					<YesNoField source="set_order_completed" />
					<YesNoField source="is_also_transporter" />
					<EditButton label="" />
					<BlockUnblockButton entity="company" />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
