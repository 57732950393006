import React from 'react';

import Box from '@material-ui/core/Box';

import { CompareWithPrevious } from './CompareWithPrevious';
import { CompareWithActual } from './CompareWithActual';


export const ExtraActionButtons = (props) => ([
    <Box ml={1} display='flex' key={1}>
        <CompareWithPrevious {...props} />
    </Box>,
    <Box ml={1} display='flex' key={2}>
        <CompareWithActual {...props} />
    </Box>
]);
