import React from 'react';

// import ExportIcon from '@material-ui/icons/TableChart';
import ExportIcon from '@material-ui/icons/GetApp';

import { ToolbarButton } from './ToolbarButton';

import { ProgressIcon } from '../../ProgressIcon';
import { fetchDataForExport, fetchDataForExport2 } from './DataExportTable';
import { httpClientRaw } from '../../../api/httpClient';


export const ExportServerButton = ({ filters, label, type, endpoint, sort }) => {
	const [loading, setLoading] = React.useState(false);
	const anchor = React.useRef();

	React.useEffect(() => {
		anchor.current = document.createElement("a");
		document.body.appendChild(anchor.current);
		return () => {
			document.body.removeChild(anchor.current);
		}
	}, []);

	const exportFile = () => {
		setLoading(true);
		fetchDataForExport(httpClientRaw, endpoint, filters, type, sort)
			.then(res => res.blob())
			.then(blobby => {
				setLoading(false);
				const objectUrl = window.URL.createObjectURL(blobby);

				anchor.current.href = objectUrl;
				anchor.current.download = `orders.${type}`;
				anchor.current.click();

				window.URL.revokeObjectURL(objectUrl);
			})
			.catch(err => {
				console.error(err);				
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<ToolbarButton onClick={exportFile} icon={loading ? <ProgressIcon /> : <ExportIcon />}>{label}</ToolbarButton>
	);
}

// Should be removed after merge
export const ExportServerButton2 = ({ filters, label, type, endpoint, sort, filename, disabled }) => {
	const [loading, setLoading] = React.useState(false);
	const anchor = React.useRef();

	React.useEffect(() => {
		anchor.current = document.createElement("a");
		document.body.appendChild(anchor.current);
		return () => {
			document.body.removeChild(anchor.current);
		}
	}, []);

	const exportFile = () => {
		setLoading(true);
		fetchDataForExport2(httpClientRaw, endpoint, filters, type, sort)
			.then(res => res.blob())
			.then(blobby => {
				setLoading(false);
				const objectUrl = window.URL.createObjectURL(blobby);

				anchor.current.href = objectUrl;
				anchor.current.download = `${filename || endpoint}.${type}`;
				anchor.current.click();

				window.URL.revokeObjectURL(objectUrl);
			})
			.catch(err => {
				console.error(err);				
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<ToolbarButton disabled={disabled} onClick={exportFile} icon={loading ? <ProgressIcon /> : <ExportIcon />}>{label}</ToolbarButton>
	);
}


export const ExportServerCSVButton = (props) => (
	<ExportServerButton {...props} type="csv" label="CSV" />
);

export const ExportServerXLSXButton = (props) => (
	<ExportServerButton {...props} type="xlsx" label="XLSX" />
);
