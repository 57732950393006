import React from 'react';
import { Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import {
	ordersResource,
	OrderCreateByCustomer,
	OrderEditByCustomerTransporter,
	OrderShowTabsWrapper
} from '../../resources/orders/';

import { pricelistArticlesResource } from '../../resources/customer-pricelist-articles';
// import { customerCompaniesResource } from '../../resources/customer-companies/';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';
import Messages from '../../resources/messages/List/List';
import { CompanySettingsPage } from '../../pages/settings/';
import { apiKeysRouts } from '../../pages/settings/APIKeys';


export const customerAndTransporterEmployeeResources = [
	<Resource name="orders" {...ordersResource} edit={OrderEditByCustomerTransporter} create={OrderCreateByCustomer} show={OrderShowTabsWrapper} />,
	<Resource {...pricelistArticlesResource} name="customer-pricelist-articles" />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="drivers" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
];

export const customerAndTransporterEmployeeRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
];
