import React from "react";
import {
    TextInput, required
} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import { GridForm, GridInput } from '../../../components/GridForm';
import { PhoneInput } from "../../../components/inputs";
import { NotifType } from "../../../entities/NotifType";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { EmailInput } from "../../../components/inputs";
const requiredValidator = [required()];

export const SMSCreateForm = props => {
    const form = useForm();
    const { values, initialValues } = useFormState();
    
    const handleTypeChange = (e) => {
        const value = e.target.value;
        if (value === NotifType.sms) {
            form.change("recipient", "");
        } else if (value === NotifType.email) {
            form.change("recipient", "");
        }
    }
    return(
	<GridForm {...props}>
		{values.type == NotifType.sms &&<GridInput sm={4} component={TextInput} source="sender" />}
        <GridInput sm={4} component={SelectEnumInput} source="type"
        key={"notif.type"}
        enumName="notif.type"
        resettable
        enumObj={NotifType} onChange={handleTypeChange} defaultValue={NotifType.sms}/>

        {values.type == NotifType.sms && <GridInput sm={4} source="recipient" component={PhoneInput} validate={requiredValidator}/>}
        {values.type == NotifType.email &&<GridInput sm={4} source="recipient" component={EmailInput} validate={requiredValidator}/>}

        <GridInput sm={10} component={TextInput} source="text" multiline rows={6}/>
	</GridForm>
)};
