import React from "react";
import { Filter, } from 'react-admin';
import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { locales } from "../../../entities/Languages";


export const CustomersFilter = props => (
	<Filter {...props}>
		<TextInputEx source="_query" alwaysOn />
		<TextInputEx source="key" />
		{locales.map(locale => 
			<TextInputEx source={locale} />
		)}
	</Filter>
);
