import { useState, useEffect } from 'react';

import { useMutation } from 'react-admin';
import { cloneDeep } from 'lodash';

import { useSimpleModal } from '../../components/dialogs/useSimpleModal';
import { listCalcDiff } from '../../components/DiffList';

import { useNotifyError } from '../notifiers/useNotifyError';


export const useCompareLists = ({ compareLeftPayload, compareRightPayload }) => {
    const [ isLoading, setIsLoading ] = useState(false);
	const [ compareLeftData, setCompareLeftData ] = useState(null);
	const [ compareRightData, setCompareRightData ] = useState(null);
	const [ compareData, setCompareData ] = useState(null);

    const notifyError = useNotifyError();
    const compareModal = useSimpleModal();

    const [getCompareLeftData, { loading: loadingCompareLeftData }] = useMutation({
        type: 'getManyReference',
        resource: compareLeftPayload.endpoint,
		payload: compareLeftPayload.payload,
    }, {
		onSuccess: (res) => {
			setCompareLeftData(res.data);
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const [getCompareRightData, { loading: loadingCompareRightData }] = useMutation({
        type: 'getManyReference',
        resource: compareRightPayload.endpoint,
		payload: compareRightPayload.payload,
    }, {
		onSuccess: (res) => {
			setCompareRightData(res.data);
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	useEffect(() => {
		let _data;
        if (compareLeftData && compareRightData) {
			_data = cloneDeep(compareRightData);
            listCalcDiff(
                compareLeftData, 
                _data, 
                { root: 'code', special_prices: 'company_id'}, 
                'root', 
                { special_prices: ['company_name'] },
            );
		}
		setCompareData(_data);
    }, [ compareLeftData, compareRightData ]);

    useEffect(() => {
        const loading = loadingCompareLeftData && loadingCompareRightData;
        setIsLoading(loading);
        loading && compareModal.handleOpen();
    }, [ compareModal, loadingCompareLeftData, loadingCompareRightData ]);

    const onOpen = () => {
        getCompareLeftData();
        getCompareRightData();
	};

    const onClose = () => {
        // setIsLoading(false);
        // setCompareLeftData(null);
        // setCompareRightData(null);
        // setCompareData(null);
        compareModal.handleClose();
    };

    console.log(compareRightData)

    return {
        isLoading, setIsLoading,
        compareLeftData, setCompareLeftData,
        compareRightData, setCompareRightData,
        compareData, setCompareData,
        compareModal,
        getCompareLeftData, loadingCompareLeftData,
        getCompareRightData, loadingCompareRightData,
        onOpen, onClose,
    }
}