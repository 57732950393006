import React from 'react';


export const useClock = (generator, props) => {
    const [time, setTime] = React.useState(generator(props));

    React.useEffect(() => {
      setInterval(() => {
       setTime(generator(props));
      }, 1000);
    }, []);

    return time;
};
