import MenuBookIcon from '@material-ui/icons/MenuBook';

import { AdminActionCreate } from './Create/Create';
import { AdminActionsList } from './List/List';
import { AdminActionShow } from './Show/Show';


export {
	AdminActionsList,
	AdminActionCreate,
	AdminActionShow,
};

export const adminActionsResource = {
	// list: AdminActionsList,
	create: AdminActionCreate,
	// show: AdminActionShow,
	icon: MenuBookIcon,
};
