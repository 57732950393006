import TerminalsIcon from '@material-ui/icons/Storage';

import { TerminalsEdit } from './Edit/Edit';
import { TerminalsList } from './List/List';


export {
    TerminalsList,
    TerminalsEdit,
};

export const terminalsResource = {
    list: TerminalsList,
    edit: TerminalsEdit,
    icon: TerminalsIcon,
};
