import React from 'react';
import { useVersion } from 'react-admin';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { httpClient } from '../../api/httpClient';



export const Swagger = (props) => {
	const [ spec, setSpec ] = React.useState(null);
	const [ isLoading, setIsLoading ] = React.useState(true);
	const viewVersion = useVersion();

	React.useEffect(() => {
		setIsLoading(true);
		httpClient("/swagger.json")
			.then(res => {
				setSpec(res.json);
				setIsLoading(false);
			});
	}, [viewVersion]);

	if (isLoading) {
		return (
			<Box p={5} display="flex" alignItems="center" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<SwaggerUI spec={spec} docExpansion="list" />
	)
}


export const Developer = () => {
	return (
		<Box mt={2}>
			<Paper>
				<Box p={2}>
					<Typography variant="h3">Developers</Typography>
				</Box>
				<Box p={0} pb={2}>
					<Swagger />
				</Box>
			</Paper>
		</Box>
	);
}
