import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

import { GridForm, GridInput } from '../../../components/GridForm/';
import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { DateTimeInput } from '../../../components/inputs';

const BaseForm = (props) => (
	<GridForm {...props}>
		<GridInput
			sm={6}
			component={TextInput}
			source="name"
			validate={[required()]}
			autoFocus
		/>
		<GridInput sm={6} component={DateTimeInput} source="expires_at" withLocalTimeZone />
	</GridForm>
);

export const CreateAPIKey = (props) => {
	return (
		<Create basePath="" resource="api-keys">
			<SimpleForm
				toolbar={<SaveWithCancelToolbar />}
				redirect={'/settings/api-keys'}
			>
				<BaseForm />
			</SimpleForm>
		</Create>
	);
};
