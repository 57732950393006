import merge from 'lodash/merge';

import polyglotI18nProvider from 'ra-i18n-polyglot';

import enMessages from 'ra-language-english';
import enDomainMessages from './lang/en.json';

import deMessages from 'ra-language-german';
import deDomainMessages from './lang/de.json';

import noMessages from 'ra-language-norwegian';
import noDomainMessages from './lang/no.json';

import ruMessages from 'ra-language-russian';
import ruDomainMessages from './lang/ru.json';

import plMessages from 'ra-language-polish';
import plDomainMessages from './lang/pl.json';

import frMessages from 'ra-language-french';
import frDomainMessages from './lang/fr.json';

import esMessages from '@blackbox-vision/ra-language-spanish';
import esDomainMessages from './lang/es.json';

import itMessages from 'ra-language-italian';
import itDomainMessages from './lang/it.json';

import daMessages from 'ra-language-danish';
import daDomainMessages from './lang/da.json';

import nlMessages from 'ra-language-dutch';
import nlDomainMessages from './lang/nl.json';

import { Languages } from '../entities/Languages';


const enTranslation = merge(enMessages, enDomainMessages);
const noTranslation = merge(noMessages, noDomainMessages);
const deTranslation = merge(deMessages, deDomainMessages);
const ruTranslation = merge(ruMessages, ruDomainMessages);
const plTranslation = merge(plMessages, plDomainMessages);
const frTranslation = merge(frMessages, frDomainMessages);
const esTranslation = merge(esMessages, esDomainMessages);
const itTranslation = merge(itMessages, itDomainMessages);
const daTranslation = merge(daMessages, daDomainMessages);
const nlTranslation = merge(nlMessages, nlDomainMessages);

export const defaultLocale = Languages.en;

export const i18nProviderStatic = polyglotI18nProvider(locale => {
    switch(locale){
        case Languages.no:            
            return noTranslation;
        case Languages.de:            
            return deTranslation;
        case Languages.ru:            
            return ruTranslation;
        case Languages.pl:            
            return plTranslation;
        case Languages.fr:            
            return frTranslation;
        case Languages.es:            
            return esTranslation;
        case Languages.it:            
            return itTranslation;
        case Languages.da:            
            return daTranslation;
        case Languages.nl:            
            return nlTranslation;
        case Languages.en: default:
            return enTranslation;
    }
}, defaultLocale);