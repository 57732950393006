import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
	chipField: {
		display: "flex",
        flexWrap: "wrap",
        padding: "10px",
	},
	chipItem: {
		flexBasis: "calc(3% - 5px)",
    padding: "4px",
	},
});


export const ArrayChipField = ({resource, ids}) => {
    const classes = useStyles();

    return (
      <>
        <div className={classes.chipField}>
            {ids.map((item, index) => (
            <div key={index} className={classes.chipItem}>
               <Link href={'#/' + resource + '/'+ item + '/show'} target="_blank">
                  <Chip
                      label={item}
                      size={'small'}
                      clickable
                  />
                </Link>
            </div>
            ))}
      </div>
      </>
    )
    
  }