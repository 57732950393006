import get from 'lodash/get';

export const upperCaseGetter = (record, source) => {
    const value = get(record, source);
    return value.toUpperCase ? value.toUpperCase() : value;
};

export const rankChoicesGetter = (record) => {
	const choices = record.rank ?
		[{id: record.rank, name: "Auto (" +  record.rank + ")"}, {id: 0, name: "Manual"}] : 
		(record.rank === 0 ? [{id: -1, name: "Auto"}, {id: 0, name: "Manual"}] : [{id: -1, name: "Auto"}, {id: 0, name: "Manual"}]);

	return choices;
};