import React from 'react';
import { FlagIcon } from './FlagIcon';

export const FlagIT = props => (
    <FlagIcon {...props} viewBox="0 0 512 512">
        <rect width="170.67" height="512" fill="#008C45" />
        <rect width="170.67" height="512" x="170.67" fill="#F4F5F0" />
        <rect width="170.67" height="512" x="341.33" fill="#CD212A" />
    </FlagIcon>
);
