import React, { createContext, useContext, useMemo, useState } from 'react';


const DragStoreContext = createContext({});


const DragStoreProvider = ({ children, isDraggable = false }) => {
    const [ dragRecordsState, setDragRecordsState ] = useState({});
    const [ recordsState, setRecordsState ] = useState(null);

    const contextValue = useMemo(() => ({ 
        isDraggable, 
        recordsState, setRecordsState,
        dragRecordsState, setDragRecordsState,
    }), [ 
        isDraggable, 
        recordsState, 
        dragRecordsState, 
    ]);

    return (
        <DragStoreContext.Provider value={contextValue}>
            {children}
        </DragStoreContext.Provider>
    );
};


const useDragStoreContext = () => {
    const context = useContext(DragStoreContext);
    return context;
};


export { 
    DragStoreProvider, 
    useDragStoreContext,
};
