import React from 'react';

import { Button } from 'react-admin';

import { ProgressIcon } from '../ProgressIcon';
import { SimpleDialog } from './Dialog';
import { ArticlePreviewGrid } from './ArticlePreviewButton';

import { useCompareLists } from '../../utils/hooks/useCompareLists';


const sharedCompareQueryParams = {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'id', order: 'DESC' },
};


export const CompareArticlesPreviewButton = ({ 
    compareLeftPayload, 
    compareRightPayload, 
    compareButtonLabel,
}) => {
    const { filter: compareLeftPayloadFilter = {}, ...restCompareLeftPayload } = compareLeftPayload;
    const { filter: compareRightPayloadFilter = {}, ...restCompareRightPayload } = compareRightPayload;

    const {
        isLoading, 
        compareModal, compareData, 
        onOpen, onClose,
    } = useCompareLists({ 
        compareLeftPayload: { 
            ...restCompareLeftPayload, 
            payload: { ...sharedCompareQueryParams, filter: compareLeftPayloadFilter }
        }, 
        compareRightPayload: { 
            ...restCompareRightPayload, 
            payload: { ...sharedCompareQueryParams, filter: compareRightPayloadFilter }
        }, 
    });
    
	return (
        <React.Fragment>	
            <Button 
                label={compareButtonLabel}
                disabled={isLoading} 
                startIcon={isLoading ? <ProgressIcon /> : null} 
                alignIcon='left' 
                onClick={onOpen} 
                variant='contained'
            />
            {compareModal.opened && compareData &&
                <SimpleDialog
                    size='lg'
                    open={true}
                    onClose={onClose}
                >
                    <ArticlePreviewGrid data={compareData} />
                </SimpleDialog>
            }
        </React.Fragment>
    );
};
