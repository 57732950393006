import React from 'react';
import { useTranslate, TextField, Datagrid } from 'react-admin';

import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
// import { Datagrid } from '../../../components/CustomizableDataGrid/';

import { WorkforceRow } from './WorkforceRow';
import { TisbankenPlaningDatagrid } from './TisbankenPlaningDataGrid';
import { cellRules } from './cellConfigData';


const BG_COLOR_GREEN = "#b7ff7d";
const BG_COLOR_RED = "#ff8c8c";


const getCellBackground = (rowValue, key) => {
    return rowValue[key] > 0 ? BG_COLOR_RED : rowValue[key] < 0 ? BG_COLOR_GREEN : null; 
}


const prepareTableData = (data, totalLabel) => {
    const preparedData = []
    let man_hours_total = 0
	let tisbanken_total = 0
	let diff_total = 0
    for (const [rowKey, rowValue] of Object.entries(data)){
        const rowObj = []
        for (const [cellKey, cellValue] of Object.entries(rowValue)) {
            if (cellKey !== "id"){
                cellRules['body'][rowValue['time']][cellKey] ? rowObj.push({'val': cellValue, ...cellRules['body'][rowValue['time']][cellKey]}) : rowObj.push(null)
            }
        }

        const bgGroup1 =  getCellBackground(rowValue, 'diff'); 
        const bgGroup2 =  getCellBackground(rowValue, 'diff_grouped_1');  
        const bgGroup3 =  getCellBackground(rowValue, 'diff_grouped_2');   

        rowObj[1]['bg'] = bgGroup1
        rowObj[2]['bg'] = bgGroup1
        rowObj[3]['bg'] = bgGroup1
        if (rowObj[4] !== null && rowObj[5] !== null && rowObj[6] !== null){
            rowObj[4]['bg'] = bgGroup2
            rowObj[5]['bg'] = bgGroup2
            rowObj[6]['bg'] = bgGroup2
        }
        if (rowObj[7] !== null && rowObj[8] !== null && rowObj[9] !== null){
            rowObj[7]['bg'] = bgGroup3
            rowObj[8]['bg'] = bgGroup3
            rowObj[9]['bg'] = bgGroup3
        }

        man_hours_total += rowValue['man_hours']
        tisbanken_total += rowValue['tisbanken']
        diff_total += rowValue['diff']

        preparedData.push(rowObj)
    }

    const totalInfo = [ {"val": man_hours_total.toFixed(2), "rowspan": 1},
                        {"val": tisbanken_total.toFixed(2), "rowspan": 1},
                        {"val": diff_total.toFixed(2), "rowspan": 1}
                    ]
    const totalRow = [{"val": totalLabel, "rowspan": 1}, ...totalInfo]
    return [preparedData, totalRow]
}


export const WorkforceGrid = props => {
    const translate = useTranslate()

    const [preparedData, totalRow] = React.useMemo(() => {
        const labor_forecast_data = (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['labor_forecast_data'] : {}
        return prepareTableData(labor_forecast_data, translate("resources.need-of-workforce.total"))
    },[props.data])
    const tisbankenPlaningData = React.useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? props.data[1]['tisbanken_planing_data'] : []
    }, [props.data]) 

    return (
           <>
            <Table>
                    <TableHead>
                        <TableRow>
                            {
                                Object.keys(cellRules['head']).map((key, index) => (
                                    <TableCell style={{ backgroundColor: cellRules['head'][key]['bg'] }}>
                                        {translate("resources.need-of-workforce.fields." + key)}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    {preparedData.map((row, index) => <WorkforceRow key={index} record={row} resource={props.resource} />)}
                    <WorkforceRow record={totalRow} resource={props.resource} style={{fontWeight: "bold", backgroundColor: "#dadfed"}}/>
                </Table>
                <div align='center'><h1>{translate("resources.tisbanken-planing-data.title")}</h1></div>
                <TisbankenPlaningDatagrid tisbankenPlaningData={tisbankenPlaningData}/>
           </>
    )
};
