import ViewListIcon from '@material-ui/icons/ViewList';

import { EntitiesList } from './List/List';
import { EntityEdit } from './Edit/Edit';
import { EntityShowTabsWrapper } from './Show/Show';


export * from './Edit/Edit';
export * from './Show/Show';


export const entitiesResource = {
	list: EntitiesList,
	edit: EntityEdit,
	show: EntityShowTabsWrapper,
	icon: ViewListIcon,
};
