import React, { useEffect } from 'react';

import {
	TextInput, required,
} from 'react-admin';

import DialogContent from '@material-ui/core/DialogContent';
import {useForm, useFormState} from 'react-final-form';

import { MapperType } from '../../../entities/MapperType';

import { PassProps } from '../../../components/_helpers/PassProps';
import { GridForm, GridInput } from '../../../components/GridForm';
import { MapperTypeSelect } from '../../../components/inputs/MapperTypeSelect';
import { MapperDataInput, MapperDataInputPredefined } from './MapperDataInput';
import { mapperKeys, optionalMapperKeys } from '../../../entities/MapperData';

const loadMapper = mapperKeys[MapperType.loading].map(x => ({key: x, value: ''}));
const unloadMapper = mapperKeys[MapperType.unloading].map(x => ({key: x, value: ''}));

function getPredefinedByType(type){
	switch(type){
		case MapperType.loading:
			return loadMapper;
		case MapperType.unloading:
			return unloadMapper;
		default:
			return;
	}
}

function getOptionalKeysByType(type){
	return optionalMapperKeys[type];
}


export const AddEditMapperBody = ({form, ...rest}) => {
	const [isPredefined, setIsPredefined] = React.useState(false);
	const [optionalKeys, setOptionalKeys] = React.useState();
	const { values } = useFormState();

	useEffect(()=>{		
		const predefinedData = getPredefinedByType(values["type"]);
		if (predefinedData) {
			if(!rest?.record?.data){
				form.change('data', predefinedData);
			}
			setIsPredefined(true);
		} else {
			form.change('data', []);
			setIsPredefined(false);
		}
		const optKeys = getOptionalKeysByType(values["type"]);
		setOptionalKeys(optKeys);		
	}, [values["type"]]);	
	
	return (
		<PassProps component={DialogContent} childPropKeys={["resource", "record"]} resource="customer-companies-mappers" {...rest}>
			<GridForm>
				<GridInput xs={6} component={TextInput} source="name" label="resources.customer-companies-mapper.fields.name" validate={required()}/>
				<GridInput xs={6} component={MapperTypeSelect} source="type" label="resources.customer-companies-mapper.fields.type" validate={required()} />
				<GridInput xs={12} source="data" label="resources.customer-companies-mapper.fields.data_field">
					{isPredefined ? <MapperDataInputPredefined optionalKeys={optionalKeys} formData={values}/> : <MapperDataInput />}
				</GridInput>
			</GridForm>
		</PassProps>
	);
};
