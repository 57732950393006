import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { DATE_FORMAT, format, parseISO } from '../../../components/_helpers/dateTime';
import { isNullUndEmpty }from "../../../utils/general"


const prepareDate = (datetime) =>{
    return format(parseISO(datetime), DATE_FORMAT);
}  

const prepareTotalCostWithCalculationInfo = (costPerHour, totalCost, totalHours) => {
	if (totalCost === null || totalCost === undefined) {
		return totalCost;
	}
	return 'Nkr ' +  costPerHour + ' * ' + totalHours + ' * 1.141 * 1.102 * 1.02 = ' + totalCost ;
}


export const LaborCostDatagrid = ({laborCost}) => {
    const translate = useTranslate()
    const [orderDirection, setOrderDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');

    React.useEffect(()=>{
        setOrderBy('date')
    }, [laborCost])

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedLaborCostData = laborCost.slice().sort((a, b) => {   
        if (orderBy === 'employee_id') {
            return orderDirection === 'asc'
                ? a.employee_id - b.employee_id
                : b.employee_id - a.employee_id;
        } else {   
            return orderDirection === 'asc'
                ? a[orderBy].toString().localeCompare(b[orderBy].toString())
                : b[orderBy].toString().localeCompare(a[orderBy].toString());
        }
    });


    return (
        <Table  size="small" aria-label="a dense table">
            <TableHead>
            <TableRow style={{backgroundColor:"#d8e6db"}}>
                <TableCell  align="left">
                        <TableSortLabel
                                        active={orderBy === 'date'}
                                        direction={orderBy === 'date' ? orderDirection : 'asc'}
                                        onClick={() => handleSortRequest('date')}
                                    >
                            {translate("resources.financial-statements.labor-cost-grid.fields.date")}
                        </TableSortLabel>
                </TableCell>
                <TableCell  align="left">
                        <TableSortLabel
                                        active={orderBy === 'employee_id'}
                                        direction={orderBy === 'employee_id' ? orderDirection : 'asc'}
                                        onClick={() => handleSortRequest('employee_id')}
                                    >
                            {translate("resources.financial-statements.labor-cost-grid.fields.employee_id")}
                        </TableSortLabel>
                </TableCell>
                <TableCell  align="left">
                        <TableSortLabel
                                        active={orderBy === 'employee_name'}
                                        direction={orderBy === 'employee_name' ? orderDirection : 'asc'}
                                        onClick={() => handleSortRequest('employee_name')}
                                    >
                            {translate("resources.financial-statements.labor-cost-grid.fields.employee_name")}
                        </TableSortLabel>
                </TableCell>
                <TableCell align="left">{translate("resources.financial-statements.labor-cost-grid.fields.terminal_account_code")}</TableCell>
                <TableCell align="left">{translate("resources.financial-statements.labor-cost-grid.fields.cost_type")}</TableCell>
                <TableCell align="left">{translate("resources.financial-statements.labor-cost-grid.fields.cost_type_name")}</TableCell>
                <TableCell align="right">{translate("resources.financial-statements.labor-cost-grid.fields.hours")}</TableCell>
                <TableCell align="right">{translate("resources.financial-statements.labor-cost-grid.fields.cost")}</TableCell>
                <TableCell align="right">{translate("resources.financial-statements.labor-cost-grid.fields.total_cost")}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {sortedLaborCostData.map((row) => (
                <TableRow key={row.name}>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {prepareDate(row.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {row.employee_id}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {row.employee_name}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {row.terminal_account_code}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {row.cost_type}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}}>
                        {row.cost_type_name}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}} align="right">
                        {row.hours}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}} align="right">
                        {row.cost}
                    </TableCell>
                    <TableCell component="th" scope="row" style={row.employee_id === 0 || ![100, 200].includes(row.terminal_account_code) || ![100, 120].includes(row.cost_type) ? {fontStyle: "italic"} : {}} align="right">
                        {prepareTotalCostWithCalculationInfo(row.cost, row.total_cost, row.hours)}
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
    )
}