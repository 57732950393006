import React from 'react';
import { ListContextProvider, Datagrid, FunctionField } from 'react-admin';
import { cloneDeep, has } from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';

import { ScrollingWrapper } from '../ScrollingWrapper';
import { CssRowFieldCellWrapper } from '../fields/CssFieldCellWrapper';


export const getDiffCssClasses = diff => (diff !== undefined ? ["row-diff-" + diff] : []);


export const DiffList = ({value, children, ...props}) => {	
	const cssFnRow = rec => getDiffCssClasses(rec["_diff"]);
	
	return (value ?
		<ListContextProvider value={value}>
			<ScrollingWrapper>
				<Datagrid size="small" rowClick={null} hover={false} >
					<CssRowFieldCellWrapper cssFn={cssFnRow} />
					{children}
				</Datagrid>
			</ScrollingWrapper>
		</ListContextProvider> :
	<></>);
};


export const DiffField = ({component: Component, cssFn, ...props}) => {
	const renderField = (record) => {
		const { source } = props;
		const oldValueKey = "_old_" + source;

		if (record[oldValueKey]) {
			return <Tooltip
				title={record[oldValueKey]}
				placement="top"
				className="cell-tooltip-diff"
			>
				<div><Component {...props} /></div>
			</Tooltip>;
		} else {
			return <Component {...props} />;
		}
	};

	return <FunctionField {...props} render={renderField} />;
};


export const listCalcDiff = (list1, list2, mapKeys, keyKey, mapKeysToIgnore) => {
	const key = mapKeys[keyKey];	
	let isDiff = false;
	
	const keysToIgnore = mapKeysToIgnore && has(mapKeysToIgnore, keyKey) ? mapKeysToIgnore[keyKey] : [];
	keysToIgnore.push(keyKey);
	
	const dicList1 = Object.fromEntries(list1.map(x => [x[key], x]));

	for(const row2 of list2) {
		const keyVal = row2[key];
		const row1 = dicList1[keyVal]
		let _diff = 0;
		if (row1) {			
			for (const [cellKey, cellVal] of Object.entries(row2)) {
				if (keysToIgnore.includes(cellKey)) continue;

				const oldCellVal = row1[cellKey];
				const oldCellKey = "_old_" + cellKey;
				if (oldCellVal !== undefined) {
					if (Array.isArray(cellVal)) {
						const _isDiff = listCalcDiff(oldCellVal, cellVal, mapKeys, cellKey, mapKeysToIgnore);
						if (_isDiff) {
							row2[oldCellKey] = true;
							if (!_diff) _diff = 2;
						}
					} else if (cellVal !== oldCellVal) {
						row2[oldCellKey] = oldCellVal;
						if (!_diff) _diff = 2;
					}
					
				}
			}		
		} else {
			_diff = 1;			
		}
		row2["_diff"] = _diff;
		if (!isDiff && _diff) isDiff = true;
	}
	const keyValues = list2.map(x => x[key]);
	for(const row1 of list1.filter(x => !keyValues.includes(x[key]))) {
		const delRow = cloneDeep(row1);
		delRow["_diff"] = -1;
		list2.push(delRow);
		if (!isDiff) isDiff = true;
	}

	return isDiff;
};
