import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  BooleanField,
} from 'react-admin';

import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { RecurringTaskType } from '../../../entities/RecurringTaskType';
import { DateFormatField } from '../../../components/fields/DateFormatField';
import { TaskServices } from '../../../entities/TaskService';
import { ObjectChipField } from '../../../components/fields/ObjectChipField';


const RecurringTasks = (props) => (
    <List 
        {...props}
        exporter={false}
        bulkActionButtons={false}
    >
        <Datagrid>
            <EditButton />
            <TextField source='title' />

            <ObjectChipField 
                source='service' 
                object={TaskServices} 
                titleKey='short_name' 
                tooltipKey='full_name' 
            />

            <DateFormatField source='start_date' />

            <SelectEnumField 
                source='type' 
                enumName='recurring_type' 
                enumObj={RecurringTaskType} 
            />

            <ReferenceField 
                source='terminal_id' 
                reference='terminal' 
                link={false}
            >
                <TextField source='name' />
            </ReferenceField>

            <BooleanField source='priority' />

            <ReferenceField 
                source='assignee_1' 
                reference='osfc-users'
            >
                <TextField source='full_name' />
            </ReferenceField>

            <ReferenceField 
                source='assignee_2' 
                reference='osfc-users'
            >
                <TextField source='full_name' />
            </ReferenceField>
        </Datagrid>
  </List>
);


export default RecurringTasks;
