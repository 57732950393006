import React from 'react';

import {
	FieldTitle, useDataProvider, useListFilterContext, useTranslate
} from 'react-admin';
import { toPrice } from '../../../components/fields/PriceField';
import { LargerTooltip } from '../../../components/LargerTooltip';


export const TotalPriceLabel = (props) => {
	const t = useTranslate();
	const listFilters = useListFilterContext().filterValues;
	const dataProvider = useDataProvider();

	const filterRef = React.useRef(null);
	const [filters, setFilters] = React.useState(null);
	const [value, setValue] = React.useState(null);

	React.useEffect(() => {
		const newFilters = JSON.stringify(filters);
		const eq = filterRef.current === newFilters;
		if (!eq) {
			if (!filters || !Object.keys(filters).length) {
				return;
			}
			filterRef.current = newFilters;
			setValue(null);
			dataProvider.getList("order-summary", {
				sort: { field: 'id', order: 'ASC' },
				pagination: { page: 1, perPage: 5 },
				filter: filters,
			}).then(res => {
				const item = res.data.find(i => i.id === "total_price");
				setValue(item.value);
			})
		}
	}, [filters, dataProvider]);

	return (
		<LargerTooltip
			onOpen={() => {
				if (filters !== listFilters) {
					setFilters(listFilters);
				}
			}}
			title={value === null ? t("ra.page.loading") : `Total: ${toPrice(value)}`}
			interactive
		>
			<div>
				<FieldTitle resource={props.resource} source={props.source} label="resources.orders.fields.total_price"/>
			</div>
		</LargerTooltip>
	);
}
