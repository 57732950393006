import React from "react";
import { Edit } from 'react-admin';

import { useMutateForm } from '../../../api/useMutateForm';

import { SimpleFormAdv } from "../../../components/forms/SimpleFormAdv";
import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { DocumentsFieldBlock } from "../../../components/fields/DocumentsField";
import EntityForm from '../forms/EntityForm';


export const EntityEdit = (props) => {
	const [mutateForm, state] = useMutateForm("update", 
        props.resource, props.basePath, props.id, {}); // eslint-disable-line

    const actionsProps = { hasShow: true, hasList: true };
	const toolbarCompProps = {
		useSaveButtonEx: true,
		saveButtonExProps: { disabled: true },
	};

    return (
        <>
            <Edit 
                {...props} 
                undoable={false}
                actions={<ActionsWithBackButton {...actionsProps} />}
            >
                <SimpleFormAdv 
                    save={mutateForm}
                    toolbarCompProps={toolbarCompProps}
                >
                    <EntityForm />
                </SimpleFormAdv>
            </Edit>
            <Edit {...props} undoable={false} actions={null}>
                <DocumentsFieldBlock variant="elevation"/>
            </Edit>
        </>
    );
};
