import React from "react";

import { Create, SimpleForm } from 'react-admin';

import { AppManagerRoles, UserRole } from '../../../entities/';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { TrailersCreateForm } from './CreateForm';
import { isInArray } from "../../../utils/general";


export const TrailersCreate = ({permissions, ...props}) => {
	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Create {...props}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />} redirect="list">
				<TrailersCreateForm isAdminOrOSFCManager={isAdminOrOSFCManager} />
			</SimpleForm>
		</Create>
	);
};
