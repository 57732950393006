import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, TableContainer } from '@material-ui/core';

import { httpClient } from '../../api/httpClient';

import { OrdersExportView } from '../../components/dialogs/OrdersExportDialog';
import { DataExportTable } from '../../components/dialogs/OrdersExportDialog/DataExportTable';
import { 
    ExportServerCSVButton, 
    ExportServerXLSXButton 
} from '../../components/dialogs/OrdersExportDialog/ExportServerButton';


export const useStyles = makeStyles((theme) => ({
    tableGrid: {
        width: '1px', 
        overflow: 'hidden',
        marginTop: '30px' 
    },
    tableContainer: {
        height: 'calc(100vh - 250px)',
    },

}));


export const ExportPage = ({ filters, titleButtons, endpoint, setExportDate, sort, tableRef }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid 
                container 
                spacing={2} 
                alignContent='center' 
                alignItems='center'
            >
                <Grid 
                    container 
                    item xs={12} 
                    alignContent='space-between' 
                    spacing={2}
                >
                    {React.Children.map(titleButtons, (button, index) => (
                        <Grid item key={index}>
                            {button}
                        </Grid>
                    ))}
                    <Grid item>
                        <ExportServerCSVButton 
                            endpoint={endpoint} 
                            filters={filters} 
                            sort={sort} 
                        />
                    </Grid>
                    <Grid item>
                        <ExportServerXLSXButton 
                            endpoint={endpoint} 
                            filters={filters} 
                            sort={sort} 
                        />
                    </Grid>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    spacing={2} 
                    alignContent='center'
                    alignItems='center'
                    className={classes.tableGrid}
                >
                    <TableContainer 
                        component={Paper} 
                        variant='outlined' 
                        elevation={0} 
                        className={classes.tableContainer}
                    >
                        <DataExportTable
                            endpoint={endpoint}
                            ref={tableRef}
                            filters={filters}
                            sort={sort}
                            setExportDate={setExportDate}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const AdminOrdersExportSearch = () => {
    const [ loadingState, setLoadingState ] = useState(0);
    const [ columns, setColumns ] = useState([]);
    const [ columnsChoices, setColumnsChoices ] = useState([]);

    const initDateFrom = new Date(Date.now());

    useEffect(() => {
        setLoadingState(1);
        const promColumns = httpClient('orders-export-header');
        Promise.all([promColumns]).then((values) => {
            const [promColumnsRes] = values;
            if (promColumnsRes.json) {
                const _columnsChoices = promColumnsRes.json.map(x => ({id: x[0], name: x[1]}));
                const _columnsSelected = promColumnsRes.json.map(x => x[0]);
                setColumnsChoices(_columnsChoices);
                setColumns(_columnsSelected);
            }
            setLoadingState(2);
        });
    }, []);

    return (
        loadingState === 2 ?
            <OrdersExportView 
                initDateFrom={initDateFrom}
                columns={columns}
                columnsChoices={columnsChoices}
                ViewComponent={ExportPage}
            /> 
        : null
    );
};


export default AdminOrdersExportSearch;
