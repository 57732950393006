import React from 'react';

import { List, Datagrid, TextField, EditButton, FunctionField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockDeleteProps } from '../../../components/_helpers/props/DatagridProps';
import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';
import { formatLinkPreview } from '../../../components/_helpers/parsers';
import { DeleteButton } from '../../../components/toolbars/DeleteButton';

import { HotlinksFilter } from './ListFilters';

import Typography from '@material-ui/core/Typography';

const defaultSort = { field: 'sort_order', order: 'DESC' };


export const HotlinksList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList();

	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<HotlinksFilter />}
			classes={classes}
			// empty={<Empty/>}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockDeleteProps}
					rowClick='show'
				>
					<TextField source='id' />
					<TextField source='name' />
					<FunctionField source='link' render={
						record => formatLinkPreview(120)(record.link)
					} />
					<TextField source='sort_order' />
					<EditButton />
					<DeleteButton 
						resource={props.resource}
						notifyMessage="osfc.dialogs.confirm_delete_hotlinks.success"
						title="osfc.dialogs.confirm_delete_hotlinks.confirm.title"
						content="osfc.dialogs.confirm_delete_hotlinks.confirm.content"
					/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
