export const Languages = {
	en: 'en',
	no: 'no',
	de: "de",
	ru: "ru",
	pl: "pl",
	fr: "fr",
	es: "es",
	it: "it",
	da: "da",
	nl: "nl",
};

export const DefaultLng = Languages.en;