import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MUIIconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Datagrid, TextField, FunctionField, ReferenceManyField, useTranslate } from 'react-admin';

import { PriceField, toPrice } from '../../../../components/fields/PriceField';
import { ScrollingWrapperInCard } from '../../../../components/ScrollingWrapper';

import { StepsPopover } from './StepsPopover';
import { LoadTimePopover } from './LoadTimePopover';
import { EditArticle } from './EditArticle';
import { AddArticle } from './AddArticle';
import { UserRole } from '../../../../entities';


const useResourceOptions = (resource) => {
	return useSelector(state => state.admin.resources[resource].props.options)
}

const prepareLoadTimeData = (record) => {
	return {
		"load_time_template": record.load_time_template,
		"load_time_kilo": record.load_time_kilo,
		"load_time_pallet": record.load_time_pallet,
	}
}

export const PricelistDatagrid = (props) => {
	const orderPricelistOptions = useResourceOptions("order-pricelist");
	const translate = useTranslate() 

	const useCalculateTotal = (key) => {
		return React.useMemo(() => {
		  if (!props.ids || !props.data) return null;
		  return props.ids.reduce((prev, curr) => {
			if (props.data[curr].deleted) {
			  return prev;
			}
			return prev + props.data[curr][key];
		  }, 0);
		}, [props.data, props.ids]);
	  };

	 const isVisibleLoadTimeInfo = props.permissions && (
		props.permissions.role === UserRole.osfc_manager ||
		props.permissions.role === UserRole.app_manager ||
		props.permissions.role === UserRole.admin
	);
	
	const total = useCalculateTotal('total');
	const load_time_total = useCalculateTotal('load_time');
	const load_time = useCalculateTotal('load_time_template');
	const load_time_kilo_total = useCalculateTotal('load_time_kilo');
	const load_time_pallet_total = useCalculateTotal('load_time_pallet');


	if (!props.ids || !props.ids.length) {
		if (orderPricelistOptions.hasAdd) {

		} else {
			return null;
		}
	}

	return (
		<Paper variant="outlined">
			<ScrollingWrapperInCard>
				<Datagrid {...props} rowStyle={row => row && row.deleted ? ({ backgroundColor: row.color, textDecoration: 'line-through' }) : ({ backgroundColor: row.color })}>
					<TextField sortable={false} source="article_code"/>
					<TextField sortable={false} source="name" />
					<TextField sortable={false} source="calculation"/>
					<TextField sortable={false} source="units"/>
					<PriceField sortable={false} source="price"/>
					<PriceField sortable={false} source="total"/>
					<TextField sortable={false} source="service_description"/>
					{/* <TextField sortable={false} source="load_time"/>
					<TextField sortable={false} source="load_time_kilo"/>
					<TextField sortable={false} source="load_time_pallet"/> */}
					<FunctionField sortable={false} source="steps" render={record => <StepsPopover record={record} steps={record.steps} />} />
					{isVisibleLoadTimeInfo && 
						<FunctionField sortable={false} source="load_time_field" 
								   render={record => <LoadTimePopover data={prepareLoadTimeData(record)} />}
						/>
					}
					{orderPricelistOptions.hasEdit &&
						<FunctionField sortable={false} source="actions" render={record => <EditArticle article={record} />} />
					}
				</Datagrid>
			</ScrollingWrapperInCard>
			<Box p={1} display="flex" alignItems="center" justifyContent="space-between" boxSizing="border-box">
				<Box pl={0.4} mr={2} display="flex" alignItems="flex-end">
					{total ?
					    <div>
							<Typography variant="body2" title={total}>
								{translate("resources.order-pricelist.total")}: {toPrice(total)}
							</Typography>
							{isVisibleLoadTimeInfo &&
								<Typography variant="body2" title={total}>
									{translate("resources.order-pricelist.total_load_time")}: {load_time_total} = {" "}
									{translate("resources.order-pricelist.load_time")}: {load_time} + {" "} 
									{translate("resources.order-pricelist.total_load_time_kilo")}: {load_time_kilo_total} + {" "}
									{translate("resources.order-pricelist.total_load_time_pallet")}: {load_time_pallet_total}
								</Typography>
							}
						</div>
						:
						<Typography variant="body2">
							{translate("resources.order-pricelist.empty")}
						</Typography>
					}
				</Box>
				{orderPricelistOptions.hasAdd &&
					<Box display="flex">
						<Box mr={1}>
							<MUIIconButton title="Rules" target="_blank" href="/pricelist_analyze" color="primary" size="small" style={{opacity: 0.5}}>
								<HelpOutlineIcon />
							</MUIIconButton>
						</Box>
						<Box display="flex" alignItems="flex-end">
							<AddArticle order={props.order} />
						</Box>
					</Box>
				}
			</Box>
		</Paper>
	);
}


export const PricelistField = (props) => {
	return (
		<ReferenceManyField {...props} perPage={-1}>
			<PricelistDatagrid order={props.record} />
		</ReferenceManyField>
	);
}


PricelistField.defaultProps = {
    addLabel: true,
	className: 'ra-field-datagrid'
};
