import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	useTranslate,
	usePermissions,
	ArrayInput,
	AutocompleteArrayInput,
	FormDataConsumer,
} from 'react-admin';
import { useFormState, useForm  } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { UserRole } from '../../entities/';
import { SysNotifTypeChoicesNew } from '../../entities/SysNotifTypesNew';
import SimpleFormIteratorEx from '../../components/_extensions/react-admin/ra-ui-materialui/src/form/SimpleFormIteratorEx';
import { PopupMessageReasonNames } from '../../entities/PopupType';

const useArrayInputStyle = makeStyles(() => ({
	root: {
        listStyle: 'none',
		width: '700px', 
        padding: 0, 
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
		alignItems: 'center',
		marginBottom: '10px',
		marginTop: '10px',
    },
}));


export const NotificationSettingsForm = () => {
	const t = useTranslate();
	const arrayInputClasses = useArrayInputStyle();

	const { permissions } = usePermissions();

	if (!permissions) {
		return null;
	};

	return (
			<Grid container spacing={7} justify="flex-start" style={{width: '1000px'}}>
				{permissions.role === UserRole.osfc_manager || permissions.role === UserRole.app_manager || permissions.role === UserRole.admin ?  
				<Grid item sm={12} md={6}>
                            <Grid container spacing={7} justify="flex-start">
                                <Grid item sm={12} md={6} style={{display:'flex', flexDirection:'column'}}>
								<ArrayInput source="user_events" label="">
									<SimpleFormIteratorEx disableRemove disableAdd disableReordering showIndex={false} classes={arrayInputClasses}>
										<FormDataConsumer>
											{({ scopedFormData }) => (
												<React.Fragment>
													<p style={{width:'200px'}}>{t("notifications.popup." +PopupMessageReasonNames[scopedFormData.notif_event])}</p>
												</React.Fragment>
											)}
										</FormDataConsumer>					
										<AutocompleteArrayInput source="notif_type" label={t("pages.settings.fields.message_type")} choices={SysNotifTypeChoicesNew} />
									</SimpleFormIteratorEx>
								</ArrayInput>
                                </Grid>
                            </Grid>
				</Grid> : null }
			 </Grid>
	);
}
