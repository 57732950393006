import React from 'react';
import { FlagIcon } from './FlagIcon';

export const FlagNL = props => (
    <FlagIcon {...props} viewBox="0 0 512 512">
        <rect width="512" height="170.67" fill="#21468B" />
        <rect width="512" height="170.67" y="170.67" fill="#FFFFFF" />
        <rect width="512" height="170.67" y="341.33" fill="#AE1C28" />
    </FlagIcon>
);
