import React from 'react';
import { useTranslate, SelectField } from 'react-admin';


export const SelectEnumField = ({enumObj, enumName, reverse = false, ...props}) => {
	const translate = useTranslate();
	// TODO: find out more better approach to translate enum
	const choices = Object.keys(enumObj).map(key => (
		!reverse ? {id: enumObj[key], name: translate(`osfc.enums.${enumName}.${key}`)} 
		: {id: parseInt(key), name: enumObj[key]}
	));
	console.log(choices)
	return (
		<SelectField
			{...props}
			choices={choices}
			// parse={parse}
		/>
	);
};


SelectEnumField.defaultProps = {
	addLabel: true,
};
