import React, { useEffect, useState } from 'react';

import { Draggable } from 'react-beautiful-dnd';

import { useDragStoreContext } from './DragStoreContext';


const DraggableDatagridRow = ({ Component, ...props }) => {
	const { recordsState, setDragRecordsState } = useDragStoreContext();
	const [ recordIndex, setRecordIndex ] = useState(null);
	const { record } = props;

	useEffect(() => {
		if (recordsState?.ids) {
			const rcdIndex = recordsState.ids.indexOf(record.id);
			setRecordIndex(rcdIndex);
			setDragRecordsState(prevData => ({ ...prevData, [rcdIndex]: record.id }));
		}
	}, [recordsState?.ids, record.id, setDragRecordsState]);

	if (!recordsState?.ids) return null;

	return (
		<Draggable draggableId={`${record.id}`} key={recordIndex} index={recordIndex}>
			{(provided, snapshot) => (
				<Component
					{...props}
					ref={provided.innerRef}
					draggableProps={provided.draggableProps}
					dragHandleProps={provided.dragHandleProps}
				/>
			)}
		</Draggable>
	);
};


export { 
    DraggableDatagridRow,
};
