import React from 'react';
import {
    CreateButton,
    useListContext,
    sanitizeListRestProps,
    TopToolbar, ShowButton,
    EditButton, Link, Button, usePermissions, ExportButton
} from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataExport } from '../../../components/dialogs/DataExportDialog';
import { isInArray } from '../../../utils/general';
import { AppManagerRoles } from '../../../entities';

export const TasksActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        selectedIds,
        total,
    } = useListContext(props);
	const { permissions } = usePermissions();
    const isAccessToExport = permissions && (isInArray(AppManagerRoles, permissions.role));
    const data = "tasks"
    const sort = [currentSort.field, currentSort.order]
    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {(exporter !== false && isAccessToExport) && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                    />
                )}
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {isAccessToExport && (<DataExport filters={filterValues} sort={sort} data={data}/>)}

                {hasCreate && <CreateButton basePath={basePath} />}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
};


export const ActionButtonsField = ({ isSearchInDocs, resource, basePath, record, ...props }) => (
    <React.Fragment>
        {record.has_documents || record.has_out_documents ?
            <ShowButton
                label="" 
                style={{minWidth: 'auto', }} 
                to={`/${resource}/${record.id}/show/2`}
                icon={record.has_documents ? <DescriptionIcon/> : <SubjectIcon />}
                {...props}			
            /> :
            <EditButton 
                label="" 
                style={{ minWidth: 'auto' }} 
                resource={resource}
                basePath={basePath} 
                record={record} 
            />
        }
        {isSearchInDocs && 
            <Link to={`/${resource}/${record.id}/show`} target={"_blank"}>
                <Button 
                    style={{ minWidth: 'auto' }}
                >
                    <VisibilityIcon/>
                </Button>
            </Link>
        }
    </React.Fragment>
);
