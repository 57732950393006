import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExportIcon from '@material-ui/icons/TableChart';
import { ProgressIcon } from '../../ProgressIcon';
import ColumnIcon from '@material-ui/icons/ViewColumn';
import { Button, usePermissions, useMutation, useQuery, useDataProvider, useTranslate } from 'react-admin';

import { useSimpleModal } from '../useSimpleModal';

import { httpClient } from '../../../api/httpClient';

import { SimpleDialog } from '../Dialog';
import { DataExportTable } from './DataExportTable';
import { ExportServerCSVButton, ExportServerXLSXButton } from './ExportServerButton';
import { CopyCSVButton } from './CopyCSVButton';

import { DateFilterField } from './DateFilterField';
import { StatusFilterField } from './StatusFilterField';
import { OrderStatus } from '../../../entities/OrderStatus';
import {ToolbarButton} from "./ToolbarButton";

import {CheckboxGroupDrawer} from "../CheckboxGroupDrawer";


const getKeyByStatus = (status) => {
	if (status === OrderStatus.ORDER_COMPLETED) {
		return "completed_at";
	}
	return "created_at";
}

const titleByKey = {
	completed_at: "COMPL Date",
	created_at: "REG Date",
};



export const ExportModalBase = ({filters, titleButtons, endpoint, onModalClose, setExportDate, sort, tableRef }) => {
	const translate = useTranslate()

	return (
		<SimpleDialog
			size="lg"
			open={true}
			onClose={onModalClose}
			title={translate(`resources.${endpoint}.export-dialog.title`)}
			titleButtons={
				<React.Fragment>
					{titleButtons}
					<ExportServerCSVButton endpoint={endpoint} filters={filters} sort={sort} />
					<ExportServerXLSXButton endpoint={endpoint} filters={filters} sort={sort}/>					
				</React.Fragment>
			}
		>
			<TableContainer component={Paper} variant="outlined" elevation={0}>
				<DataExportTable
					endpoint={endpoint}
					ref={tableRef}
					filters={filters}
					sort={sort}
					setExportDate={setExportDate}
				/>
			</TableContainer>
		</SimpleDialog>
	);
}


export const OrdersExportWithFilters = ({ filters }) => {
	const exportModal = useSimpleModal();
	const endpoint = "orders"
	return (
		<React.Fragment>
			<Button label="resources.orders.export" alignIcon="left" onClick={exportModal.handleOpen} startIcon={<ExportIcon />} />
			{exportModal.opened && <ExportModalBase filters={filters} endpoint={endpoint} onModalClose={exportModal.handleClose} />}
		</React.Fragment>
	);
};

export const OrdersExportView = ({ 
	userId, 
	viewComponentProps, 
	initDateFrom, 
	columnsChoices,
	columns, 
	ViewComponent = ExportModalBase 
}) => {
	const tableRef = React.useRef(null);
	const columnsModal = useSimpleModal();
	const translate = useTranslate()

	const [ exportDate, setExportDate ] = React.useState(null);
	const [ dateFromFilter, setDateFromFilter ] = React.useState(initDateFrom);
	const [ dateToFilter, setDateToFilter ] = React.useState(null);
	const [ statusId, setStatusId ] = React.useState(undefined);
	const [ columnsSelected, setColumnsSelected ] = React.useState(columns);

	const onColumnsClose = (val) => {
		setColumnsSelected(val);
		columnsModal.handleClose();
	};

	const endpoint = "orders"
	const filterKey = getKeyByStatus(statusId);
	const transColumns = translate("osfc.buttons.columns");

	const filters = React.useMemo(() => ({
		[`${filterKey}:gt`]: dateFromFilter,
		[`${filterKey}:lt`]: dateToFilter,
		'status': statusId,
		excluded_columns: columns.filter(x => !columnsSelected.includes(x))
	}), [dateFromFilter, dateToFilter, statusId, filterKey, columns, columnsSelected]);


	const titleButtons = [
			<StatusFilterField 
				value={statusId} 
				onChange={setStatusId} 
			/>,
			<DateFilterField 
				label={translate("resources.orders.export-dialog.fields.date_from")} 
				onChange={setDateFromFilter} 
				value={dateFromFilter} 
			/>,
			<DateFilterField 
				label={translate("resources.orders.export-dialog.fields.date_to")} 
				onChange={setDateToFilter} 
				value={dateToFilter} 
			/>,
			<ToolbarButton 
				onClick={columnsModal.handleOpen} 
				icon={<ColumnIcon/>}
			>
				{transColumns}
			</ToolbarButton>,
			(columnsModal.opened &&
				<CheckboxGroupDrawer
					choices={columnsChoices}
					value={columnsSelected}						
					title={transColumns}
					onClose={onColumnsClose}
				/>
			),
			<CopyCSVButton 
				table={tableRef} 
				userId={userId} 
				exportDate={exportDate} 
				setDateFromFilter={setDateFromFilter} 
			/>,
		];

	return (
		<ViewComponent 
			filters={filters} 
			endpoint={endpoint} 
			{...viewComponentProps} 
			titleButtons={titleButtons}
			setExportDate={setExportDate} 
		/>
	);
};

export const OrdersExportModal = ({userId}) => {
	const exportModal = useSimpleModal();
	const dataProvider = useDataProvider();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ initDateFrom, setInitDateFrom ] = useState(new Date(Date.now()));
	const [ columns, setColumns ] = React.useState([]);
	const [ columnsChoices, setColumnsChoices ] = React.useState([]);

	const onOpen = () => {
		setIsLoading(true);
		
		const promLastDate = dataProvider.getOne('orders/last-export-date', { id: userId });
		const promColumns = httpClient("orders-export-header");
		Promise.all([promLastDate, promColumns]).then((values) => {
			const [promLastDateRes, promColumnsRes] = values;
			if (promLastDateRes.data && promLastDateRes.data.date) setInitDateFrom(promLastDateRes.data.date);
			if (promColumnsRes.json) {
				const _columnsChoices = promColumnsRes.json.map(x => ({id: x[0], name: x[1]}));			
				const _columnsSelected = promColumnsRes.json.map(x => x[0]);
				setColumnsChoices(_columnsChoices);
				setColumns(_columnsSelected);				
			}
			setIsLoading(false);
			exportModal.handleOpen();	
		});
	};

	return (
		<React.Fragment>
			<Button 
				label="osfc.buttons.export_search" 
				disabled={isLoading} 
				startIcon={isLoading ? <ProgressIcon /> : <ExportIcon />} 
				alignIcon="left" 
				onClick={onOpen} 
			/>
			{exportModal.opened && 
				<OrdersExportView 
					userId={userId} 
					viewComponentProps={{ onModalClose: exportModal.handleClose }}
					initDateFrom={initDateFrom} 
					columns={columns} 
					columnsChoices={columnsChoices} 
				/>
			}
		</React.Fragment>
	);
};
