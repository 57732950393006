import { withStyles } from "@material-ui/core/styles";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";


export const StyledToggleButton = withStyles((theme) => ({
	root: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,

		'&:hover': {
			color: theme.palette.background.paper,
			backgroundColor: theme.palette.primary.light,
		},

		'&.Mui-selected': {
			color: theme.palette.background.paper,
			backgroundColor: theme.palette.primary.main,

			'&:hover': {
				backgroundColor: theme.palette.primary.light,
			},
		}
	},

}))(ToggleButton);


export const StyledNoToggleButton = withStyles((theme) => ({
	root: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,

		'&.Mui-selected': {
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		}
	},
}))(ToggleButton);


export const FilterToggleButtonGroup = withStyles(t => ({
	root: {
		marginBottom: 4,
	}
}))(ToggleButtonGroup);
