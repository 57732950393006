import React, { useState, useEffect } from 'react';

import { orderBy } from 'lodash';
import { useTranslate, TextInput, SelectInput } from 'react-admin';
import { Form } from 'react-final-form';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/OpenInNew';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import RenameIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { UserRole } from '../../../entities/UserRole';
import DocumentTextParsedStatus from "../DocumentTextParsedField";
import { OrderDocumentOutputType } from '../../../entities';
import { utcStr2localStr, parseDateFromDatetimeString, parseTimeFromDatetimeString, } from '../../_helpers/dateTime';
import {DateFormatField, DateTimeField} from '../../../components/fields/DateFormatField';
import { httpClientRaw } from '../../../api/httpClient';
import { fetchDataForExport } from '../../dialogs/OrdersExportDialog/DataExportTable';
import { LoadingSpinner } from '../../LoadingSpinner';

const useDocumentItemStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	rootGray: {
		width: '100%',
		opacity: 0.5
	},
	media: {
		height: 140,
		backgroundPosition: 'top',
	},
	contentRoot: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),

		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',

		'&:last-child': {
			paddingBottom: theme.spacing(1),
		}
	},
	title: {
		overflowWrap: 'anywhere',
		textAlign: 'left!important'
	},
}));


const documentTypes = [
	{id: "CMR", 					name: "osfc.fields.document.types.cmr"},
	{id: "Packing list", 			name: "osfc.fields.document.types.packing_list"},
	{id: "Export documents", 		name: "osfc.fields.document.types.export_documents"},
	{id: "Other", 					name: "osfc.fields.document.types.other"},
	{id: "Moving report", 			name: "osfc.fields.document.types.moving_report"},
	{id: "Excel list for plukk", 	name: "osfc.fields.document.types.excel_list_for_plukk"},
	{id: "Invoice", 				name: "osfc.fields.document.types.invoice"},
	{id: "Loading chart",			name: "osfc.fields.document.types.loading_chart"},
	{id: "Off loading chart", 		name: "osfc.fields.document.types.off_loading_chart"},
	{id: "Bill og loading", 		name: "osfc.fields.document.types.bill_og_loading"},
	{id: "SAD", 					name: "osfc.fields.document.types.sad"},
	{id: "T1", 						name: "osfc.fields.document.types.t1"},
	{id: "MIO", 					name: "osfc.fields.document.types.mio"},
	{id: "Deviation report",		name: "osfc.fields.document.types.deviation_report"},
	{id: "Label", 					name: "osfc.fields.document.types.label"},
	{id: "Pictures", 				name: "osfc.fields.document.types.pictures"},
	{id: "OSFC plukk list", 		name: "osfc.fields.document.types.osfc_plukk_list"},
	{id: "Plukk list scanned", 		name: "osfc.fields.document.types.plukk_list_scanned"},
];

export const DocumentEditDialog = ({ open, handleClose, doc, handleSave}) => {
	const translate = useTranslate();

	const [typeChoices, setTypeChoices] = useState([]);

	useEffect(() => {
		const choices = orderBy(documentTypes.map(x => ({id: x.id, name: translate(x.name)})), ["name"], ["asc"]);
		setTypeChoices(choices);
	}, []);

	const onSubmit = (values) => {
		handleSave && handleSave(values);
		handleClose();
	}	
	
	return (
		<Form onSubmit={onSubmit} initialValues={doc}>
			{props =>
				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
					<DialogTitle id="form-dialog-title">{translate("osfc.document.edit.label")}</DialogTitle>
					<DialogContent>
						<TextInput autoFocus name="title" label="osfc.fields.document.title" fullWidth />
						<SelectInput fullWidth name="type" label="osfc.fields.document.type" choices={typeChoices}	  />
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							{translate("osfc.buttons.cancel")}
						</Button>
						<Button onClick={props.handleSubmit} color="primary">
							{translate("osfc.buttons.update")}
						</Button>
					</DialogActions>
				</Dialog>
			}
		</Form>
	);
}


const DocumentItemMenu = (props) => {
	const translate = useTranslate();
	const [loading, setLoading] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const anchorFullDownload = React.useRef();

	React.useEffect(() => {
		anchorFullDownload.current = document.createElement("a");
		document.body.appendChild(anchorFullDownload.current);
		return () => {
			document.body.removeChild(anchorFullDownload.current);
		}
	}, []);

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const handleCloseModal = () => setIsModalOpen(false);
	
	const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

	const handleClick = React.useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const handleEdit = () => {
		setIsModalOpen(true);
		handleClose();
	};

	const handleFullDownload = () => {
		setLoading(true);
		fetchDataForExport(httpClientRaw, 'documents-output', {src : props.document.src, type: props.document.type}, "xlsx")
			.then(res => {console.log(res); return res.blob()})
			.then(blobby => {
				setLoading(false);
				const objectUrl = window.URL.createObjectURL(blobby);

				anchorFullDownload.current.href = objectUrl;
				const outputDocName = props.document.title ? props.document.title : '_'
				anchorFullDownload.current.download = `${outputDocName}_full_out_doc.xlsx`;
				anchorFullDownload.current.click();

				window.URL.revokeObjectURL(objectUrl);
			})
			.catch(err => {
				console.error(err);				
			})
			.finally(() => {
				handleClose();
				setLoading(false);
			});
	};

	const handleDelete = () => {
		props.handleDelete && props.handleDelete(props.document);
		handleClose();
	};

	// const isCustomerDriver = props.permissions && (
	// 	props.permissions.role === UserRole.customer_and_transporter_driver
	// );
	// const isTransporterCompany = props.permissions && (
	// 	props.permissions.role === UserRole.transporter_manager || 
	// 	props.permissions.role === UserRole.transporter_employee || 
	// 	props.permissions.role === UserRole.transporter_driver
	// );

	return (
		<React.Fragment>
			{React.cloneElement(props.children, { onClick: handleClick })}
			{isModalOpen && <DocumentEditDialog
				open={isModalOpen}
				handleClose={handleCloseModal}
				doc={props.document}
				handleSave={props.handleUpdate}
			/>}
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem dense component="a" href={props.document.src} target="_blank">
					<ListItemIcon>
						<DownloadIcon fontSize="small" />
					</ListItemIcon>
					{translate("osfc.fields.document.download")}
				</MenuItem>
				{props.isOutputDocuments && <MenuItem dense onClick={handleFullDownload}>
					<ListItemIcon>
						<ArrowDownwardIcon fontSize="small" />
					</ListItemIcon>
					{translate("osfc.fields.document.full_download")}
				</MenuItem>}
				{(!props.isReadOnly  && !props.isOutputDocuments) && <MenuItem dense onClick={handleEdit}>
					<ListItemIcon>
						<RenameIcon fontSize="small" />
					</ListItemIcon>
					{translate("osfc.fields.document.edit")}
				</MenuItem>}
				{!props.isReadOnly && <MenuItem dense onClick={handleDelete}>
					<ListItemIcon>
						<DeleteIcon fontSize="small" />
					</ListItemIcon>
					{translate("osfc.fields.document.delete")}
				</MenuItem>}
				{(props.isOutputDocuments && loading) && <LoadingSpinner />}
			</Menu>
		</React.Fragment>
	);
}


export const DocumentItem = (props) => {
	const translate = useTranslate();
	const classes = useDocumentItemStyles();
	const imagePath = !props.isOutputDocuments ? props.data.thumbnail : 
					  	props.data.type === OrderDocumentOutputType.loading ? '/loading_doc.png' : '/unloading_doc.png'
	const created_at = utcStr2localStr(props.data.created_at)

	let outputDocInfo = null;
	const rootStyle = {};
	if (props.isOutputDocuments) {
		let created_by_name = "SYSTEM";
		if (props.data.created_by_name) {
			created_by_name = props.data.created_by_name;			
		} else {
			rootStyle["opacity"] = 0.5;
		}

		let recreated_at = props.data.recreated_at ? utcStr2localStr(props.data.recreated_at) : "-";
		if (props.data.recreated_at) {
			recreated_at = utcStr2localStr(props.data.recreated_at);
			recreated_at = parseDateFromDatetimeString(recreated_at) + ' ' + parseTimeFromDatetimeString(recreated_at)
		}

		outputDocInfo = <>
			Created by: {created_by_name}
			<br />
			Auto edited: {recreated_at}
			<br />
			{props.data.is_empty ? "EMPTY" : ""}
		</>;
	}
	
	const title = !props.isOutputDocuments ? props.data.title : props.data.title + ' ' 
				  + parseDateFromDatetimeString(created_at) + ' ' + parseTimeFromDatetimeString(created_at)
	return (
		<Card className={classes.root} style={rootStyle} elevation={0} variant="outlined">
			<CardActionArea href={props.data.src} target="_blank">
				<CardMedia
					className={classes.media}
					image={imagePath}
				/>
			</CardActionArea>
			<CardContent className={classes.contentRoot}>
				<Typography className={classes.title} variant="body2" color="textSecondary" component="p">
					<Typography className={classes.title} variant="body2" color="textPrimary" component="span">
						{translate(documentTypes.filter(item => item?.id === props.data.type)[0]?.name)}
					</Typography>
					<br />
					<DateTimeField source="created_at"/>
					<br />
					{title} 
					<br />
					{outputDocInfo}
					{ !props.isOutputDocuments && <DocumentTextParsedStatus {...props} />}
				</Typography>
				<DocumentItemMenu isReadOnly={props.isReadOnly} document={props.data} 
								  handleUpdate={props.handleUpdate} handleDelete={props.handleDelete}
								  isOutputDocuments={props.isOutputDocuments}>
					<IconButton size="small" color="primary">
						<MoreIcon fontSize="small" />
					</IconButton>
				</DocumentItemMenu>
			</CardContent>
		</Card>
	);
}
