export const UserType = {
    manager: 2,
    employee: 3,
    app_manager: 11,
};


export const UserTypeNames = {
	[UserType.manager]: "Manager",
	[UserType.employee]: "Employee",
	[UserType.app_manager]: "Management",

}