import { fetchUtils } from 'react-admin';

import simpleRestProvider from 'ra-data-simple-rest';


const simpleRestProviderEx = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const _simpleRestProvider = simpleRestProvider(apiUrl, httpClient);

    return {
        ..._simpleRestProvider, 
        updatePartial: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        updateManyArray: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'PUT',
                body: JSON.stringify({ ids: params.ids, data: params.data }),
            }).then(({ json }) => ({ data: json })),

        createMany: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),
    };    
};


export default simpleRestProviderEx;