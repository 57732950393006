// Package from
// https://github.com/vascofg/react-admin-date-inputs

import React from 'react';
import PropTypes from 'prop-types';
import { FieldTitle, useInput, useTranslate } from 'react-admin';
import MUITextField from '@material-ui/core/TextField';
import {
	KeyboardDatePicker,
	// KeyboardTimePicker,
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
} from '@material-ui/pickers';
import * as dateFns from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
	DATE_TIME_FORMAT,
	DATE_FORMAT,
	TIME_FORMAT,
	TODAY,
	locale,
	isDateValid,
	ISO_DATE_TIME_FORMAT,
} from '../_helpers/dateTime';
import { isEmpty } from '../../utils/validation/required';
import { utcToZonedTime, format } from 'date-fns-tz';

const InputComponent = (props) => (
	<MUITextField
		{...props}
		size="small"
		// margin="dense"
	/>
);

function isDateValidValidation(value, allValues, meta) {
	if (!isEmpty(value) && !isDateValid(new Date(value)))
		return 'Date or time is not valid!';
}

function mergeValidators(validator, validatorsArray) {
	if (Array.isArray(validator)) {
		return [...validatorsArray, ...validator];
	} else if (typeof validator === 'function') {
		return [...validatorsArray, validator];
	} else {
		return validatorsArray;
	}
}

export const Picker = ({
	PickerComponent,
	defaultValue,
	onChange = undefined,
	...fieldProps
}) => {
	const {
		resettable,
		margin,
		variant,
		options,
		label,
		source,
		resource,
		className,
		providerOptions,
		parse,
		format,
		autoFocus,
		validate
	} = fieldProps;


	const { isRequired, input, meta } = useInput({
		source,
		validate: validate,
		parse: parse,
		format: format,
		defaultValue: defaultValue,
	});
	const { touched, error } = meta;
	const translate = useTranslate();

	return (
		<MuiPickersUtilsProvider {...providerOptions}>
			<PickerComponent
				autoFocus={autoFocus}
				variant="modal"
				{...options}
				resettable={resettable}
				margin={margin}
				onKeyDown={fieldProps.onKeyDown}
				autoOk
				clearable
				showTodayButton={true}
				label={
					<FieldTitle
						label={label}
						source={source}
						resource={resource}
						isRequired={isRequired}
					/>
				}
				// InputAdornmentProps
				error={!!(touched && error)}
				helperText={touched && error ? translate(error) : ''}
				className={className}
				value={input.value || null}
				onChange={(val) => {
					input.onChange(val);
					onChange && onChange(val);
				}}
				onBlur={input.onBlur}
				inputVariant={variant || 'filled'}
				fullWidth={fieldProps.fullWidth}
				minutesStep={fieldProps.minutesStep}
				disabled={fieldProps.disabled}
				title={fieldProps.title}
				ampm={fieldProps.ampm}
				TextFieldComponent={InputComponent}
			/>
		</MuiPickersUtilsProvider>
	);
};

Picker.propTypes = {
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	minutesStep: PropTypes.number,
	// meta: PropTypes.object,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	labelTime: PropTypes.string,
	className: PropTypes.string,
	providerOptions: PropTypes.shape({
		utils: PropTypes.func,
		locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

Picker.defaultProps = {
	input: {},
	isRequired: false,
	minutesStep: 5,
	ampm: false,
	// meta: { touched: false, error: false },
	options: {
		format: DATE_TIME_FORMAT,
		placeholder: DATE_TIME_FORMAT,
	},
	resource: '',
	source: '',
	labelTime: '',
	className: '',
	providerOptions: {
		utils: DateFnsUtils,
		locale: locale,
	},
};

const parseTime = (val) => {
	try {
		return dateFns.format(val, TIME_FORMAT);
		// return dateFns.format(val, { representation: 'time' });
	} catch (e) {
		console.error(e);
		return val;
	}
};

const formatTime = (val) => {
	try {
		if (!val) return null;
		return dateFns.parse(val, TIME_FORMAT, TODAY);
	} catch (e) {
		console.error(e);
		return val;
	}
};

export const parseDate = (val) => {
	try {
		const result = dateFns.formatISO(val, { representation: 'date' });
		return result;
	} catch (e) {
		console.error(e);
		return val;
	}
};

export const formatDate = (val) => {
	try {
		if (!val) return null;
		const result = dateFns.parseISO(val, TODAY);
		return result;
	} catch (e) {
		console.error(e);
		return val;
	}
};

export const parseDateTime = (val, withLocalTimeZone = false) => {
	try {
		if (withLocalTimeZone) {
			const zonedDate = utcToZonedTime(val, 'UTC');
			return format(zonedDate, ISO_DATE_TIME_FORMAT, { timeZone: 'UTC' });
		}
		return dateFns.formatISO(val, { representation: 'complete' });
	} catch (e) {
		return val;
	}
};

export const formatDateTime = (val, withLocalTimeZone = false) => {
	try {
		if (!val) return null;
		if (withLocalTimeZone) {
			const parsedDate = new Date(`${val}Z`);
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const zonedDate = utcToZonedTime(parsedDate, timeZone);
			return format(zonedDate, ISO_DATE_TIME_FORMAT);
		}
		return dateFns.parseISO(val, TODAY);
	} catch (e) {
		return val;
	}
};

export const DateInput = (props) => (
	<Picker
		parse={parseDate}
		format={formatDate}
		PickerComponent={KeyboardDatePicker}
		options={{ format: DATE_FORMAT }}
		validate={mergeValidators(props.validate, [isDateValidValidation])}
		{...props}
	/>
);
export const TimeInput = (props) => (
	<Picker
		parse={parseTime}
		format={formatTime}
		PickerComponent={KeyboardTimePicker}
		options={{ format: TIME_FORMAT }}
		{...props}
		minutesStep={5}
		ampm={false}
	/>
);
export const DateTimeInput = ({ withLocalTimeZone = false, ...props}) => (
	<Picker
		parse={(v) => parseDateTime(v, withLocalTimeZone)}
		format={(v) => formatDateTime(v, withLocalTimeZone)}
		PickerComponent={KeyboardDateTimePicker}
		validate={mergeValidators(props.validate, [isDateValidValidation])}
		{...props}
		minutesStep={5}
		ampm={false}
	/>
);
