import React, { Component } from 'react';
import T from 'prop-types';

import { FieldTitle } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';

export const EXCLUDE = -1;

export class ColumnSelectionForm extends Component {
  onColumnClicked = ({ target: { value: columnName } }) => {
    this.props.onColumnClicked(columnName);
  };

  render() {
    const { columns, selection, resource, filterColumns, formGroupProps = {} } = this.props;

    // Apply filtering if filterColumns prop exists
    const filteredColumns = filterColumns
      ? columns.filter(({ source }) => filterColumns.includes(source))
      : columns;

    return (
      <FormGroup {...formGroupProps}>
        {filteredColumns.map(({ source, label }) => (
          <FormControlLabel
            key={source}
            control={
              <Checkbox
                checked={selection[source] !== EXCLUDE}
                onChange={this.onColumnClicked}
                value={source}
              />
            }
            label={<FieldTitle label={label} source={source} resource={resource} />}
          />
        ))}
      </FormGroup>
    );
  }
}

ColumnSelectionForm.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    })
  ).isRequired,
  selection: T.object.isRequired,
  onColumnClicked: T.func.isRequired,
  resource: T.string.isRequired,
  filterColumns: T.arrayOf(T.string), // Optional prop
};

export class SelectionDialog extends Component {
  onColumnClicked = (columnName) => {
    this.props.onColumnClicked(columnName);
  };

  render() {
    const { columns, selection, onClose, resource, configurationLabel, filterColumns } = this.props;

    return (
      <Drawer maxWidth="xs" anchor="left" onClose={onClose} open>
        <DialogTitle id="ra-columns-dialog-title">{configurationLabel}</DialogTitle>
        <DialogContent>
          <ColumnSelectionForm
            columns={columns}
            selection={selection}
            onColumnClicked={this.onColumnClicked}
            resource={resource}
            filterColumns={filterColumns} // Pass the filterColumns prop down
          />
        </DialogContent>
      </Drawer>
    );
  }
}

SelectionDialog.propTypes = {
  columns: T.arrayOf(
    T.shape({
      label: T.string,
      source: T.string.isRequired,
    })
  ).isRequired,
  selection: T.object.isRequired,
  onColumnClicked: T.func.isRequired,
  onClose: T.func.isRequired,
  resource: T.string.isRequired,
  configurationLabel: T.string.isRequired,
  filterColumns: T.arrayOf(T.string), // Optional prop
};

SelectionDialog.defaultProps = {
  columns: [],
};
