import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FilterToggleButtonGroup, StyledToggleButton } from '../../../components/filters/FilterToggleInput';
import { filterColors } from '../../../utils/constants';


export const GroupingPeriodEnum = {
	MONTH: "month",
	WEEK: "week",
	DAY: "day",
}

const useStyles = makeStyles({
	filterPeriod: {
		backgroundColor: filterColors.period
	},
});

export const GroupingPeriodFilter = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { input } = useInput(props);
	const value = input.value;

	const handleChangeSingle = (e, newValues) => {
		input.onChange([newValues]);
	}

	return (
		<React.Fragment>
			<FilterToggleButtonGroup value={value} onChange={handleChangeSingle} label={input.label} exclusive className={classes.filterPeriod} >
				<StyledToggleButton value={GroupingPeriodEnum.MONTH}>
					{translate("osfc.filters.financial_statement.grouping_period.month")}
				</StyledToggleButton>
				<StyledToggleButton value={GroupingPeriodEnum.WEEK}>
					{translate("osfc.filters.financial_statement.grouping_period.week")}
				</StyledToggleButton>
				<StyledToggleButton value={GroupingPeriodEnum.DAY}>
					{translate("osfc.filters.financial_statement.grouping_period.day")}
				</StyledToggleButton>
			</FilterToggleButtonGroup>
		</React.Fragment>
  );
}
