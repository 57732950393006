import { MONTHDAYS } from '../constants';
import { RecurringTaskType } from '../../../entities/RecurringTaskType';

export const extractHour = (time) => {
    return String(time.getHours()).padStart(2, '0');
}

export const extractMinutes = (time) => {
    return String(time.getMinutes()).padStart(2, '0');
};

export const convertTaskDateForWeek = (day) => {
    if (day <= 0) {
        day = MONTHDAYS + day;
    }
    const formattedDay = String(day).padStart(2, '0');
    const formattedDate = `2024-01-${formattedDay}`;
    let d = new Date(formattedDate).getDay(); 
  
    d = d === 0 ? 7 : d; 
    return `2024-01-${String(d).padStart(2, '0')}`;
};

export const convertTaskDate = (day) => {
    if (day <= 0) {
        day = MONTHDAYS + day;
    }
    const formattedDay = String(day).padStart(2, '0');

    const formattedDate = `2024-01-${formattedDay}`;
    return formattedDate;
};


export const addTimeToDate = (date, amount, repeat_every, type) => {
    if (!date) {
        date = new Date();
    }
    if (!amount) {
        amount = 1;
    }
    const newDate = new Date(date);
    if (type === RecurringTaskType.month) {
        newDate.setMonth(newDate.getMonth() + amount * repeat_every);
    } else if (type === RecurringTaskType.week) {
        newDate.setDate(newDate.getDate() + amount * 7* repeat_every); 
    } else if (type === RecurringTaskType.day) {
        newDate.setDate(newDate.getDate() + amount * repeat_every);
    } else if (type === RecurringTaskType.year) {
        newDate.setFullYear(newDate.getFullYear() + amount * repeat_every);
    }

    const formattedDate = newDate.toISOString().split('T')[0]
    return formattedDate;
}

export const isLastDayOfMonth = (date) => {
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    
    nextMonth.setDate(nextMonth.getDate() - 1);
    
    return date.getDate() === nextMonth.getDate();
}