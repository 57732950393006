import React from "react";
import {
	List, Datagrid, TextField, EditButton, ReferenceField, usePermissions,
} from 'react-admin';

import { AppManagerRoles, UserRole } from '../../../entities/UserRole';
import { PhoneField } from '../../../components/fields/PhoneField';
import { DriversFilter } from './ListFilters';
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
// import { DriverExpandPanel } from './ItemExpand';
import {HideBlockUnblockButtonIfDeleted} from '../../../components/toolbars/BlockUnblockButton';
import {DatagridWithBlockDeleteProps} from '../../../components/_helpers/props/DatagridProps';
import DeleteRestoreButtons from "../../../components/toolbars/DeleteRestoreButtons";
import {stylesForColorInheritInList} from "../../../components/_helpers/useStyles";
import {StyledEmailField} from "../../../components/fields/StyledEmailField";
import { isInArray } from "../../../utils/general";


const defaultSort = { field: 'id', order: 'DESC' };


export const DriversList = (props) => {
	const classes = stylesForColorInheritInList()
	const { permissions } = usePermissions();

	if (!permissions) {
		return null;
	}

	const isAdmin = isInArray(AppManagerRoles, permissions.role)
	
	// INFO: not used
	// const companyReference = isAdmin ? "companies" : "transporters";

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={isAdmin ? <DriversFilter /> : undefined}
			sort={defaultSort}
			classes={classes}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockDeleteProps}
					// expand={<DriverExpandPanel />}
					rowClick="show"
				>
					<TextField source="id" />
					{isAdmin && <ReferenceField source="company_id" reference={"companies"} link={false}>
						<TextField source="full_name" />
					</ReferenceField>}
					<TextField source="first_name" />
					<TextField source="last_name" />
					<ReferenceField source="truck_id" reference={"trucks"} link={false} >
						<TextField source="name" />
					</ReferenceField>
					<TextField source="trailer_number" />
					<StyledEmailField source="email" />
					<PhoneField source="phone" />
					{props.hasEdit && <EditButton label="" />}
					{(
						// permissions.role === UserRole.customer_manager ||
						permissions.role === UserRole.customer_and_transporter_manager ||
						permissions.role === UserRole.transporter_manager ||
						permissions.role === UserRole.admin
					) && <HideBlockUnblockButtonIfDeleted entity={"user"}/>}					
					{(
						// permissions.role === UserRole.customer_manager ||
						permissions.role === UserRole.customer_and_transporter_manager ||
						permissions.role === UserRole.transporter_manager ||
						permissions.role === UserRole.admin
					) && <DeleteRestoreButtons resourceForUpdate={"user-is-deleted"}/>}
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
