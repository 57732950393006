import React from 'react';

import { FunctionField } from 'react-admin';

import Typography from "@material-ui/core/Typography";


export const TooltipTextField = (props) => {
	
	return (
		<FunctionField {...props} render={record => {
			const tt = props['ttFn'] ? props['ttFn'](record) : false;
			const ttSign = props['ttSign'] || '!';
			return tt ? <Typography title={tt} component="span" variant="body2">
				{record[props.source]}
				<span class="tt-icon">{ttSign}</span>
			</Typography> :
			<Typography component="span" variant="body2">
				{record[props.source]}
			</Typography>
		}} />
	);
}

TooltipTextField.defaultProps = {    
};
