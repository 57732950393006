import React from 'react';
import { SimpleForm, Edit } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { RecurringTaskForm } from '../forms/RecurringTaskForm';
import {useMutateForm} from '../../../api/useMutateForm';


export const EditRecurringTask = (props) => {
    const [mutateForm, state] = useMutateForm("update", props.resource, props.basePath, props.id, {}); // eslint-disable-line
	const mutateFormAndEmit = (...args) => {
		const result = mutateForm(...args);
		return result;
	};
    return(
    <Edit {...props} undoable={false}>
        <SimpleForm 
        	save={mutateFormAndEmit}

            submitOnEnter={false} 
            toolbar={<SaveWithCancelToolbar />} 
            redirect='list'
        >
            <RecurringTaskForm {...props} />
        </SimpleForm>
    </Edit>
)};
