import React from 'react';
import { 
	TextField, ReferenceField, Tab,
	FunctionField, SelectInput, 
} from 'react-admin';

import { DateFormatField } from '../../../components/fields/DateFormatField';
import { FlexLayout, FlexRow } from '../../../components/FlexShowLayout';
import { PriorityField } from '../../../components/fields/PriorityField';
import { HistoryField } from '../../../components/HistoryGrid';
import { ReferenceEditableField } from '../../../components/fields/EditableField';

import { TaskHistoryGrid } from './HistoryGrid';
import { AppManagerRoles, UserRole } from '../../../entities';
import { DocumentsFieldBlock } from '../../../components/fields/DocumentsField';
import { TaskServiceNames } from '../../../entities/TaskService';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { isInArray } from '../../../utils/general';


export const GeneralTab = ({ permissions, ...props}) => {
	const isAppManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Tab label="General" {...props}>
			<FlexLayout>
				<FlexRow>
					<TextField source="id" />
					<TextField source="title" />
					<TextField source="rank" />
					<TextField source="rank_auto" />

					<SelectEnumField 
						sortable={false} 
						source="service" 
						enumObj={TaskServiceNames} 
						reverse
					/>

					<ReferenceEditableField 
						editable={isAppManager} 
						source="status" 
						reference="task-statuses"
						editField={<SelectInput optionText="name" />}
					>
						<TextField source="short_name" />
					</ReferenceEditableField>

					<PriorityField source="priority" />

					<ReferenceField 
						source="terminal_id" 
						reference="terminal" 
						link={false}
					>
						<TextField 
							label="Terminal" 
							source="name"
						/>
					</ReferenceField>
				</FlexRow>

				<FlexRow>
					<DateFormatField source="eta_date" />
					<TextField source="eta_time" />
					<DateFormatField source="etd_date" />
					<TextField source="etd_time" />
					<TextField source="estimate_duration" />
					<DateFormatField source="deadline_date" />
					<TextField source="deadline_time" />
				</FlexRow>

				<FlexRow>
					<TextField source="notes" />
					<TextField source="description" />
				</FlexRow>
			</FlexLayout>
		</Tab>
	);
};


export const HistoryTab = (props) => (
	<Tab 
		label="Task History" 
		{...props}
	>
		<ReferenceField 
			label="resources.task-editlog.created_by" 
			source="created_by" 
			reference="users" 
			link={false}
		>
			<FunctionField render={record => `${record.first_name} ${record.last_name}`} />
		</ReferenceField>

		<HistoryField 
			label="resources.task-history.name" 
			reference="task-history" 
			target="original_id"
		>
			<TaskHistoryGrid />
		</HistoryField>
	</Tab>
);


export const DocumentsTab = ({isOSFCManager, isCustomerAsTrans, ...props}) => {
	return (
		<Tab label="Documents" {...props}>
			<FlexLayout>
				<FlexRow>
					<TextField source="id"/>
					<TextField source="reference"/>
					<ReferenceEditableField editable={isOSFCManager} source="status" reference="task-statuses"
											editField={<SelectInput optionText="name"/>}>
						<TextField source="short_name"/>
					</ReferenceEditableField>
				</FlexRow>
			</FlexLayout>
			<DocumentsFieldBlock variant="elevation" isCustomerAsTrans={isCustomerAsTrans} isOutputDocuments={false}/>
		</Tab>
	);
};
