import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const isComponentsNotValid = (arrayOfComponents) => (
    !arrayOfComponents.every((component) => typeof component === "function" || typeof component === "object")
);

const openButtonStyles = withStyles(theme => ({
    textButton: {
        backgroundColor: 'transparent',
        padding: 0,
        minWidth: 'auto',
        boxShadow: 'none',
        textTransform: 'none',
        textAlign: 'left',
        justifyContent: 'flex-start',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            textDecoration: 'underline',
        },
    },
}));

const PreviewOpenButton = ({ openButtonProps, classes, ...rest }) => (
    <Button 
        {...rest}
        size="small" 
        color="primary" 
        className={classes?.textButton} 
        style={(openButtonProps?.labelStyles || {})}
    >
        {openButtonProps?.formatLabel ? 
            openButtonProps.formatLabel(openButtonProps?.record || {}) 
        : 
            openButtonProps?.label
        }
    </Button>
);

const StyledPreviewOpenButton = openButtonStyles(PreviewOpenButton);

const PreviewCloseButton = (props) => (
    <IconButton disableRipple={true} disableFocusRipple={true} color="primary" {...props}>
        <CloseIcon fontSize="small" />
    </IconButton>
);


const drawerStyles = withStyles(theme => ({
	paper: {
		[theme.breakpoints.up('md')]: {
			width: '25vw',
		},
		[theme.breakpoints.only('sm')]: {
			width: '22vw',
		},
		[theme.breakpoints.only('xs')]: {
			width: '100vw',
		},
	},
}));

const StyledDrawer = drawerStyles(Drawer);


const QuickPreview = ({
    formatLabel, label, labelStyles,
    onDrawerOpen, onDrawerClose,

    allowDivider = true, anchorSide = "right",
    contentProps = {}, 
    withDrawerStyles = true, 
    withOpenButtonStyles = true, 

    ContentComponent, InjectedComponent,
    PreviewOpenButtonComponent = withOpenButtonStyles ? StyledPreviewOpenButton : PreviewOpenButton,
    PreviewCloseButtonComponent = PreviewCloseButton,
    DrawerComponent = withDrawerStyles ? StyledDrawer : Drawer,

    ...rest
}) => {
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    if (isComponentsNotValid([ContentComponent, PreviewOpenButtonComponent, 
                              PreviewCloseButtonComponent, DrawerComponent])) {
        return null;
    }

    const record = rest?.record || contentProps.record;
    const openButtonProps = { formatLabel, label, record, labelStyles };
    const drawerProps = { anchorSide, isDrawerOpen, setIsDrawerOpen };

    const openButtonSharedProps = { openButtonProps, drawerProps };
    const closeButtonSharedProps = { drawerProps };
    const previewContentProps = contentProps;
    const sharedProps = { openButtonProps, drawerProps, contentProps };

	const handlePreviewButtonClick = (event) => {
        onDrawerOpen && onDrawerOpen(event, sharedProps);
		setIsDrawerOpen(true);
	};

	const handleDrawerCloseClick = (event) => {
        onDrawerClose && onDrawerClose(event);
		setIsDrawerOpen(false);
	};

	return (
		<React.Fragment>
            <PreviewOpenButtonComponent {...openButtonSharedProps} onClick={handlePreviewButtonClick} />
            <DrawerComponent anchor={anchorSide} open={isDrawerOpen} onClose={handleDrawerCloseClick}>
                <PreviewCloseButtonComponent {...closeButtonSharedProps} onClick={handleDrawerCloseClick} />
                {allowDivider ? <Divider /> : null}
                <ContentComponent {...previewContentProps} />
            </DrawerComponent>
            {!isComponentsNotValid([InjectedComponent]) && <InjectedComponent {...sharedProps} />}
		</React.Fragment>
	);
};


export default QuickPreview;
