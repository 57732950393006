import React from 'react';

import { TextField, FunctionField, ReferenceField, Datagrid, useRecordContext } from 'react-admin';

import { PriceField } from '../../../../components/fields/PriceField';
import { DateTimeField } from '../../../../components/fields/DateFormatField';

import { StepsPopover } from '../Pricelist/StepsPopover';
import { MyDatagrid } from './HistoryGrid';


const changedCellStyle = {
    boxShadow: '0px 0px 100px rgba(255,0,0,0.5) inset',
};


const UpdatedByField = ({anotherSource, ...props}) => {
    const record = useRecordContext(props);

    if (!record.updated_by && anotherSource) {
        return (
            <FunctionField {...props} source={anotherSource} render={record => record.created_by} />
        );
    };

    return (
        <ReferenceField {...props} link={false}>
            <FunctionField render={record => `${record.first_name} ${record.last_name}`} />
        </ReferenceField>
    );
};

const UpdatedAtField = ({anotherSource, ...props}) => {
    const record = useRecordContext(props);

    const overridedProps = {};
    if (!record.updated_at && anotherSource) {
        overridedProps["source"] = "created_at";
    };

    return (
        <DateTimeField {...props} {...overridedProps} />
    );
};


export const OrderArticleHistoryGrid = ({nextForRecordId, ...props}) => {
    const rowStyle = (record) => {
        const styles = { backgroundColor: record.color };
        if (record.deleted) styles.textDecoration = "line-through";
        return styles;
	};

    const cellStyle = (fieldName, record) => {
        if (!props.data || fieldName === "updated_at" || fieldName === "steps") return;
        
        const nextRecordId = nextForRecordId[record.id];
        if (!nextRecordId) return;
        if (props.data[nextRecordId][fieldName] !== record[fieldName]) return changedCellStyle;
    };

    return (
        <MyDatagrid {...props} useCustomDataGrid={true} isHeaderShown={false} cellStyle={cellStyle} rowStyle={rowStyle}>
            <UpdatedByField sortable={false} source="updated_by" reference="users" anotherSource="created_by"
                            label="resources.order-history.fields.updated_by" />
            <UpdatedAtField sortable={false} source="updated_at" anotherSource="created_at" 
                            label="resources.order-history.fields.updated_at" withLocalTimeZone />

            <TextField sortable={false} source="article_code" label="resources.order-pricelist-history.article_code" />
            <TextField sortable={false} source="name" label="resources.customer-pricelist-articles.fields.name" />
            <TextField sortable={false} source="calculation" 
                       label="resources.customer-pricelist-articles.fields.calculation" />
            <TextField sortable={false} source="units" label="resources.order-pricelist-history.units" />
            <PriceField sortable={false} source="price" label="resources.customer-pricelist-articles.fields.price_a" />
            <PriceField sortable={false} source="total" label="resources.order-pricelist-history.total" />
            <TextField sortable={false} source="service_description" 
                       label="resources.customer-pricelist-articles.fields.service_description" />
        </MyDatagrid>
    );
};


export const OrderPricelistHistoryGrid = ({nextForRecordId, ...props}) => {
    const rowStyle = (record) => {
        const styles = { backgroundColor: record.color };
        if (record.deleted) styles.textDecoration = "line-through";
        if (record.color === null) styles.backgroundColor = "#828494";
        return styles;
	};

    return (
        <Datagrid {...props} rowStyle={rowStyle}>
            <UpdatedByField sortable={false} source="updated_by" reference="users" anotherSource="created_by"
                            label="resources.order-history.fields.updated_by" />
            <UpdatedAtField sortable={false} source="updated_at" anotherSource="created_at" 
                            label="resources.order-history.fields.updated_at" withLocalTimeZone />

            <TextField sortable={false} source="article_code" label="resources.order-pricelist-history.article_code" />
            <TextField sortable={false} source="name" label="resources.customer-pricelist-articles.fields.name" />
            <TextField sortable={false} source="calculation" 
                       label="resources.customer-pricelist-articles.fields.calculation" />
            <TextField sortable={false} source="units" label="resources.order-pricelist-history.units" />
            <PriceField sortable={false} source="price" label="resources.customer-pricelist-articles.fields.price_a" />
            <PriceField sortable={false} source="total" label="resources.order-pricelist-history.total" />
            <TextField sortable={false} source="service_description" 
                       label="resources.customer-pricelist-articles.fields.service_description" />

            <FunctionField sortable={false} source="steps" label="resources.order-pricelist-history.steps" 
                           render={record => <StepsPopover record={record} steps={record.steps} />} />
        </Datagrid>
    );
};
