import React from 'react';
import { usePermissions, useMutation, useTranslate } from 'react-admin';

import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useNotifyError } from '../../../utils/notifiers/useNotifyError';
import { OrderDocumentOutputType } from '../../../entities';

import { DocumentAddButton } from './AddButton';
import { useEntityFileUpload } from './useEntityFileUpload';
import { DropPaper } from './DropPaper';
import { DocumentItem, DocumentEditDialog } from './Item';


export const DocumentsField = (props) => {
	const { permissions } = usePermissions();
	const documentsPath = props.isOutputDocuments ? 'documents-output' : 'documents'
	const translate = useTranslate();
	const notifyError = useNotifyError();
	const [fetchAllDocuments, { data, loading }] = useMutation({
		type: 'getMany',
		resource: `${props.resource}/${props.record.id}/${documentsPath}`,
		payload: {},
	});

	const [ uploading, uploadFile, uploaded, resetUploaded ] = useEntityFileUpload(fetchAllDocuments, props.record.id, props.resource);

	const [ mutate ] = useMutation({
		resource: `${props.resource}/${props.record.id}/${documentsPath}`,
	}, {
		onSuccess: fetchAllDocuments,
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const deleteDocument = (doc) => {
		if(props.isOutputDocuments){
			if (doc.type === OrderDocumentOutputType.loading){
				props.changeIsLoading(false)
			}
			else if(doc.type === OrderDocumentOutputType.unloading){
				props.changeIsUnloading(false)
			}
		}
		if (!window.confirm("Are you sure to delete this document?")) return;
		mutate({
			type: 'delete',
			payload: {
				id: doc.id,
			},
		});
	};

	const updateDocument = (doc) => {
		mutate({
			type: 'update',
			payload: {
				id: doc.id,
				data: {
					title: doc.title,
					type: doc.type,
				}
			},
		});
	};

	React.useEffect(() => {
		fetchAllDocuments();
	}, [fetchAllDocuments]);

	React.useEffect(() => {
		if(props.isOutputDocuments && data){
			for (let documentFile of data){
				if (documentFile.type === OrderDocumentOutputType.loading){
					props.changeIsLoading(true)
				}
				else if(documentFile.type === OrderDocumentOutputType.unloading){
					props.changeIsUnloading(true)
				}
			}
		}
	}, [data]);

	if (!permissions) return null;

	return (
		<DropPaper uploading={uploading} processDocument={uploadFile} elevation={0} variant={props.variant || "outlined"}>
			{uploaded && <DocumentEditDialog open handleClose={resetUploaded} doc={uploaded} handleSave={updateDocument} />}
			{!props.isOutputDocuments && <Box p={2} pb={0} display="flex" justifyContent="space-between" alignItems="center">
				<DocumentAddButton
					uploading={uploading}
					uploadFile={uploadFile}
				/>
			</Box>}
			<Box p={2}>
				{loading && !data ? <LinearProgress /> :
				<Grid container spacing={2}>
					{data && data.map(item => 
						<Grid key={item.id} item xs={12} sm={6} md={3}>
							<DocumentItem 
								isReadOnly={props.isCustomerAsTrans && permissions.company_id !== item.company_id} 
								data={{...item, relatedEntityId: props.record.id}} 
								handleDelete={deleteDocument} 
								handleUpdate={updateDocument} 
								isOutputDocuments={props.isOutputDocuments}
								resource={props.resource} 
							/>
						</Grid>
					)}
					{data && data.length === 0 &&
						<Grid item xs={12}>
							<Typography align="center" variant="body2" color="textSecondary">
								{!props.isOutputDocuments ? 
									translate("osfc.fields.document.no_documents") : translate("osfc.fields.output-document.no_documents")
								}
							</Typography>
						</Grid>
					}
				</Grid>}
			</Box>
		</DropPaper>
	);
};


export const DocumentsFieldBlock = (props) => <DocumentsField {...props} />;
