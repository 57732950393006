import React from 'react';
import {
	usePermissions,
} from 'react-admin';
import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';
import { NotificationSettingsForm } from './NotificationSettingsForm';

export const NotificationSettings = () => {
	const { permissions } = usePermissions();

	if (!permissions) {
		return null;
	};
	return (
		<UpdateForm
			id={permissions.id}
			resource="settings"
			render={({ body, buttons }) => (
				<CardBlock  buttons={buttons} children={body} />
			)}
			permissions={permissions}
		>
			<NotificationSettingsForm/>
		</UpdateForm>

	);
}
