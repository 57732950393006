import React from "react";
import { Create } from 'react-admin';

import { useMutateForm } from '../../../api/useMutateForm';

import { SimpleFormAdv } from '../../../components/forms/SimpleFormAdv';
import { FormManager } from '../forms/FormManager';
import { FormCustomer } from '../forms/FormCustomer';

import { 
	SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar 
} from '../../../components/toolbars/SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar';
import { OrderService } from '../../../entities';

import {
	orderWarningValidations,
} from '../../../components/_helpers/warningsConditions';


export const makeOrderCreate = (Form) => (props) => {
	const [mutateForm, state]  = useMutateForm("create", props.resource, props.basePath, null, {}); // eslint-disable-line 
	const toolbarCompProps = {
		warningValidations: orderWarningValidations,
	};

	return (
		<Create {...props}>
			<SimpleFormAdv
				save={mutateForm}
				initialValues={{
					commodity: "salmon",
					split_load: false,
					priority: false,
					service: OrderService.reloadCarCar,
					plumb_seal: false,
					printed_doc: false,
				}}
				toolbarComp={SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar} 
				toolbarCompProps={toolbarCompProps}			
			>
				<Form isAddMode={true} />
			</SimpleFormAdv>
		</Create>
	);
};


export const OrderCreate = makeOrderCreate(FormManager);
export const OrderCreateByManager = OrderCreate;
export const OrderCreateByCustomer = makeOrderCreate(FormCustomer);
