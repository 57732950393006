import React from 'react';

import { CompareArticlesPreviewButton } from '../../../../components/dialogs/CompareListsPreviewButton';


export const CompareWithActual = ({ data: record }) => (
    <CompareArticlesPreviewButton 
        compareButtonLabel='osfc.buttons.compare-backup-pricelist-with-actual'
        compareLeftPayload={{
            'endpoint': 'pricelist-articles-with-special',
            'filter': { 'terminal_id': [record.terminal_id] },
        }}
        compareRightPayload={{
            'endpoint': `backup-pricelist/${record.id}/articles-with-special`,
            'filter': { 'terminal_id': record.terminal_id },
        }}
    />
);
