import React, { useState } from 'react';

import ExportIcon from '@material-ui/icons/TableChart';
import { ProgressIcon } from '../ProgressIcon';
import ColumnIcon from '@material-ui/icons/ViewColumn';
import { Button, useTranslate } from 'react-admin';

import { useSimpleModal } from './useSimpleModal';

import { httpClient } from '../../api/httpClient';

import { ToolbarButton } from './OrdersExportDialog/ToolbarButton';
import { ExportModalBase } from './OrdersExportDialog';
import {CheckboxGroupDrawer} from "./CheckboxGroupDrawer";

const ExportModal = ({ onModalClose, columnsChoices, columns, endpoint, filters, sort }) => {	
	const [ columnsSelected, setColumnsSelected ] = React.useState(columns);

	const columnsModal = useSimpleModal();
	const translate = useTranslate()

	const onColumnsClose = (val) => {
		setColumnsSelected(val);
		columnsModal.handleClose();
	};

	const filtersData = React.useMemo(() => ({
		...filters,
		excluded_columns: columns.filter(x => !columnsSelected.includes(x))
	}), [filters, columns, columnsSelected]);
	
	const transColumns = translate("osfc.buttons.columns");

	return (
		<ExportModalBase filters={filtersData} sort={sort} endpoint={endpoint} onModalClose={onModalClose} 
			titleButtons={<>				
				<ToolbarButton onClick={columnsModal.handleOpen} icon={<ColumnIcon/>}>{transColumns}</ToolbarButton>
				{columnsModal.opened && <CheckboxGroupDrawer title={transColumns} choices={columnsChoices} value={columnsSelected} onClose={onColumnsClose} />}				
			</>}
		/>
	);
};

export const DataExport = ({filters, sort, data}) => {
	const exportModal = useSimpleModal();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ columns, setColumns ] = React.useState([]);
	const [ columnsChoices, setColumnsChoices ] = React.useState([]);
	const onOpen = () => {
		setIsLoading(true);
		
		httpClient(`${data}-export-header`)
			.then((promColumnsRes) => {		
				if (promColumnsRes.json) {
					const _columnsChoices = promColumnsRes.json.map(x => ({id: x[0], name: x[1]}));			
					const _columnsSelected = promColumnsRes.json.map(x => x[0]);
					setColumnsChoices(_columnsChoices);
					setColumns(_columnsSelected);				
				}			
				exportModal.handleOpen();	
			})
			.catch(err => {
                console.error(err);                
            })
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<React.Fragment>
			<Button label="osfc.buttons.export_search" disabled={isLoading} startIcon={isLoading ?  <ProgressIcon /> : <ExportIcon />} alignIcon="left" onClick={onOpen} />
			{exportModal.opened && <ExportModal endpoint={data} onModalClose={exportModal.handleClose} columns={columns} columnsChoices={columnsChoices} filters={filters} sort={sort} />}
		</React.Fragment>
	);
};

