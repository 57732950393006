import React from 'react';
import { ReferenceField, TextField, usePermissions } from 'react-admin';

import { DateTimeField } from '../../../components/fields/DateFormatField';
import { YesNoField } from '../../../components/fields/YesNoField';
import { TextPopoverField } from '../../../components/fields/TextPopoverField';
import { MyDatagrid } from '../../../components/HistoryGrid/Grid';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { TaskServiceNames } from '../../../entities/TaskService';


const changedCellStyle = {
	boxShadow: '0px 0px 100px rgba(255,0,0,0.5) inset',
};


export const TaskHistoryGrid = ({ nextForRecordId, ...props }) => {
    const { permissions } = usePermissions();

    if (!permissions) return null;

    const cellStyle = (fieldName, record) => {
		if (!props.data || fieldName === "updated_at") return;
        if (!nextForRecordId) return;
        if (!record) return;

		const nextRecordId = nextForRecordId[record.id];
		if (!nextRecordId) return;
		if (props.data[nextRecordId][fieldName] !== record[fieldName]) return changedCellStyle;
    };
    
    return (
        <MyDatagrid {...props} cellStyle={cellStyle}>
            <TextField 
                sortable={false} 
                source="updated_by_name" 
                label="resources.tasks.fields.updated_by" 
            />

            <TextField 
                sortable={false} 
                source="title" 
                label="resources.tasks.fields.title" 
            />

            <TextField 
                sortable={false} 
                source="Rank" 
                label="resources.tasks.fields.rank" 
            />

            <TextField 
                sortable={false} 
                source="rank_auto" 
                label="resources.tasks.fields.rank_auto" 
            />

            <DateTimeField 
                sortable={false} 
                source="created_at" 
                label="resources.tasks.fields.created_at" 
            />

            <DateTimeField 
                sortable={false} 
                source="updated_at" 
                label="resources.tasks.fields.updated_at" 
            />

            <ReferenceField 
                sortable={false} 
                source="terminal_id" 
                label="resources.tasks.fields.terminal" 
                reference="terminal" 
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>

            <YesNoField 
                sortable={false} 
                source="priority" 
                label="resources.tasks.fields.priority" 
            /> 

            <SelectEnumField 
                sortable={false} 
                source="service" 
                enumObj={TaskServiceNames} 
                reverse
                label="resources.tasks.fields.service" 
            />

            <ReferenceField 
                sortable={false} 
                source="status" 
                label="resources.tasks.fields.status" 
                reference="task-statuses" 
                link={false}
            >
                <TextField source="name" />
            </ReferenceField>

            <TextField 
                sortable={false} 
                source="estimate_duration" 
                label="resources.tasks.fields.estimate_duration" 
            />

            <TextField 
                sortable={false} 
                source="deadline_date" 
                label="resources.tasks.fields.deadline_date" 
            />

            <TextField 
                sortable={false} 
                source="deadline_time" 
                label="resources.tasks.fields.deadline_time" 
            />

            <TextField 
                sortable={false} 
                source="eta_date" 
                label="resources.tasks.fields.eta_date" 
            />

            <TextField 
                sortable={false} 
                source="eta_time" 
                label="resources.tasks.fields.eta_time" 
            />

            <TextField 
                sortable={false} 
                source="etd_date" 
                label="resources.tasks.fields.etd_date" 
            />

            <TextField 
                sortable={false} 
                source="etd_time" 
                label="resources.tasks.fields.etd_time" 
            />

            <TextPopoverField 
                sortable={false}
                source="description" 
                label="resources.tasks.fields.description" 
            />

            <TextPopoverField 
                sortable={false}
                source="notes" 
                label="resources.tasks.fields.notes" 
            />
        </MyDatagrid>
    );
};
