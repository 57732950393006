export const validateRequiredLoadKilo = (dis) => (val, values) => {
	const isLoadTimeKiloEmpty = !values['load_time_kilo'] && values['load_time_kilo'] !== 0;
    const isLoadTimePalletEmpty = !values['load_time_pallet'] && values['load_time_pallet'] !== 0;

	const isLoadTimeKiloRequired = !(isLoadTimeKiloEmpty && !isLoadTimePalletEmpty) && isLoadTimeKiloEmpty;

    if (isLoadTimeKiloRequired) {
		return "ra.validation.required";
	}		
	return undefined
};

export const validateRequiredLoadPallet = (dis) => (val, values) => {
	const isLoadTimeKiloEmpty = !values['load_time_kilo'] && values['load_time_kilo'] !== 0;
    const isLoadTimePalletEmpty = !values['load_time_pallet'] && values['load_time_pallet'] !== 0;

	const isLoadTimePalletRequired = !(isLoadTimePalletEmpty && !isLoadTimeKiloEmpty)  && isLoadTimePalletEmpty;

    if (isLoadTimePalletRequired) {
		return "ra.validation.required";
	}		
	return undefined
};
