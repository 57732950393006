import React from 'react';
import { SimpleForm, Create } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { RecurringTaskForm } from '../forms/RecurringTaskForm';


export const CreateRecurringTask = (props) => (
    <Create {...props}>
        <SimpleForm 
            submitOnEnter={false} 
            toolbar={<SaveWithCancelToolbar />} 
            redirect='list'
        >
            <RecurringTaskForm {...props} />
        </SimpleForm>
    </Create>
);
