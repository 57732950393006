import React from 'react';
import {useParams} from 'react-router-dom'
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { GridForm, GridInput } from '../../../components/GridForm/';
import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

const BaseForm = (props) => (
	<GridForm {...props}>
		<GridInput
			sm={6}
			component={TextInput}
			source="name"
			validate={[required()]}
			autoFocus
		/>
	</GridForm>
);

export const EditAPIKey = (props) => {
	const { id } = useParams();

	return (
		<Edit id={id} basePath="" resource="api-keys">
			<SimpleForm
				toolbar={<SaveWithCancelToolbar />}
				redirect={'/settings/api-keys'}
			>
				<BaseForm />
			</SimpleForm>
		</Edit>
	);
};
