import ViewListIcon from '@material-ui/icons/ViewList';


import { TasksList } from './List/List';
import { TaskCreate } from './Create/Create';
import { TaskEdit } from './Edit/Edit';
import { TaskShowTabsWrapper } from './Show/Show';

export * from './Create/Create';
export * from './Edit/Edit';
export * from './Show/Show';


export const tasksResource = {
	list: TasksList,
	create: TaskCreate,
	edit: TaskEdit,
	show: TaskShowTabsWrapper,
	icon: ViewListIcon,
}

