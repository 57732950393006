export const cellRules = {
    head: {
        time: {bg:null}, 
        man_hours:  {bg:'#C1E4F5'}, 
        tisbanken:  {bg:'#C1E4F5'}, 
        diff: {bg:'#C1E4F5'}, 
        man_hours_grouped_1:  {bg:'#F6C5AC'}, 
        tisbanken_grouped_1:  {bg:'#F6C5AC'}, 
        diff_grouped_1:  {bg:'#F6C5AC'}, 
        man_hours_grouped_2:  {bg:'#FFC000'}, 
        tisbanken_grouped_2:  {bg:'#FFC000'}, 
        diff_grouped_2:  {bg:'#FFC000'}, 
    },
    body: {
        "00-01": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 6},
            tisbanken_grouped_1: {bg: null, rowspan: 6},
            diff_grouped_1: {bg: null, rowspan: 6},
            man_hours_grouped_2: {bg: null, rowspan: 6},
            tisbanken_grouped_2: {bg: null, rowspan: 6},
            diff_grouped_2: {bg: null, rowspan: 6}
        },
        "01-02": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "02-03": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "03-04": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "04-05": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "05-06": {
            time: {bg: "#D9D9D9", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "06-07": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 3},
            tisbanken_grouped_1: {bg: null, rowspan: 3},
            diff_grouped_1: {bg: null, rowspan: 3},
            man_hours_grouped_2: {bg: null, rowspan: 9},
            tisbanken_grouped_2: {bg: null, rowspan: 9},
            diff_grouped_2: {bg: null, rowspan: 9}
        },
        "07-08": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "08-09": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "09-10": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 3},
            tisbanken_grouped_1: {bg: null, rowspan: 3},
            diff_grouped_1: {bg: null, rowspan: 3},
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "10-11": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "11-12": {
            time: {bg: "#B3E5A1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "12-13": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 3},
            tisbanken_grouped_1: {bg: null, rowspan: 3},
            diff_grouped_1: {bg: null, rowspan: 3},
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "13-14": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "14-15": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "15-16": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 3},
            tisbanken_grouped_1: {bg: null, rowspan: 3},
            diff_grouped_1: {bg: null, rowspan: 3},
            man_hours_grouped_2: {bg: null, rowspan: 7},
            tisbanken_grouped_2: {bg: null, rowspan: 7},
            diff_grouped_2: {bg: null, rowspan: 7}
        },
        "16-17": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "17-18": {
            time: {bg: "#C1E4F5", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "18-19": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 6},
            tisbanken_grouped_1: {bg: null, rowspan: 6},
            diff_grouped_1: {bg: null, rowspan: 6},
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "19-20": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "20-21": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "21-22": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "22-23": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: {bg: null, rowspan: 3},
            tisbanken_grouped_2: {bg: null, rowspan: 3},
            diff_grouped_2: {bg: null, rowspan: 3}
        },
        "23-00": {
            time: {bg: "#D1D1D1", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: null,
            tisbanken_grouped_1: null,
            diff_grouped_1: null,
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
        "No ETA": {
            time: {bg: "#FFFF00", rowspan: 1}, 
            man_hours: {bg: null, rowspan: 1},
            tisbanken: {bg: null, rowspan: 1},
            diff: {bg: null, rowspan: 1}, 
            man_hours_grouped_1: {bg: null, rowspan: 1},
            tisbanken_grouped_1: {bg: null, rowspan: 1},
            diff_grouped_1: {bg: null, rowspan: 1},
            man_hours_grouped_2: null,
            tisbanken_grouped_2: null,
            diff_grouped_2: null
        },
    }    
}
