export const OSLOCameraId = {
    OSLO_92: 92,
    OSLO_93: 93,
}

export const WSCCameraId = {
    WSC_84: 84,
    WSC_85: 85,
    WSC_92: 92,
    WSC_93: 93,
}

export const OSLOIp = {
    92:  [
        { id: "172.22.10.81", name: "172.22.10.81" },
        { id: "172.22.10.95", name: "172.22.10.95" }
    ],
    93: [{ id: "172.22.10.95" , name: "172.22.10.95" },]
}



export const WSCIp = {
    84:[{ id: "172.22.16.47", name: "172.22.16.47" }],
    85:[{ id: "172.22.16.51", name: "172.22.16.51" }],
    92:[{ id: "172.22.10.95", name: "172.22.10.95" }],
    93:[{ id: "172.22.10.95", name: "172.22.10.95" }],
}