import React from "react";

import { useTranslate } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { NumberInputEx } from "../../../../components/inputs";


export const EstimateDurationInput = (props) => {
    const translate = useTranslate();

    const endAdornment = (
        <InputAdornment
            position="end"
        >
            <span>{translate('osfc.fields.special.minutes')}</span>
        </InputAdornment>
    );

    return (
        <NumberInputEx {...props} InputProps={{ endAdornment }} />
    );
};
