import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';
                        
export const WorkforceRow = ({ classes, record, style, ...props }) => {
    return (
        <TableRow>
            {record.map(elem => {
                return(elem && <TableCell rowSpan={elem['rowspan']} style={{backgroundColor: elem['bg'], padding: "5px", ...style}} >
                                   {elem['val']}
                               </TableCell> 
                )
            })}
        </TableRow>)
};
