import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Button as ButtonMUI  from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { Button, TextField, ReferenceField, useMutation, FunctionField, useTranslate, useRefresh } from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';

import { ProgressIcon } from '../ProgressIcon';
import { httpClient } from '../../api/httpClient';
import { useNotifyError } from '../../utils/notifiers/useNotifyError';
import { useNotifySuccess } from '../../utils/notifiers/useNotifySuccess';
import { PriceField } from '../fields/PriceField';
import { cloneDeep, keyBy } from 'lodash';
import { ToolbarButton } from './OrdersExportDialog/ToolbarButton';
import { DiffList, DiffField, listCalcDiff } from '../DiffList';


export const ArticlePreviewButton = (props) => {
	const t = useTranslate();
	const refresh = useRefresh();
	const notifyError = useNotifyError();
	const notifySuccess = useNotifySuccess();

	const [uploadData, setUploadData] = useState();
	const [actualData, setActualData] = useState();
	const [data, setData] = useState();
	const [fileName, setFileName] = useState();

	useEffect(() => {
		let _data;
        if (uploadData && actualData) {
			_data = cloneDeep(uploadData);
            listCalcDiff(actualData, _data, { root: "code", special_prices: "company_id"}, "root", {special_prices: ["company_name"]});
		}
		setData(_data);
    }, [uploadData, actualData]);

	
	const [getActualData, { loadingActualData }] = useMutation({
        type: 'getManyReference',
        resource: 'pricelist-articles-with-special',
		payload: {
			filter: props.filters,
			pagination: { page: 1, perPage: 1000 },
			sort: { field: 'id', order: 'DESC' },
		}
    }, {
		onSuccess: (res) => {
			setActualData(res.data);
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	
	const [change, { loadingChange }] = useMutation({
        type: 'create',
        resource: 'pricelist-articles-change',
		payload: {
			data: {
				terminal_id: props.terminalId,
				file_name: fileName
			}
		}
    }, {
		onSuccess: (data) => {
			notifySuccess(t("osfc.dialogs.pricelist-articles-preview.msg.success"));
			setFileName(undefined);
			setUploadData(undefined);
			setData(undefined);
			setActualData(undefined);
			refresh();
		},
		onFailure: (error) => {
			notifyError(error);
		}
	});

	const onUploaded = ({file_name, items}) =>{
		setFileName(file_name);
		setUploadData(items);
		if (!actualData){
			getActualData();
		}		
	}

	const handleSubmit = (values) => {
		change({
			payload: {
				data: {
					terminal_id: props.terminalId,
					file_name: fileName
				}
			}
		});
	}

	const handleClose = () => {
		setFileName(undefined);
		setUploadData(undefined);
		setData(undefined);
	}

	const loading = loadingActualData || loadingChange;

	return (<>	
		<UploadButton
			url="pricelist-articles-upload-preview"
			uploadParams={{terminal_id: props.terminalId}}			
			acceptTypes=".xlsx"
			label={t("osfc.buttons.upload_articles")}
			disabled={loading || props.disabled || !props.terminalId}			
			onUploaded={onUploaded}
		/>
		{data && <Dialog open={true} maxWidth="lg" onClose={handleClose}>
			<DialogTitle>{t("osfc.dialogs.pricelist-articles-preview.title")}</DialogTitle>
			<DialogContent>
				<ArticlePreviewGrid data={data} />
			</DialogContent>
			<DialogActions>
				<Button label={t("ra.action.cancel")} disabled={loading} onClick={handleClose} color="primary" />
				<Button label={t("osfc.buttons.apply")} disabled={loading} onClick={handleSubmit} color="primary" />
			</DialogActions>
		</Dialog>}
	</>);
};


export const ArticlePreviewGrid = ({data, ...props}) => {
	const [ids, setIds] = React.useState();
	const [dicData, setDicData] = React.useState();

	useEffect(()=>{		
		if (data) {
			const _ids = data.map(x => x["id"]);
			setIds(_ids);
			const _dicData = keyBy(data, "id");
			setDicData(_dicData);			
		} else {
			setIds(undefined);
			setDicData(undefined);
		}
	}, [data]);
	
	return ((dicData && ids) ?
		<DiffList value={{ ids: ids, data: dicData, currentSort: { field: 'code', order: 'ASC' } }}>
			<TextField source="code" sortable={false} />
			<ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
				<TextField label="Terminal" source="name" />
			</ReferenceField>

			<DiffField component={TextField} source="name" sortable={false} />
			<DiffField component={TextField} source="calculation" sortable={false} />
			<DiffField component={PriceField} source="price_a" sortable={false} />
			<DiffField component={PriceField} source="price_b" sortable={false} />
			<DiffField component={PriceField} source="price_c" sortable={false} />
			<DiffField component={PriceField} source="price_d" sortable={false} />
			<DiffField component={PriceField} source="price_e" sortable={false} />
			<DiffField component={TextField} source="service_description" sortable={false} />
			<SpecialPricesField source="special_prices" sortable={false} />
		</DiffList> :
	<></>);
};

const SpecialPricesField = (props) => {
	return (
	  <FunctionField {...props} render={() =>
		<SpecialPricesButton data={props.record["special_prices"]} isDiff={props.record["_old_special_prices"]} />
	  } />
	);
  };


const SpecialPricesButton = ({data, isDiff, ...props}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const t = useTranslate()
  
	const handleOpen = (e) => {	  
		setAnchorEl(e.currentTarget);
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClose = (e) => {
		setAnchorEl(null);
		e.preventDefault();
		e.stopPropagation();
	};
  
	const isOpen = Boolean(anchorEl);
	const id = isOpen ? 'simple-popover' : undefined;
	const className = isDiff ? "cell-btn-diff" : "";
  
	return (
		<div className={className}>
			<Button label={t("resources.pricelist-articles.button.prices")} onClick={handleOpen} color="primary" fullWidth={true} />
			<Popover id={id} open={isOpen} anchorEl={anchorEl}		  
				anchorOrigin={{ vertical: 'center', horizontal: 'left'}}
				transformOrigin={{ vertical: 'center', horizontal: 'right'}}
				onClose={handleClose}
			>
				<ArticlePreviewSpecialPrices data={data} />
			</Popover>
		</div>
	);
  }
  const ArticlePreviewSpecialPrices = ({data, ...props}) => {
	const [ids, setIds] = React.useState();
	const [dicData, setDicData] = React.useState();

	useEffect(()=>{		
		if (data) {
			const _ids = data.map(x => x["company_id"]);
			setIds(_ids);
			const _dicData = keyBy(data, "company_id");
			setDicData(_dicData);			
		} else {
			setIds(undefined);
			setDicData(undefined);
		}
	}, [data]);
	
	return ((dicData && ids) ?
		<DiffList value={{ ids: ids, data: dicData, currentSort: { field: 'company_name', order: 'ASC' } }}>
			<TextField source="company_name" sortable={false} />			
			<DiffField component={PriceField} source="price" sortable={false} />
		</DiffList> :
	<></>);
};


const UploadButton = ({url, uploadParams, acceptTypes, label, disabled, onUploaded }) => {	
	const inputRef = React.useRef(null);
	const [uploading, setUploading] = useState(false)
	const notifyError = useNotifyError();

	const handleBtnClick = React.useCallback(() => inputRef.current.click(), []);

	const handleFileChange = React.useCallback(async (e) => {
		const file = e.target.files[0];
		const data = new FormData();
		data.append('file', file);
		if (uploadParams) {
			for (const [key, value] of Object.entries(uploadParams)) {
				data.append(key, value);
			}
		}
		setUploading(true);
		httpClient(url, {method: 'POST', body: data})
			.then((res) => {
				if (onUploaded) onUploaded(res.json);
			})
			.catch((error) => {
				notifyError(error);
			})
			.finally(() => {
				setUploading(false);
			});
	}, [url, uploadParams]);

	return (
		<ToolbarButton
			disabled={disabled || uploading}
			icon={uploading ? <ProgressIcon /> : <PublishIcon />}
			onClick={handleBtnClick}
		>
			<input id="file" type="file" style={{display: "none"}}
				ref={inputRef}
				accept={acceptTypes}
				onclick="this.value=null;"
				onChange={handleFileChange}
			/>
			{label}
		</ToolbarButton>
	);
}
