import React from "react";
import { ReferenceManyField, Pagination } from "react-admin";

import { HistoryDatagrid } from './Grid';


export const HistoryField = ({children, ...props}) => (
	<ReferenceManyField {...props} perPage={15} 
						pagination={<Pagination rowsPerPageOptions={[5, 15, 25, 50, 100]} />}>
		<HistoryDatagrid children={children} />
	</ReferenceManyField>
);


HistoryField.defaultProps = {
	addLabel: true,
	className: "ra-field-datagrid",
};
