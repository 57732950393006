import React, { useState } from "react";
import {
    SelectInput,
    required, useInput
} from "react-admin";

import { RecurringTaskType } from "../../entities/RecurringTaskType";
import { MonthOptions } from "../../entities/RecurringTaskMonthOptions";
import { GridInput } from "../../components/GridForm";
import { DateInput } from "../../components/inputs";
import { SelectEnumInput } from "../../components/inputs/SelectEnumInput";
import { addTimeToDate, isLastDayOfMonth } from "./utils/timeOperations";
import { RecurringTaskRecurringOptionsType } from "../../entities/RecurringTaskRecurringOptionsType";

import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { IconButton } from '@material-ui/core';
import { useNotifyError } from "../../utils/notifiers/useNotifyError";
import "./styles.css";

const requiredValidator = [required()];
const days = [
    { label: 'S', value: 0 },
    { label: 'M', value: 1 },
    { label: 'T', value: 2 },
    { label: 'W', value: 3 },
    { label: 'Th', value: 4 },
    { label: 'F', value: 5 },
    { label: 'Sat', value: 6 },
];

const weekDays = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
}


const Checker = ({ inp: InputComponent, selectedOption, value, label, onChange, inputChange, path, occurrences, values }) => {
    return (
        <div style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
            <GridInput component={FormControlLabel}
                control={<Radio checked={selectedOption == value} onChange={onChange} value={value} />}
                label={label} xs={3}
            />
            <GridInput component={InputComponent}
                source={path}
                onChange={inputChange}
                label=""
                disabled={selectedOption != value}
                occurrences={occurrences}
                values={values}
                style={{
                    width: '200px',
                    paddingTop: value === '3' ? '10px' : '0px'
                }}
                xs={9}
            />
        </div>
    )
}


export const EndTimeSettings = ({ form, values }) => {
    const [selectedOption, setSelectedOption] = useState('1');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimes, setSelectedtimes] = useState(null);

    React.useEffect(() => {
        setSelectedOption(values.end_option)
    }, [values])

    const changeDate = (new_date) => {
        const date = new Date(new_date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(formattedDate);
    }

    const changeTimes = (times) => {
        setSelectedtimes(times);
    }

    const handleRadioChange = (event) => {
        const option = event.target.value;

        if (option == RecurringTaskRecurringOptionsType.never) {
            
            form.change("repeat_times", null);
            form.change("end_date", null);

        } else if (option == RecurringTaskRecurringOptionsType.on) {
            form.change("repeat_times", null);
        } else if (option == RecurringTaskRecurringOptionsType.after) {
            form.change("repeat_times", 1);
        }
        form.change("end_option", option);
        setSelectedOption(option);
    };
    return (
        <Grid container alignItems="center" direction="row" xs={12} md={12} lg={9} style={{ marginTop: '10px', marginLeft: "10px", marginRight: "40px", marginBottom: "20px" }}>
            <GridInput component={FormControlLabel}
                control={<Radio checked={selectedOption == RecurringTaskRecurringOptionsType.never} onChange={handleRadioChange} value={RecurringTaskRecurringOptionsType.never} />}
                label="Never" xs={12} md={2}
            />
            <GridInput component={Checker} inp={DateInput} selectedOption={selectedOption}
                onChange={handleRadioChange} inputChange={changeDate}
                value={RecurringTaskRecurringOptionsType.on} label="On date" xs={12} md={5} path="end_date" />

            <GridInput component={Checker} inp={CustomNumberInput} selectedOption={selectedOption}
                onChange={handleRadioChange} inputChange={changeTimes} occurrences={true} values={values}
                value={RecurringTaskRecurringOptionsType.after} label="After" xs={12} md={5} path="repeat_times" />

        </Grid>
    );
};


const DaySelector = ({ form, values }) => {
    const [selectedDays, setSelectedDays] = useState(values?.repeat_options?.week_days_repeat || []);

    const handleDayClick = (dayValue) => {
        if (selectedDays.includes(dayValue)) {
            const newSelectedDays = selectedDays.filter(day => day !== dayValue);
            const updatedRepeatOptions = { ...values.repeat_options, week_days_repeat: newSelectedDays };
            form.change("repeat_options", updatedRepeatOptions)
            setSelectedDays(newSelectedDays);
        } else {
            const newSelectedDays = [...selectedDays, dayValue];
            const updatedRepeatOptions = { ...values.repeat_options, week_days_repeat: newSelectedDays };
            form.change("repeat_options", updatedRepeatOptions)
            setSelectedDays(newSelectedDays);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'sans-serif',
            paddingTop: '10px',
        }}>
            <div style={{
                display: 'flex',
                gap: '10px',
                paddingLeft: '8px',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}>
                {days.map((day) => (
                    <button
                        type="button"
                        key={day.value}
                        onClick={() => handleDayClick(day.value)}
                        style={{
                            padding: '10px',
                            borderRadius: '50%',
                            border: '1px solid #ddd',
                            backgroundColor: selectedDays.includes(day.value) ? '#2979ff' : '#f1f1f1',
                            color: selectedDays.includes(day.value) ? '#fff' : '#000',
                            cursor: 'pointer',
                            fontSize: '14px',
                            width: '40px',
                            height: '40px',
                        }}
                    >
                        {day.label}
                    </button>
                ))}
            </div>
        </div>
    );
};


const MonthRepeat = ({ form, values }) => {
    const [choices, setChoices] = useState([])
    const [selectedOption, setSelectedOption] = useState(values?.repeat_options?.month_repeat || '1');

    const changeOption = (option) => {
        const _option = option.target.value;
        setSelectedOption(_option);

        const updatedRepeatOptions = { ...values.repeat_options, month_repeat: _option };
        form.change("repeat_options", updatedRepeatOptions);
    }


    React.useEffect(() => {
        if (values.start_date) {
            const date = new Date(values.start_date);

            const dayOfMonth = date.getDate();
            const dayOfWeek = date.getDay();
            const year = date.getFullYear();
            const month = date.getMonth();

            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

            const weeksInMonth = Math.ceil((daysInMonth + startOfMonth) / 7);
            const weekNumber = Math.ceil((dayOfMonth + startOfMonth.getDay()) / 7);

            let option = ""
            if (weekNumber == 1) {
                option = `Every month on ${weekDays[dayOfWeek]} every first week `
            } else if (weekNumber == 2) {
                option = `Every month on ${weekDays[dayOfWeek]} every second week `
            } else if (weekNumber == 3) {
                option = `Every month on ${weekDays[dayOfWeek]} every third week `
            } else if (weekNumber == 4) {
                if (weeksInMonth == 4) {
                    option = `Every month on ${weekDays[dayOfWeek]} every last week `
                } else {
                    option = `Every month on ${weekDays[dayOfWeek]} every fourth week `

                }
            } else if (weekNumber == 5) {
                option = `Every month on ${weekDays[dayOfWeek]} every last week `
            }
            if (isLastDayOfMonth(date)) {
                setChoices([
                    { id: MonthOptions.byDate, name: `Every month on ${dayOfMonth}th` },
                    { id: MonthOptions.byWeek, name: option },
                    { id: MonthOptions.lastDayOfMonth, name: "Every month on last day of month" },

                ])            
            } else{
                setChoices([
                    { id: MonthOptions.byDate, name: `Every month on ${dayOfMonth}th` },
                    { id: MonthOptions.byWeek, name: option },
                ])
            }
           

        }
    }, [values.start_date])

    if (!values.start_date) return null;

    return (
        <GridInput component={SelectInput}
            style={{ marginTop: '-0.5px' }}
            label=""
            onChange={changeOption}
            defaultValue={1}
            value={selectedOption}
            source="repeat_options[month_repeat]"
            choices={choices} xs={12} md={3} />
    )
}


const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        fontSize: '16px',
        outline: 'none',
    },
    input: {
        width: '50px',
        height: '34px',
        textAlign: 'center',
        padding: '6px 8px',
        border: 'none',
        outline: 'none',
        fontSize: '16px',
        margin: 0,

    },
    occurrences: {
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        backgroundColor: 'transparent',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    },

});


const CustomNumberInput = (props) => {
    const classes = useStyles(props);
    const occurrences = props.occurrences;
	const notifyError = useNotifyError();

    const [value, setValue] = useState(null);

    React.useEffect(() => {
        if(occurrences){
            setValue(props.values?.repeat_times);
        } else if (!occurrences){
            setValue(props.values?.repeat_every);
        }

    }, [props.values])

    const { input, meta } = useInput(props);
    const disabled = props.disabled;

    const handleIncrement = () => {
        setValue(prev => {
            let newValue;
            if(occurrences){
                newValue = (props.values?.repeat_times || prev) + 1;
            } else{
                newValue = (props.values.repeat_every || prev) + 1;
            }
            input.onChange(newValue);
            return newValue;
        });
    };
    
    const handleDecrement = () => {
        setValue(prev => {
            let newValue;
            if(occurrences){
                if(props.values?.repeated_times){
                    newValue = Math.max(1, (props.values?.repeat_times || prev) - 1);
                    if(newValue < props.values.repeated_times){
                        notifyError(`Repeat times should be greater than the times the task has already been created. 
                                        Task was created ${props.values.repeated_times} times`)
                        return prev;
                    }
                } else{
                    newValue = Math.max(1, (props.values?.repeat_times || prev) - 1);
                }
            } else{
                newValue =  Math.max(1, (props.values.repeat_every || prev) - 1);
            }
            input.onChange(newValue);
            return newValue;
        });
    };
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-3px' }}>
            {props.occurrences ?
                <div className={classes.inputContainer}>
                    <input
                        {...input}
                        type="number"
                        onChange={(e) => {
                            const newValue = Math.max(1, Number(e.target.value));
                            setValue(newValue);
                            input.onChange(newValue);
                        }}
                        placeholder="1"
                        disabled={disabled}
                        className={classes.input}
                    />
                    <span className={classes.occurrences}>occurrences</span>
                </div>
                :
                <input
                    {...input}
                    type="number"
                    placeholder="1"
                    onChange={(e) => {
                        const newValue = Math.max(1, Number(e.target.value));
                        setValue(newValue);
                        input.onChange(newValue);
                    }}
                    style={{
                        width: "100%",
                        height: "34px",
                        textAlign: 'center',
                        marginRight: '8px',
                        padding: '6px 8px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        fontSize: '16px',
                        outline: 'none',
                        margin: 0,
                    }}
                />
            }
            <div style={{ display: disabled ? 'none' : 'flex', flexDirection: 'column' }}>
                <IconButton
                    onClick={handleIncrement}
                    aria-label="increase"
                    style={{ paddingRight: '0', paddingTop: '0', paddingBottom: '0', padddingLeft: '4px' }}
                >
                    +
                </IconButton>
                <IconButton
                    onClick={handleDecrement}
                    disabled={value <= 1}
                    aria-label="decrease"
                    style={{ paddingRight: '0', paddingTop: '0', paddingBottom: '0', padddingLeft: '4px' }}
                >
                    -
                </IconButton>
            </div>
        </div>
    );
};


const AdaptiveText = ({ text }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '45px',
            }}
        >
            <Typography variant="body2" style={{ textAlign: "center" }}>
                {text}
            </Typography>
        </div>
    )
}

export const RepeatType = ({ form, values, onChange }) => {

    const changeRepeatEvery = (value) => {
        // INFO Changing occurences or date vased on end_option type
        if(values.end_option === RecurringTaskRecurringOptionsType.after){
            form.change('end_date', addTimeToDate(values.start_date, values.repeat_times, value || 1, values.type))
        }
    }

    return (
        <div style={{ display: "flex", gap: "10px", alignItems: 'start', width: '100%' }}>
            <GridInput component={AdaptiveText} text="Repeat every" sm={2} md={3} style={{ height: '40px' }} />
            <GridInput component={CustomNumberInput} form={form} source='repeat_every' sm={4} md={4} values={values} onChange={changeRepeatEvery} />
            <GridInput component={SelectEnumInput}
                sm={6}
                md={5}
                label={false}
                key='type'
                enumName='recurring_type'
                defaultValue={RecurringTaskType.month}
                enumObj={RecurringTaskType}
                onChange={onChange}
                source='type'
                validate={requiredValidator}
                style={{ marginTop: '-0.5px' }}
            />
        </div>
    )
}


export const RepeatOptions = ({ form, values, }) => {
    return (
        (values.type === RecurringTaskType.week ? (
            <DaySelector form={form} values={values} />
        ) : values.type === RecurringTaskType.month ? (
            <MonthRepeat form={form} values={values} />
        ) : null)
    )
}



