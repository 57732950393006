import React from 'react';

import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import inflection from 'inflection';
import classnames from 'classnames';

import { 
	MenuItemLink, DashboardMenuItem, 
	getResources, useTranslate, usePermissions, useDataProvider, 
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DefaultIcon from '@material-ui/icons/ViewList';
import SubMenuItemIcon from '@material-ui/icons/Label';
import AddIcon from '@material-ui/icons/Add';
import NotificationIcon from '@material-ui/icons/Notifications';
import ExportIcon from '@material-ui/icons/TableChart';

import { OsfcRoles, CompanyManagerRoles, DriverRoles, UserRole, AppRoles } from '../entities/';
import { SubMenu } from './SubMenu';
import { isInArray } from '../utils/general';
import { SiteTimeZonesDropDown } from './SiteTimeZonesDropDown.js';

const useStyles = makeStyles(
	theme => ({
	  main: {
		display: 'flex',
		flexDirection: 'column',
		// height: '96px',
		justifyContent: 'space-between',
		// justifyContent: 'flex-start',
		marginTop: '0.5em',
		[theme.breakpoints.only('xs')]: {
		  marginTop: 0,
		  height: '100vh',
		},
		[theme.breakpoints.up('md')]: {
		  marginTop: '1.5em',
		  height: 'calc(100vh - 2.0em - 48px)'
		  // height: document.body.scrollHeight > document.documentElement.clientHeight ? 'calc(100vh - 2em - 48px)' : 'calc(100vh - 1.5em - 48px)',
		},
	  },
	}),
	{ name: 'RaMenu' }
  );

const useLinkStyles = makeStyles(
	theme => ({
        root: {
        	marginBottom: "0.6em",
        	marginRight: "0.2em",
        	paddingBottom: "0.6em",
        	paddingTop: "0.6em",
        	borderRadius: "0 0.6em 0.6em 0",
        	backgroundColor: "rgba(0, 0, 0, 0.03)",
        	border: "1px solid rgba(0, 0, 0, 0.02)",
            color: theme.palette.text.secondary,
        },
        active: {
            color: theme.palette.text.secondary,
        },
        icon: {
        	minWidth: theme.spacing(5)
        },
	}),
);

const translatedResourceName = (resource, translate) => {
	return translate(`resources.${resource.name}.name`, {
		smart_count: 2,
		_:
			resource.options && resource.options.label
				? translate(resource.options.label, {
					  smart_count: 2,
					  _: resource.options.label,
				  })
				: inflection.humanize(inflection.pluralize(resource.name)),
	});
}

const RenderResourceMenuItemLink = ({ resource, onClick, sidebarIsOpen, dense, translate }) => (
	<MenuItemLink
		key={resource.name}
		to={`/${resource.name}`}
		primaryText={translatedResourceName(
			resource,
			translate
		)}
		leftIcon={
			resource.icon ? <resource.icon /> : <DefaultIcon />
		}
		onClick={onClick}
		dense={dense}
		sidebarIsOpen={sidebarIsOpen}
	/>
);

const Menu = (props) => {
	const {
		classes: classesOverride,
		className,
		dense,
		hasDashboard,
		onMenuClick,
		logout,
		...rest
	} = props;

	const translate = useTranslate();
	const classes = useStyles(props);
	const classesLink = useLinkStyles(props);
	const isXSmall = useMediaQuery((theme) =>
		theme.breakpoints.down('xs')
	);
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	const resources = useSelector(getResources, shallowEqual);

	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();

	const [ orderHotlinks, setOrderHotlinks ] = React.useState([]);

	const isAppRole = permissions && isInArray(AppRoles, permissions.role);
	const isDriver = permissions && DriverRoles.indexOf(permissions.role) !== -1;
	const isCompanyManager = permissions && CompanyManagerRoles.indexOf(permissions.role) !== -1;
	const isOSFC = permissions && (OsfcRoles.indexOf(permissions.role) !== -1 || AppRoles.indexOf(permissions.role) !== -1);

	const isAdmin = permissions && permissions.role === UserRole.admin;

	const entitiesResources = ["tasks", "orders", "entities"];
	const OSFCEntityResources = resources.filter(resource => entitiesResources.includes(resource.name));
	const EntitiesIcon = OSFCEntityResources[0]?.icon;

	React.useEffect(() => {
		if (isOSFC){
			dataProvider.getList("hotlinks", {
				pagination: {page: 0, perPage: -1},
				sort: {field: 'sort_order', order: 'ASC'},
				filter: {},
			}).then((data) => {
				if (data?.data) {
					setOrderHotlinks(data.data);
				};
			});
		}		
	}, [dataProvider, isOSFC]);

	// Used to force redraw on navigation
	useSelector((state) => state.router.location.pathname);
	return !permissions ? null : (
		<div className={classnames(classes.main, className)} {...rest}>	
		    <div>
				{isDriver ? null :
					<MenuItemLink
						classes={classesLink}
						color="primary"
						to="/orders/create"
						primaryText={translate("resources.orders.create")}
						leftIcon={<AddIcon />}
						onClick={onMenuClick}
						dense={dense}
						sidebarIsOpen={open}
					/>}
				{hasDashboard && (
					<DashboardMenuItem
						onClick={onMenuClick}
						dense={dense}
						sidebarIsOpen={open}
					/>
				)}
				{isOSFC ?
					<SubMenu 
						primaryText={translate("resources.orders.name")}
						leftIcon={<EntitiesIcon />}
						isDropdownOpen={true}
						isAlwaysOpen={true}
					>
						{OSFCEntityResources.map(resource => (
							<RenderResourceMenuItemLink 
								resource={resource} 
								onClick={onMenuClick} 
								dense={dense} 
								sidebarIsOpen={open} 
								translate={translate}
							/>
						))}
					</SubMenu>
				: null}
				{resources
					.filter(r => r.hasList)
					.filter(r => !(isOSFC && entitiesResources.includes(r.name)))
					.map(resource => (
						<RenderResourceMenuItemLink 
							resource={resource} 
							onClick={onMenuClick} 
							dense={dense} 
							sidebarIsOpen={open} 
							translate={translate}
						/>
				))}
				{isCompanyManager && (
					<MenuItemLink
						to="/notifications"
						primaryText={translate("pages.company-settings.name")}						
						leftIcon={<NotificationIcon />}
						onClick={onMenuClick}
						dense={dense}
						sidebarIsOpen={open}
					/>
				)}
				{isAdmin && (
					<MenuItemLink
						to="/admin-orders-export-search"
						primaryText={translate("pages.admin-orders-export-search.name")}						
						leftIcon={<ExportIcon />}
						onClick={onMenuClick}
						dense={dense}
						sidebarIsOpen={open}
					/>
				)}
				{isOSFC  ?
					<SubMenu primaryText={translate("resources.hotlinks.name")}>
						{orderHotlinks.map(hotlink => (
							<MenuItemLink
								key={hotlink.name}
								to={`/${hotlink.link}`}
								primaryText={hotlink.name}
								leftIcon={<SubMenuItemIcon />}
								onClick={onMenuClick}
								dense={dense}
								sidebarIsOpen={open}
							/>
						))}
					</SubMenu>					
				: null}
				{isXSmall && logout}			
			</div>
			<div>
				<SiteTimeZonesDropDown />
			</div>
			<div>			
				<MenuItemLink
					href="mailto:se@tixe.solutions"
					component="a"
					primaryText={translate("support")}							
					leftIcon={<img src='/osfc-logo.jpg' alt='osfc' width={28} />}
					onClick={onMenuClick}
					dense={dense}
					// style={{position:'fixed', bottom: "0px"}}
					sidebarIsOpen={open}
				/>
			</div>			
		</div>);
};

Menu.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	dense: PropTypes.bool,
	hasDashboard: PropTypes.bool,
	logout: PropTypes.element,
	onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
	onMenuClick: () => null,
};

export default Menu;
