import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { TextField, FunctionField } from 'react-admin'

import { format as dateTimeFormat } from '../_helpers/dateTime';
import { DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT, format, parseISO } from '../_helpers/dateTime';


const renderHandlerWrapper = (props, handler) => (record) => handler({ ...props, record });

const handleDateValue = ({ getValue, format, record, source }) => {
	const value = getValue(record, source);
	if (!value) { return ''; }
	const date = value instanceof Date ? value : new Date(value);
	if (typeof format === 'function') {
		return format(date);
	}
	return dateTimeFormat(date, format);
};

const handleTimeValue = ({ getValue, format, record, source }) => {
	const value = getValue(record, source);
	if (!value) { return ''; }
	const date = value instanceof Date ? value : new Date(value);
	if (typeof format === 'function') {
		return format(date);
	}
	return dateTimeFormat(date, format);
};


export const DateFormatField = ({ format, getValue = get, ...props }) => (
	<FunctionField 
		{...props} 
		render={renderHandlerWrapper({ getValue, format, source: props.source }, handleDateValue)} 
	/>
);

DateFormatField.defaultProps = {
    addLabel: true,
    format: DATE_FORMAT,
};

DateFormatField.propTypes = {
    ...TextField.PropTypes,    
    showTime: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};


export const TimeFormatField = ({ format, getValue = get, ...props }) => (
	<FunctionField 
		{...props} 
		render={renderHandlerWrapper({ getValue, format, source: props.source }, handleTimeValue)} 
	/>
);

TimeFormatField.defaultProps = {
    addLabel: true,
    format: TIME_FORMAT,
};

TimeFormatField.propTypes = {
    ...TextField.PropTypes,    
    showTime: PropTypes.bool,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};


export const DateTimeField = ({ source, withLocalTimeZone, ...props }) => {
	const cache = React.useRef({});

	return (
		<FunctionField	
			{...props}
			render={record => {
				if (record[source]) {
					const k = record[source];
					if (!cache.current[k]) {
						if (k[k.length - 1] !== "Z" && withLocalTimeZone) {
							cache.current[k] = format(parseISO(k + "Z"), DATE_TIME_FORMAT);
						} else {
							cache.current[k] = format(parseISO(k), DATE_TIME_FORMAT);
						}
					}
					return cache.current[k];
				}
				return '';
			}}
		/>
	);
};


export const DateTimeFormatField = ({ formatDate, formatTime, getValue = get, ...props }) => (
	<FunctionField
		{...props}
		render={record => {
			const date = handleDateValue({ getValue, format: formatDate, source: props.source, record });
			const time = handleTimeValue({ getValue, format: formatTime, source: props.source, record });
			if (!time) {
				return date;
			}
			return date + ', ' + time;
		}}
	/>
);

DateTimeFormatField.defaultProps = {
    addLabel: true,
	formatDate: DATE_FORMAT,
	formatTime: TIME_FORMAT,
};

DateTimeFormatField.propTypes = {
    ...TextField.PropTypes,   
	showTime: PropTypes.bool,
    formatDate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    formatTime: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
