export const NotifType = {
    email: 1,
    sms: 2,
    expo_push: 8,
}


export const NotifTypeVeluesList = [
    {id: NotifType.email, value: "EMAIL"}, 
    {id: NotifType.sms, value: "SMS"}
]

