import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import { BackupLogPricelistList } from './List/List';
import { BackupLogPricelistShow } from './Show/Show';


export { 
	BackupLogPricelistList, 
	BackupLogPricelistShow 
};

export const backupLogPricelistResource = {
	list: BackupLogPricelistList,
	show: BackupLogPricelistShow,
	icon: ViewColumnIcon,
};
