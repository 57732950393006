import React from 'react';
import get from 'lodash/get';

import { FunctionField, ReferenceField, TextField, useRecordContext } from 'react-admin';


export const SelectTextField = ({ refSource, formatLabel, ...props }) => {
	const record = useRecordContext(props);
	const emptyText = get(record, props.source);

	return (
		<ReferenceField link={false} {...props} source={refSource} emptyText={emptyText}>
			{formatLabel
				? <FunctionField render={(record) => formatLabel(record)} />
				: <TextField source="name" />
			}
		</ReferenceField>
	);
};


SelectTextField.defaultProps = {
	addLabel: true,
};
