import React from 'react';
import { 
	List, Datagrid, ReferenceField, 
	TextField, Pagination,
} from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { DateTimeFormatField } from '../../../components/fields/DateFormatField';

import { BackupLogPricelistFilter } from './ListFilters';


const defaultSort = { field: 'id', order: 'DESC' };
const defaultPagination = [25, 50, 100];

const BackupLogPricelistPagination = (props) => (
	<Pagination rowsPerPageOptions={defaultPagination} {...props} />
);


export const BackupLogPricelistList = (props) => (
	<List
		{...props}
		sort={defaultSort}
		exporter={false}
		bulkActionButtons={false}
		perPage={defaultPagination[0]}
		pagination={<BackupLogPricelistPagination/>}
		filters={<BackupLogPricelistFilter />}
	>
		<ScrollingWrapper>
			<Datagrid {...DatagridWithBlockProps} rowClick='show'>
				<TextField source='id' />
				<TextField source='version' />
				<DateTimeFormatField source='created_at' />

				<ReferenceField source='created_by' reference='users' link={false}>
					<TextField source='first_name' />
				</ReferenceField>

				<ReferenceField source='terminal_id' reference='terminal' link={false}>
					<TextField source='name' />
				</ReferenceField>

				<TextField source='file_path' />
			</Datagrid>
		</ScrollingWrapper>
	</List>
);
