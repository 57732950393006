import React from 'react';

import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'ra-ui-materialui/lib/input/AutocompleteInput';


const defaultSort = {
    field: "id",
    order: "ASC"
};


export const TimeZoneSelect = ({ size, source, ...props }) => {
    return (
        <ReferenceInput 
            {...props} 
            sort={defaultSort} 
            source={source} 
            reference="time-zones" 
            filter={{ no_range: true, ...props.filter }}
        >
            <AutocompleteInput 
                optionText="zone"
                optionValue="zone"
                resettable 
                options={{ InputProps: { size } }} 
            />
        </ReferenceInput>
    );
};
