import React, { createContext, useContext, useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

import { useLocalStorage } from '../api/useLocalStorage';

const socketURL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_URL : undefined;
const SocketContext = createContext();


const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState(null);

    const { is_logged_in = null, permissions = null } = useLocalStorage();

    useEffect(() => {
        if (is_logged_in) {
            const socketIo = io(socketURL, {
				withCredentials: true,
			});
            socketIo.on('connect_error', (err) => setError(err));

            setSocket(socketIo);
            return () => {
                socketIo.disconnect();
                socketIo.off('connect_error');
                setSocket(null);
            };
        }
    }, [is_logged_in]);

    useEffect(() => {
        if (socket?.connected && permissions) {
            socket?.emit('user_setup', permissions);
        };
    }, [socket?.connected, permissions]);
  
    const joinRoom = useCallback((room) => {
      if (socket) {
        socket.emit('join_room', room);
      }
    }, [socket]);

    const contextValue = { socket, error, joinRoom };

    return (
        <SocketContext.Provider value={contextValue}>
            {children}
        </SocketContext.Provider>
    );
};

const useSocketContext = () => {
    const context = useContext(SocketContext);
  
    if (!context) {
        throw new Error('useSocket must be used within an SocketProvider');
    }
  
    return context;
};

export { SocketProvider, useSocketContext };
