import ViewListIcon from '@material-ui/icons/ViewList';

import { OrderCreate } from './Create/Create';
import { OrderShow, OrderShowTabsWrapper } from './Show/Show';
import { OrderEditByManager } from './Edit/Edit';
import { OrdersList } from './List/List';

export * from './Create/Create';
export * from './Edit/Edit';
export * from './Show/Show';


export const ordersResource = {
	list: OrdersList,
	create: OrderCreate,
	edit: OrderEditByManager,
	show: OrderShowTabsWrapper,
	icon: ViewListIcon,
}
