import React from 'react';

import { toDate } from '../../_helpers/dateTime';
import { httpClient } from '../../../api/httpClient';
import { AutoTable } from './AutoTable';


export const fetchDataForExport = (httpClient, endpoint, filter, type = "json", order = [ 'id', 'DESC' ], range=[0,19]) => {
	const _order = JSON.stringify(order);
	const _filter = JSON.stringify(filter);
	const _range = JSON.stringify(range);
	return httpClient(`/${endpoint}-export.${type}?filter=${_filter}&sort=${_order}&range=${_range}`);
};

// Should be removed after merge
export const fetchDataForExport2 = (httpClient, endpoint, filter, type = "json", order = [ 'id', 'DESC' ], range=[0,19]) => {
	const _order = JSON.stringify(order);
	const _filter = JSON.stringify(filter);
	const _range = JSON.stringify(range);
	return httpClient(`/${endpoint}-export.${type}?filter=${_filter}&sort=${_order}&range=${_range}`);
}

const rowsPerPageOptions = [200, 500, 1000];

export const DataExportTable = React.forwardRef(({ filters, setExportDate, endpoint, sort}, tableRef) => {
	const [loading, setLoading] = React.useState(true);
    const [orders, setOrders] = React.useState([]);
    const [totalPages, setTotalPages] = React.useState(1);
	const [page, setPage] = React.useState(0); 
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[0]); 
	// const itemsPerPage = 20;
	React.useEffect(() => {
        setLoading(true);
        fetchDataForExport(httpClient, endpoint, filters, "json", sort, [page * rowsPerPage, page * rowsPerPage + rowsPerPage - 1])
            .then(res => {
                const exportDateRaw = res.headers.get('x-date');
                const exportDate = toDate(exportDateRaw);
                if (setExportDate) setExportDate(exportDate);
                
                const [start, end, total] = res.headers.get('Content-Range').split(/[\/-]/).map(Number);
                setTotalPages(total);

                setOrders(res.json);
                setLoading(false);
            })
            .catch(err => {
				console.error(err);				
			})
			.finally(() => {
				setLoading(false);
			});
    }, [filters, page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<AutoTable
            ref={tableRef}				
			loading={loading}
            header={orders && orders.header}
            rows={orders && orders.rows}
            totalCount={totalPages}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
			rowsPerPage={rowsPerPage}
            setPage={setPage}				
            setRowsPerPage={setRowsPerPage}
        />
	);
});