export const TaskService = {
	maintenance: 1,
	cleaning: 2,
	outside: 3,
	deviation: 4,
	customerRelated: 5,
	storage: 6,
	routines: 7,
	other: 8,
};


export const TaskServiceNames = {
	[TaskService.maintenance]: "Maintenance",
	[TaskService.cleaning]: "Cleaning",
	[TaskService.outside]: "Outside",
	[TaskService.deviation]: "Deviation",
	[TaskService.customerRelated]: "Customer related",
	[TaskService.storage]: "Storage",
	[TaskService.routines]: "Routines",
	[TaskService.other]: "Other",
};


export const TaskServices = {
	[TaskService.maintenance]: {
		full_name: "Maintenance",
		short_name: "MNT",
	},
	[TaskService.cleaning]: {
		full_name: "Cleaning",
		short_name: "CLN",
	},
	[TaskService.outside]: {
		full_name: "Outside",
		short_name: "OUT",
	},
	[TaskService.deviation]: {
		full_name: "Deviation",
		short_name: "DVT",
	},
	[TaskService.customerRelated]: {
		full_name: "Customer related",
		short_name: "CSREL",
	},
	[TaskService.storage]: {
		full_name: "Storage",
		short_name: "STR",
	},
	[TaskService.routines]: {
		full_name: "Routines",
		short_name: "RTN",
	},
	[TaskService.other]: {
		full_name: "Other",
		short_name: "OTH",
	},
};
