import React from 'react';
import {
	CreateButton,
	useListContext,
	sanitizeListRestProps,
	TopToolbar,
	ExportButton,
	usePermissions
} from 'react-admin';
import { DataExport } from '../../../components/dialogs/DataExportDialog';
import { AppRoles } from '../../../entities';
import { isInArray } from '../../../utils/general';

export const OsfcUsersActions = (props) => {
    const { className, exporter, filters, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
	const { permissions } = usePermissions();
	const isAccessToExport = permissions && (isInArray(AppRoles, permissions.role));
    const data = "osfc-users"
    const sort = [currentSort.field, currentSort.order]
    return React.useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filters &&
                    React.cloneElement(filters, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })}
                {(exporter !== false && isAccessToExport) && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                    />
                )}
				{/* {isAccessToExport && (<OrdersExportModal title="Export" filters={filterValues}/>)}
                {isAccessToExport && (<OrdersExportModal title="Export Search" />)} */}
                {/* {isAccessToExport && (<OrdersExportWithFilters filters={filterValues}/>)} */}
                {isAccessToExport && (<DataExport userId={permissions.id} filters={filterValues} sort={sort} data={data}/>)}
                {hasCreate && <CreateButton basePath={basePath} />}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    );
}
