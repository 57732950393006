import React from 'react';
import { FlagIcon } from './FlagIcon';

export const FlagDA = props => (
    <FlagIcon {...props} viewBox="0 0 512 512">
        <rect width="512" height="512" fill="#C60B1E" />
        <rect width="85.33" height="512" x="170.67" fill="#FFFFFF" />
        <rect width="512" height="85.33" y="213.33" fill="#FFFFFF" />
    </FlagIcon>
);
