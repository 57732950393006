import { isArray, isObject } from "lodash";

export const bitwiseNumber2Arr = (val, arrEnum) => {
    if (!val) return [0];
	const arr = arrEnum.filter(x => val & x);
	return arr;
};

export const arr2bitwiseNumber = (arr) => {
    if (arr[arr.length - 1] === 0) return 0;
    if (arr[0] === 0) arr = arr.slice(1);

	const val = arr.reduce((res, curr) => res | curr, 0)	
	return val;
};

export const dict2dict = (dic, keysMap, funcMap = {}, mapWithEl = false) => {
    const result = {}
	if (isArray(keysMap)) {
		for (const key of keysMap) {
			if (dic.hasOwnProperty(key) || (mapWithEl && funcMap.hasOwnProperty(key)))
				result[key] = dic[key]
			else if (mapWithEl && funcMap.hasOwnProperty(key))
				result[key] = funcMap[key](mapWithEl ? dic : dic[key])
		}
	} else if (isObject(keysMap)) {
		for (const [kTo, kFrom] of Object.entries(keysMap)) {
			if (dic.hasOwnProperty(kFrom)) {
				if (funcMap.hasOwnProperty(kTo))
					result[kTo] = funcMap[kTo](mapWithEl ? dic : dic[kFrom])
				else
					result[kTo] = dic[kFrom]
			}
		}

	}
	return result;
};