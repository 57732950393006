import ViewListIcon from '@material-ui/icons/ViewList';

import RecurringTasks from '../recurring_tasks/List/List';
import { CreateRecurringTask } from './Create/Create';
import { EditRecurringTask } from './Edit/Edit';


export const recurringTaskResource = {
    list: RecurringTasks,
    create: CreateRecurringTask,
    edit: EditRecurringTask,
    icon: ViewListIcon,
};
