import React from 'react';
import { Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { ordersResource, OrderEditByCustomer, OrderCreateByCustomer } from '../../resources/orders/';

import { customersEmployeesResource } from '../../resources/customer-employees/';
import { customerUsers } from '../../resources/customer-users';
import { customerDriversResource } from '../../resources/drivers/';
import { pricelistArticlesResource } from '../../resources/customer-pricelist-articles';
import { pricelistHolidaysResource } from '../../resources/pricelist-holidays/';
import { areasResource } from '../../resources/ports-areas';

import Messages from '../../resources/messages/List/List';
import { CompanySettingsPage } from '../../pages/settings/';
import { apiKeysRouts } from '../../pages/settings/APIKeys';

export const customerManagerResources = [
	<Resource {...ordersResource} edit={OrderEditByCustomer} create={OrderCreateByCustomer} options={{label: "Orders"}} name="orders" />,
	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...customerUsers} name="customer-users" options={{ label: 'Employees' }} />,
	// <Resource {...transportersResource} create={null} edit={null} name="transporters" />,
	// <Resource {...customerDriversResource} name="drivers" />,
	<Resource {...pricelistArticlesResource} name="customer-pricelist-articles" options={{hasUpload: false}} />,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource name="ports" intent="registration" />,
	<Resource name="areas" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="transporters" intent="registration" />,
	<Resource name="drivers" intent="registration" />,
	<Resource name="trucks" intent="registration" />,
	<Resource name="trailers" intent="registration" />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="terminal" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
];


export const customerManagerRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/notifications" component={CompanySettingsPage} />,
];
