import React from 'react';
import { SelectInput } from 'react-admin';

import { TaskService, TaskServiceNames } from '../../../../entities/TaskService';


export const serviceChoices = [
	{id: TaskService.reloadCarCar, name: TaskServiceNames[TaskService.reloadCarCar]},
	{id: TaskService.reloadCarTerminalCar, name: TaskServiceNames[TaskService.reloadCarTerminalCar]},
	{id: TaskService.intoPlukkStorage, name: TaskServiceNames[TaskService.intoPlukkStorage]},
	{id: TaskService.maintenance, name: TaskServiceNames[TaskService.maintenance]},
	{id: TaskService.cleaning, name: TaskServiceNames[TaskService.cleaning]},
	{id: TaskService.outside, name: TaskServiceNames[TaskService.outside]},
	{id: TaskService.deviation, name: TaskServiceNames[TaskService.deviation]},
	{id: TaskService.customerRelated, name: TaskServiceNames[TaskService.customerRelated]},
	{id: TaskService.storage, name: TaskServiceNames[TaskService.storage]},
	{id: TaskService.routines, name: TaskServiceNames[TaskService.routines]},
	{id: TaskService.other, name: TaskServiceNames[TaskService.other]},
];


export const ServiceInput = props => {
	return (
		<SelectInput {...props} choices={serviceChoices} />
	);
};
