import React, { useState } from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LanguageSelect from "../../../components/inputs/LanguageSelect";
import { SaveWithCancelToolbar } from "../../../components/toolbars/SaveWithCancelToolbar";
import { GridForm, GridInput, FormGroupGrid } from "../../../components/GridForm";
import makeStyles from '@material-ui/core/styles/makeStyles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { httpClient } from "../../../api/httpClient";
import { useNotifyError } from "../../../utils/notifiers/useNotifyError";

const useDocumentItemStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	rootGray: {
		width: '100%',
		opacity: 0.5
	},
	media: {
		height: 240,
		backgroundPosition: 'top',
	},
	contentRoot: {


		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',

		'&:last-child': {
			paddingBottom: theme.spacing(1),
		}
	},
	title: {
		overflowWrap: 'anywhere',
		textAlign: 'left!important'
	},
}));



const DocumentAddButton = ({ onFileChange }) => {
    const inputRef = React.useRef(null);

    const handleSelectFile = React.useCallback(() => inputRef.current.click(), []);

    const handleFileChange = (e) => {
        if (e.target.files) {
            onFileChange(e.target.files[0]);
            console.log(e.target.files);
        }
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleSelectFile}
            >
                Add Document
            </Button>
            <input
                ref={inputRef}
                onChange={handleFileChange}
                type="file"
                style={{ display: "none" }}
                accept=".png,.jpg,.jpeg,.pdf,.gif,.docx,.doc,.xlsx,.xls"
            />
        </React.Fragment>
    );
};


const FileInfo = ({ file, setFile }) => {
    const classes = useDocumentItemStyles();
    const title = file.name;
    return(
        <Card className={classes.root} elevation={0} variant="outlined" >
			<CardContent className={classes.contentRoot}>
				<Typography className={classes.title} variant="body2" color="textSecondary" component="p">
					<Typography className={classes.title} variant="body2" color="textPrimary" component="span">
					</Typography>
					<br />
					<br />
					{title} 
                    <IconButton  
						style={{marginBottom:"2px"}}
						onClick={()=>setFile(null)}>
                        <DeleteIcon fontSize="inherit" style={{color:"#ab1f15"}}/>
                    </IconButton >
					<br />					
				</Typography>
			</CardContent>
		</Card>
    )
}

export const UserManualCreate = (props) => {
    const [file, setFile] = useState(null);
    const history = useHistory();
    const notifyError = useNotifyError();	

    const onSubmit = async (values) => {
        const data = new FormData();
        if (file) {
            data.append('document', file);
        }
        Object.keys(values).forEach(key => {
            data.append(key, values[key]);
        });

        await httpClient(`user-manuals`, {
			method: 'POST',
            body: data,
		}).then((response) => {
			history.push('/user-manuals');
		}).catch(error => {;
            notifyError(error);
        });
    };

    return (
        <Create {...props}>
            <SimpleForm 
                submitOnEnter={false} 
                toolbar={<SaveWithCancelToolbar />} 
                redirect={false}
                save={onSubmit} 
            >
                <GridForm>
                    <FormGroupGrid>
                        <GridInput xs={6} component={LanguageSelect} source="locale" default="en" validate={required()} />
                        <GridInput xs={6} component={TextInput} source="page_url" validate={required()} />
                    </FormGroupGrid>
                    <FormGroupGrid>
                        {file==null && <GridInput xs={6} component={DocumentAddButton} onFileChange={setFile} />}
                        {file && <GridInput xs={3} component={FileInfo} file={file} setFile={setFile} />}
                    </FormGroupGrid>
                </GridForm>
            </SimpleForm>
        </Create>
    );
};
