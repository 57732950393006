import React from 'react';

import { Show, TextField, SimpleShowLayout } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';


export const ArticleShow = (props) => {
	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source="code" />
				<TextField source="name" />
				<TextField source="color" />
				<TextField source="split" />
				<TextField source="calculation" />
				<TextField source="price_a" />
				<TextField source="price_b" />
				<TextField source="price_c" />
				<TextField source="price_d" />
				<TextField source="service_description" />
			</SimpleShowLayout>
		</Show>
	);
};
