import React from "react";

import { maxLength, NumberInput, ReferenceInput, SelectInput, TextInput, usePermissions } from 'react-admin';
import { useForm } from 'react-final-form';

import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm';

import { DateInput, TimeInput } from '../../../components/inputs';
import { YesNoInput } from '../../../components/inputs/YesNoInput';
import { OSFCUserSelect } from '../../../components/inputs/OSFCUserSelect';

import { required } from '../../../utils/validation/required';
import { TaskService } from "../../../entities/TaskService";
import { EstimateDurationInput } from './inputs/EstimateDurationInput';
import { ServiceInput } from './inputs/ServiceInput';
import { UserRole } from "../../../entities";


const TaskForm = ({ isEditForm = false, ...props }) => {
    const { permissions } = usePermissions();
    const form = useForm();

    const handle_eta_change = (event) => {
        if (event) {
            const eventDate = new Date(event);
            const year = eventDate.getFullYear();
            const month = String(eventDate.getMonth() + 1).padStart(2, '0');
            const day = String(eventDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            form.change("etd_date", formattedDate)
        }
    };

    const handle_etd_change = (event) => {
        if (event) {
            const eventDate = new Date(event);
            const year = eventDate.getFullYear();
            const month = String(eventDate.getMonth() + 1).padStart(2, '0');
            const day = String(eventDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            form.change("eta_date", formattedDate)
        }
    };

    const isDisabledForOSFCEmployee = isEditForm && 
        permissions && permissions.role === UserRole.osfc_employee;

    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput 
                    sm={6} md={3} lg={3} 
                    component={TextInput} 
                    source="title"
                    validate={[required()]} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    source="service" 
                    sm={2} 
                    component={ServiceInput} 
                    validate={[required()]} 
                    defaultValue={TaskService.other} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    sm={6} md={3} 
                    source="status" 
                    reference="task-statuses"
                >
                    <ReferenceInput validate={[required()]}>
                        <SelectInput optionText="name" /> 
                    </ReferenceInput>
                </GridInput>

                <GridInput 
                    sm={6} md={2} 
                    component={YesNoInput} 
                    source="priority" 
                    defaultValue={false} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    sm={6} md={2} 
                    validate={[required()]}
                    disabled={isDisabledForOSFCEmployee}
                >
                    <ReferenceInput 
                        source="terminal_id" 
                        reference="terminal" 
                        validate={[required()]} 
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>
            </FormGroupGrid>

            <FormGroupGrid 
                xs={12}
                style={{ marginBottom: 20 }}
            >
                {/* <GridInput 
                    xs={6} sm={6} md={3} 
                    component={NumberInput} 
                    source="rank" 
                    defaultValue={0} 
                    disabled={isDisabledForOSFCEmployee}
                /> */}

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={EstimateDurationInput} 
                    source="estimate_duration" 
                    validate={[required()]} 
                    disabled={isDisabledForOSFCEmployee}
                    defaultValue={5} 
                    min={5}
                />

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={DateInput} 
                    source='deadline_date'
                    validate={[required()]} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={TimeInput} 
                    source='deadline_time'
                    validate={[required()]} 
                    disabled={isDisabledForOSFCEmployee}
                />
            </FormGroupGrid>

            <FormGroupGrid 
                xs={12}
                style={{ marginBottom: 20 }}
            >
                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={DateInput} 
                    source='eta_date'
                    onChange={handle_eta_change} 
                />

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={TimeInput} 
                    source='eta_time'
                />

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={DateInput} 
                    source='etd_date'
                    onChange={handle_etd_change} 
                />

                <GridInput 
                    xs={6} sm={6} md={3} 
                    component={TimeInput} 
                    source='etd_time' 
                />
            </FormGroupGrid>

            <FormGroupGrid xs={12}>
                <GridInput 
                    source="assignee_1" 
                    sm={6} md={6} lg={6} 
                    component={OSFCUserSelect} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    source="assignee_2" 
                    sm={6} md={6} lg={6} 
                    component={OSFCUserSelect} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    sm={12} md={6} 
                    component={TextInput} 
                    source="description" 
                    multiline 
                    rows={6} 
                    validate={[maxLength(1024)]} 
                    disabled={isDisabledForOSFCEmployee}
                />

                <GridInput 
                    sm={12} md={6} 
                    component={TextInput} 
                    source="notes" 
                    multiline 
                    rows={6} 
                    validate={[maxLength(1024)]} 
                />
            </FormGroupGrid>
        </GridForm>
    );
};


export default TaskForm;
