import React from 'react';

import { Show, TextField, EmailField, SimpleShowLayout, ReferenceManyField } from 'react-admin';

import { PhoneField } from '../../../components/fields/PhoneField';
import { PriceCategoryField } from '../../../components/fields/PriceCategoryField';
import { YesNoField } from '../../../components/fields/YesNoField';
import { EmployeesDatagrid } from './EmployeesDatagrid';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { ScrollingWrapperInCard } from '../../../components/ScrollingWrapper';


export const CompanyShow = (props) => {
	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source="id" disabled />
				<TextField source="full_name" autoFocus />
				<TextField source="short_name" />
				<TextField source="accountancy_number" />
				<PhoneField source="phone" />
				<EmailField source="email" />
				<EmailField source="notification_email" />
				<PriceCategoryField source="price_category" />
				<YesNoField source="set_order_completed" />
				<YesNoField source="is_also_transporter" />
				<EmailField source="transporter_notification_email" />
				<ScrollingWrapperInCard>
					<ReferenceManyField label="Employees" reference="customer-users" target="company_id">
						<EmployeesDatagrid id={props.id} />
					</ReferenceManyField>
				</ScrollingWrapperInCard>
			</SimpleShowLayout>
		</Show>
	);
};
