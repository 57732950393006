import React from 'react';
import { Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import {ordersResource, OrderEditByTransporter, OrderShowTabsWrapper} from '../../resources/orders/';

import { customerDriversResource } from '../../resources/drivers/';
import { transportTrucksResource } from '../../resources/trucks';
import { transportTrailersResource } from '../../resources/trailers';
// import {customersEmployeesResource} from "../../resources/customer-employees";
import { customerUsers } from '../../resources/customer-users';

import Messages from '../../resources/messages/List/List';
import { CompanySettingsPage } from '../../pages/settings/';

export const transporterAdminResources = [
	<Resource name="orders" list={ordersResource.list} edit={OrderEditByTransporter} show={OrderShowTabsWrapper} />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	// <Resource {...customersEmployeesResource} name="customer-employees" />,
	<Resource {...customerUsers} name="customer-users" options={{ label: 'Employees' }} />,
	<Resource name="ports" intent="registration" />,
	<Resource name="users" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist" intent="registration" />,
	<Resource name="drivers" {...customerDriversResource} />,
	<Resource name="customer-companies" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
];

export const transporterAdminRoutes = [
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/notifications" component={CompanySettingsPage} />,
];
