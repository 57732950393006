import React from "react";
import { List, Datagrid, TextField, EditButton, ReferenceField, usePermissions} from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { PhoneField } from '../../../components/fields/PhoneField';
import { LinkField } from '../../../components/fields/LinkField';
import { YesNoField } from '../../../components/fields/YesNoField';
import { StyledEmailField } from '../../../components/fields/StyledEmailField';
import { CustomersFilter } from './ListFilters';
import {HideBlockUnblockButtonIfDeleted} from '../../../components/toolbars/BlockUnblockButton';
import DeleteRestoreButtons from '../../../components/toolbars/DeleteRestoreButtons';
import {DatagridWithBlockDeleteProps} from "../../../components/_helpers/props/DatagridProps";
import {stylesForColorInheritInList,
} from "../../../components/_helpers/useStyles";
import {AppManagerRoles, UserRole} from "../../../entities";
import { CustomerEmployeesActions } from "./Actions";
import { UserTypeField } from "../../../components/fields/UserTypeField";
import { isInArray } from "../../../utils/general";

const defaultSort = { field: 'id', order: 'DESC' };


export const CustomersList = props => {
	const classes = stylesForColorInheritInList()

	const { permissions } = usePermissions();
	
	return ( !!permissions &&
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			filters={<CustomersFilter />}
			perPage={22}
			classes={classes}
			actions={<CustomerEmployeesActions/>}

		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockDeleteProps}>
					<TextField source="id" />
					{isInArray(AppManagerRoles, permissions.role)
						&&
						<ReferenceField reference="companies" source="company_id" link={false}>
							<TextField source="full_name" />
						</ReferenceField>
					}
					<LinkField source="first_name" />
					<LinkField source="last_name" />
					<StyledEmailField source="email" />
					<PhoneField source="phone" />
					<UserTypeField source="type" sortable={false} />
					<YesNoField source="access_to_export" />
					<EditButton label="" />
					<HideBlockUnblockButtonIfDeleted entity={"user"}/>
					{(permissions.role === UserRole.admin) &&
						<DeleteRestoreButtons resourceForUpdate={"user-is-deleted"}/>}
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
