import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { Button, SaveButton, FormWithRedirect, useMutation, useNotify, useGetOne, useSetLocale, Confirm,
		useLogout } from 'react-admin';
import { PassProps } from '../../components/_helpers/PassProps';
import { setPermissions } from '../../api/authProvider';

export const Progress = () => <Box display="flex" flex={1} alignItems="center" justifyContent="center" margin={5}><CircularProgress /></Box>;


export const UpdateForm = ({ id, children, resource, render, ...props }) => {
	const notify = useNotify();
	const { data, loading: initialLoading } = useGetOne(resource, id);
	const setLocale = useSetLocale()
	const [showReloginModal, setShowReloginModal] = React.useState(false);
	const logout = useLogout();

	const [mutate, { loading }] = useMutation({
		type: 'update',
		resource: resource,
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: (response) => {
			notify("Updated successfully", "success");
			props.onSuccess && props.onSuccess();
			if (resource === "settings"){
				if (props.permissions?.time_refresh_order !== response.data.time_refresh_order){
					setPermissions({...props.permissions, time_refresh_order: response.data.time_refresh_order})
				}
				if (props.permissions?.locale !== response.data.locale){
					setShowReloginModal(true);
				}
			}
		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
	});

	const handleSubmit = async values => {
		mutate({
			payload: {
				id: id,
				data: values,
			}
		});
	};

    const handleRelogin = () => {
        logout();
    };

    const handleCloseModal = () => {
        setShowReloginModal(false);
    };

	const renderFunction = typeof render === "function" ? render : undefined;

	return (
		<React.Fragment>
		<FormWithRedirect
			initialValues={data}
			submitOnEnter={false}
			save={handleSubmit}
			render={({ handleSubmitWithRedirect, pristine, saving }) => {
				const body = (initialLoading ? <Progress /> :
					<PassProps childPropKeys={["resource"]} resource={resource}>
						{children}
					</PassProps>
				);

				const buttons = (
					<React.Fragment>
						{props.onCancel && <Button label="ra.action.cancel" onClick={props.onCancel} disabled={loading} color="secondary" />}
						<SaveButton
							handleSubmitWithRedirect={handleSubmitWithRedirect}
							pristine={pristine}
							saving={saving}
							disabled={loading}
						/>
					</React.Fragment>
				);

				if (renderFunction) {
					return renderFunction({
						body: body,
						buttons: buttons,
					});
				}

				return (
					<React.Fragment>
						{body}
						{buttons}
					</React.Fragment>
				)
			}}
		/>
		  <Confirm
				isOpen={showReloginModal}
				content="osfc.dialogs.language_change.message"
				title="osfc.dialogs.language_change.title"
                confirm="Logout"
                cancel="Cancel"
				onConfirm={handleRelogin}
				onClose={handleCloseModal}
			/>
		</React.Fragment>
	);
}
