import {
    minValue,
    number,
    email,
} from 'react-admin';


export const validateEmail = [email()];

export const validateNumberPositive = [number(), minValue(0)];

export const confirmPasswordValidator = (value, values) => {
    if (value !== values["password"]) {
        return "osfc.messages.validation.confirmPasswordInvalid";
    }
};

export const validateUnique = (field, msg = "osfc.messages.validation.unique") => (val, vals, params) => {
    const data = vals.data; 
	if (data) {
		const arr = data.filter(x => x && x[field] && x[field] === val);
		if (arr.length > 1) return msg;
	}		
};

export const validateInteger = (value) => {
    if (value && !Number.isInteger(value)) {
        return 'osfc.messages.validation.integer';
    }
};
