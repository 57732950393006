import React from 'react';
import { FlagIcon } from './FlagIcon';

export const FlagFR = props => (
    <FlagIcon {...props} viewBox="0 0 512 512">
        <rect width="170.67" height="512" fill="#0052B4" />
        <rect width="170.67" height="512" x="170.67" fill="#F0F0F0" />
        <rect width="170.67" height="512" x="341.33" fill="#D80027" />
    </FlagIcon>
);
