import React, { useEffect, useState } from 'react';
import { MenuItemLink, useDataProvider } from 'react-admin';

import TimeZoneIcon from '@material-ui/icons/Timelapse';
import UserIcon from '@material-ui/icons/AccountCircle';
import TerminalIcon from '@material-ui/icons/Storage';

import { SubMenu } from './SubMenu';
import { getDateTimeForTimeZone } from '../components/_helpers/dateTime';
import { useClock } from '../utils/hooks/useClock';


const EntityMenuItemText = ({ entity, timeZone: _timeZone }) => {
    const [ timeZone, dateTime ] = useClock(getDateTimeForTimeZone, _timeZone);

    return (
        <span>
            {entity} ({timeZone}) <br /> {dateTime}
        </span>
    );
};

const UserLocalMenuItemLink = ({ dense, open }) => (
    <MenuItemLink
        key='user-local'
        component='p'
        primaryText={<EntityMenuItemText entity="User" />}
        leftIcon={<UserIcon />}
        dense={dense}
        sidebarIsOpen={open}
    />
);

const TerminalMenuItemLink = ({ dense, open }) => {
	const dataProvider = useDataProvider();
    const [ terminals, setTerminals ] = useState([]);

    useEffect(() => {
        dataProvider.getList("terminal", {
            pagination: {page: 0, perPage: 5},
            sort: {field: 'name', order: 'ASC'},
            filter: {},
        }).then((data) => {
            if (data?.data) {
                setTerminals(data.data);
            };
        });
	}, [ dataProvider ]);

    if (!terminals.length) return null;

    return (
        terminals.map(({ name, time_zone }) => (
            <MenuItemLink
                component='p'
                key={`terminal-${name}`}
                primaryText={(
                    <EntityMenuItemText 
                        entity="Terminal" 
                        timeZone={time_zone} 
                    />
                )}
                leftIcon={<TerminalIcon />}
                dense={dense}
                sidebarIsOpen={open}
            />
            
        ))
    );
};

export const SiteTimeZonesDropDown = () => (
    <SubMenu 
        primaryText="Time Zones Datetimes"
        leftIcon={<TimeZoneIcon />}
        >
            <TerminalMenuItemLink />
            <UserLocalMenuItemLink />
    </SubMenu>
);