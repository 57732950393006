import React, { useState } from 'react';

import {
	TextInput, ReferenceInput, SelectInput, 
	BooleanInput, required,
	maxLength
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';


import { GridForm, GridInput, FormGroupGrid, } from '../../../components/GridForm';
import { OSFCUserSelect } from '../../../components/inputs/OSFCUserSelect';

import { DateInput, TimeInput } from '../../../components/inputs';
import { ServiceInput } from '../../tasks/forms/inputs/ServiceInput';

import { TaskService } from '../../../entities/TaskService';
import { RecurringTaskRecurringOptionsType } from '../../../entities/RecurringTaskRecurringOptionsType';
import { addTimeToDate } from '../utils/timeOperations';
import { EndTimeSettings, RepeatOptions, RepeatType } from '../RecurringOptions'; 



const requiredValidator = [required()];

// TODO: investigate what can be improved
// try to use react-admin input component
export const RecurringTaskForm = props => {
	const form = useForm();
	const { values } = useFormState();

	React.useEffect(() => {
		if(!values.repeat_every){
			form.change('repeat_every', 1);
		}
		if(values.end_option === RecurringTaskRecurringOptionsType.after){
			form.change('end_date', addTimeToDate(values.start_date, values.repeat_times || 1, values.repeat_every || 1, values.type));
		}
		
	}, [values]);

	
	const onRepeatTypeChange = (event) => {
		if(values.end_option === RecurringTaskRecurringOptionsType.after){
			form.change('end_date', addTimeToDate(values.start_date, values.repeat_times, values.repeat_every, event.target.value))
		} 
		else if(values.end_option === RecurringTaskRecurringOptionsType.on){
			form.change('repeat_times', null);
		}
	};

	return (
		<GridForm {...props}>
			<FormGroupGrid>
				<GridInput 
					xs={12} 
					sm={3} 
					component={TextInput} 
					source='title' 
				/>

				<GridInput 
					source='service' 
					xs={12} 
					sm={3} 
					component={ServiceInput} 
					validate={requiredValidator} 
					defaultValue={TaskService.other} 
				/>

				<GridInput xs={12} sm={3}>
					<ReferenceInput 
						source='terminal_id' 
						reference='terminal' 
						validate={requiredValidator}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				</GridInput>

				<GridInput 
					xs={12} 
					sm={1} 
					component={BooleanInput} 
					source='priority' 
				/>
			</FormGroupGrid>

			<FormGroupGrid>
					<GridInput 
						xs={12} 
						sm={3} 
						component={DateInput} 
						source='start_date' 
						validate={requiredValidator} 
					/>
					<GridInput 
							xs={12} 
							sm={9} 
							md={6}
							lg={3}
							component={RepeatType} 
							values={values}
							form={form}
							onChange={onRepeatTypeChange}
						/>

					<RepeatOptions form={form} values={values}/>           
			</FormGroupGrid>

			<FormGroupGrid>
				<EndTimeSettings form={form} values={values} xs={12} md={12}/>
			</FormGroupGrid>

			<FormGroupGrid style={{marginBottom: "15px"}}>
				<GridInput 
					xs={12} 
					sm={3} 
					component={TimeInput} 
					source='start_time' 
					validate={requiredValidator} 
				/>
				<GridInput 
					xs={12} 
					sm={3} 
					component={TimeInput} 
					source='end_time' 
					validate={requiredValidator} 
				/>
				 
			</FormGroupGrid>
			<FormGroupGrid>
				<GridInput 
						source='assignee_1' 
						xs={12} 
						sm={6} 
						component={OSFCUserSelect} 
				/>
				<GridInput
					source='assignee_2' 
					xs={12} 
					sm={6} 
					component={OSFCUserSelect} 
				/>
			</FormGroupGrid>

			<FormGroupGrid>
				<GridInput 
					sm={12} md={12} 
					component={TextInput} 
					source='description' 
					multiline 
					rows={6} 
					validate={[maxLength(1024)]} 
				/>
			</FormGroupGrid>
		</GridForm>
	);
};
