import React from "react";
import { List, Datagrid, TextField, EditButton, DeleteButton, Pagination } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';

import { ArticleTemplatesFilter } from './ListFilters';


const defaultSort = { field: 'template', order: 'DESC' };

const ArticleTemplatesPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

export const ArticleTemplatesList = props => {
	return (
		<List
			{...props}
			sort={defaultSort}
			exporter={false}
			bulkActionButtons={false}
			pagination={<ArticleTemplatesPagination/>}
			perPage={50}
			// filters={<ArticleTemplatesFilter />}
		>
			<ScrollingWrapper>
				<Datagrid {...DatagridWithBlockProps} >
					<TextField source="template" sortable={true} />
					<TextField source="load_time" />
					<TextField source="load_time_kilo" />
					<TextField source="load_time_pallet" />
					<EditButton label="" />
					<DeleteButton label="" />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}
