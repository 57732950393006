import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

import { TextReadonlyReferenceInput } from './TextReadonlyInput';


const defaultSort = {
	field: "id",
	order: "ASC"
};


const optionText = r => r ? `${r.first_name} ${r.last_name}` : null;


export const OSFCUserText = (props) => {
	return (
		<ReferenceInput sort={defaultSort} reference="osfc-users" {...props} filter={{ id: [props.record[props.source]] }}>
			<TextReadonlyReferenceInput optionText={optionText} />
		</ReferenceInput>
	);
};


export const OSFCUserSelect = ({ size, ...props }) => {
	return (
		<ReferenceInput sort={defaultSort} reference="osfc-users" {...props} filter={{ no_range: true, is_terminal: true, ...props.filter }}>
			<AutocompleteInput optionText={optionText} resettable options={{ InputProps: { size } }} />
		</ReferenceInput>
	);
};
