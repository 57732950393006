import React from "react";
import { Filter } from 'react-admin';

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';

import { ProcessStatus, ParsedStatus } from "../../../entities"


export const EntityDocumentStatusFilter = (props) => (
    <Filter {...props}>
        <TextInputEx source="entity_id" key="entity_id" resettable />
        <SelectEnumInput 
            source="parsed_status" key="parsed_status"
            resettable enumName="parsedstatus" enumObj={ParsedStatus} 
            style={{ minWidth: "240px" }} 
        />
        <SelectEnumInput 
            source="process_status" key="process_status" 
            resettable enumName="processstatus" enumObj={ProcessStatus} 
            style={{ minWidth: "240px" }} 
        />
    </Filter>
);
