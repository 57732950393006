import React from 'react';
import { 
	TextField, ReferenceField, Tab,
	FunctionField, SelectInput, 
} from 'react-admin';

import { DateFormatField } from '../../../components/fields/DateFormatField';
import { FlexLayout, FlexRow } from '../../../components/FlexShowLayout';
import { PriorityField } from '../../../components/fields/PriorityField';
import { HistoryField } from '../../../components/HistoryGrid';
import { ReferenceEditableField } from '../../../components/fields/EditableField';

import { EntityHistoryGrid } from './HistoryGrid';
import { UserRole } from '../../../entities';

import { DocumentsFieldBlock } from '../../../components/fields/DocumentsField';

export const GeneralTab = (props) => (
	<Tab label="General" {...props}>
		<FlexLayout>
			<FlexRow>
				<TextField source="id" />

				<PriorityField source="priority" />
				<TextField source="notes" />

				<ReferenceField 
					source="terminal_id" 
					reference="terminal" 
					link={false}
				>
					<TextField 
						label="Terminal" 
						source="name"
					/>
				</ReferenceField>
			</FlexRow>

			<FlexRow>
				<DateFormatField source="eta_date"/>
				<TextField source="eta_time"/>
				<DateFormatField source="etd_date"/>
				<TextField source="etd_time"/>
			</FlexRow>
		</FlexLayout>
	</Tab>
);


export const HistoryTab = (props) => (
	<Tab 
		label="Entity History" 
		{...props}
	>
		<ReferenceField 
			label="resources.entity-editlog.created_by" 
			source="created_by" 
			reference="users" 
			link={false}
		>
			<FunctionField render={record => `${record.first_name} ${record.last_name}`} />
		</ReferenceField>

		<HistoryField 
			label="resources.entity-history.name" 
			reference="entity-history" 
			target="original_id"
		>
			<EntityHistoryGrid />
		</HistoryField>
	</Tab>
);


export const DocumentsTab = ({isOSFCManager, isCustomerAsTrans, ...props}) => {
	return (
		<Tab label="Documents" {...props}>
			<FlexLayout>
				<FlexRow>
					<TextField source="id"/>
					<TextField source="reference"/>
				</FlexRow>
			</FlexLayout>
			<DocumentsFieldBlock variant="elevation" isCustomerAsTrans={isCustomerAsTrans} isOutputDocuments={false}/>
		</Tab>
	);
};
