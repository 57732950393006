import React from 'react';
import {Edit, usePermissions} from 'react-admin';

import {useMutateForm} from '../../../api/useMutateForm';

import {DocumentsFieldBlock} from '../../../components/fields/DocumentsField';
import {ActionsWithBackButton} from '../../../components/actionbars/ActionsWithBackButton';
import {SimpleFormAdv} from '../../../components/forms/SimpleFormAdv';

import {FormManager} from '../forms/FormManager';
import {FormCustomer} from '../forms/FormCustomer';
import {FormTransporter} from '../forms/FormTransporter';
import {FormCustomerTransporter} from "../forms/FormCustomerTransporter";

import {OrderStatus} from '../../../entities';

import {
	orderWarningValidations,
} from '../../../components/_helpers/warningsConditions';

export const OrderEditBase = (Form, name, actionsProps) => (props) => {
	const [mutateForm, state] = useMutateForm("update", props.resource, props.basePath, props.id, {}); // eslint-disable-line
	const toolbarCompProps = {
		warningValidations: orderWarningValidations,
	};
	const mutateFormAndEmit = (...args) => {
		const result = mutateForm(...args);
		return result;
	};

	return (
		<React.Fragment>
			<Edit {...props} undoable={false}
				  actions={<ActionsWithBackButton {...actionsProps}
												  cloneMutableValues={
													  {
														  reference: null,
														  origin_id: props.id
													  }}/>}>
				<SimpleFormAdv
					save={mutateFormAndEmit}
					children={<Form/>}
					toolbarCompProps={toolbarCompProps}
				/>
			</Edit>
			<Edit {...props} undoable={false} actions={null}>
				<DocumentsFieldBlock variant="elevation"/>
			</Edit>
		</React.Fragment>
	);
};

export const OrderEditByManager = OrderEditBase(FormManager, "Manager", {
	hasClone: true, 
	hasChange: true
})
export const OrderEditByCustomer = OrderEditBase(FormCustomer, "Customer", {
	hasDelete: (record) => record?.status === OrderStatus.BOOKED,
	hasClone: true
});
export const OrderEditByCustomerTransporter = OrderEditBase(FormCustomerTransporter, "Customer and transporter", {
	ifCustomerTransporterOrderEdit: true,
});
export const OrderEditByTransporter = OrderEditBase(FormTransporter, "Transporter");
