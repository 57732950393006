import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { FilterToggleButtonGroup, StyledToggleButton } from '../../../components/filters/FilterToggleInput';
import { filterColors } from '../../../utils/constants';


export const ToggleDatesLaborForecastEnum = {
	TODAY: "today",
	TOMORROW: "tomorrow",
	DAY_AFTER_TOMORROW: "day_after_tomorrow",
}

const useStyles = makeStyles({
	filterPeriod: {
		backgroundColor: filterColors.period
	},
	filterToday: {
		backgroundColor: filterColors.today
	},
});

export const ToggleDatesFilter = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { input } = useInput(props);
	const value = input.value;


	const handleChangeSingle = (e, newValues) => {
		input.onChange([newValues]);
	}

	return (
		<React.Fragment>
			<FilterToggleButtonGroup value={value} onChange={handleChangeSingle} label={input.label} exclusive className={classes.filterPeriod} >
				<StyledToggleButton value={ToggleDatesLaborForecastEnum.TODAY} className={classes.filterToday}>
					{translate('osfc.filters.dates.today')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesLaborForecastEnum.TOMORROW}>
					{translate('osfc.filters.dates.tomorrow')}
				</StyledToggleButton>
				<StyledToggleButton value={ToggleDatesLaborForecastEnum.DAY_AFTER_TOMORROW}>
					{translate('osfc.filters.dates.day_after_tomorrow')}
				</StyledToggleButton>
			</FilterToggleButtonGroup>
		</React.Fragment>
  );
}
