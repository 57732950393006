import React from 'react';
import {
    useLocale, useSetLocale, usePermissions, useMutation, useNotify, useLogout, Confirm
} from "react-admin";
import {
    makeStyles, Menu, MenuItem, IconButton, ListItemIcon
} from '@material-ui/core';
import { 
    FlagEN, 
    FlagNO, 
    FlagDE, 
    FlagRU, 
    FlagPL, 
    FlagFR, 
    FlagES,
    FlagIT,
    FlagDA,
    FlagNL,
} from '../components/icons/flags'
import { setPermissionsLocale } from '../api/authProvider'
import { Languages } from '../entities/Languages';


const useStyles = makeStyles(theme => ({
	listItemIcon: {
		minWidth: 'auto',
	},
}));

const localeFlagStyle = { width: 16, height: 16 };

const dataLocale = {
    [Languages.en]: {text: 'english', icon: <FlagEN style={localeFlagStyle} />},
    [Languages.no]: {text: 'norwegian', icon: <FlagNO style={localeFlagStyle} />},
    [Languages.de]: {text: 'german', icon: <FlagDE style={localeFlagStyle} />},
    [Languages.ru]: {text: 'russian', icon: <FlagRU style={localeFlagStyle} />},
    [Languages.pl]: {text: 'polish', icon: <FlagPL style={localeFlagStyle} />},
    [Languages.fr]: {text: 'french', icon: <FlagFR style={localeFlagStyle} />},
    [Languages.es]: {text: 'spanish', icon: <FlagES style={localeFlagStyle} />},
    [Languages.it]: {text: 'italian', icon: <FlagIT style={localeFlagStyle} />},
    [Languages.da]: {text: 'danish', icon: <FlagDA style={localeFlagStyle} />},
    [Languages.nl]: {text: 'dutch', icon: <FlagNL style={localeFlagStyle} />},
}

export const LocaleSwitcher = () => {
    const classes = useStyles();
    const notify = useNotify();
    const locale = useLocale();
    const setLocale = useSetLocale();    
    const [anchorEl, setAnchorEl] = React.useState(null);   
    const [showReloginModal, setShowReloginModal] = React.useState(false);
	const logout = useLogout(); 
    const [ mutate ] = useMutation({
		type: 'update',
		resource: 'settings',
	}, {
		withDeclarativeSideEffectsSupport: true,
		onSuccess: ({ data }) => {
            setShowReloginModal(true);

		},
		onFailure: (error) => {
			notify(error.message, "error");
		}
    });    
    const permissions = usePermissions();
    const permissionsLocale = permissions.permissions ? permissions.permissions.locale : null;   
    
    React.useEffect(() => {
        if (permissionsLocale){
            setLocale(permissionsLocale);
        }
    }, [permissionsLocale, setLocale]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (key) => {        
        setAnchorEl(null);
        mutate(
			{
				payload: {
					id: permissions.permissions.id,
					data: { locale: key },
				}
			},
		);
    };

    const handleRelogin = () => {
        logout();
    };

    const handleCloseModal = () => {
        setShowReloginModal(false);
    };
    return (
        <React.Fragment>		
            <IconButton color="primary" component="span" onClick={handleClick}>
                {dataLocale[locale].icon}
            </IconButton>
            <Menu		  
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(dataLocale).map((key, i) => (
                    <MenuItem key={key} disabled={locale === key} onClick={() => handleMenuItemClick(key)}>
                        <ListItemIcon className={classes.listItemIcon}>{dataLocale[key].icon}</ListItemIcon>
                    </MenuItem>
                ))}          
            </Menu>
            <Confirm
				isOpen={showReloginModal}
				content="osfc.dialogs.language_change.message"
				title="osfc.dialogs.language_change.title"
                confirm="Logout"
                cancel="Cancel"
				onConfirm={handleRelogin}
				onClose={handleCloseModal}
			/>
        </React.Fragment>
    );
}
