import React from "react";
import { Create } from 'react-admin';

import { useMutateForm } from '../../../api/useMutateForm';
import { SimpleFormAdv } from "../../../components/forms/SimpleFormAdv";
import { 
	SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar 
} from '../../../components/toolbars/SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar';

import TaskForm from '../forms/TaskForm';


export const TaskCreate = (props) => {
    const [mutateForm, state] = useMutateForm("create", 
        props.resource, props.basePath, null, {});

    return (
        <Create {...props} >
            <SimpleFormAdv 
                save={mutateForm}
                toolbarComp={SaveButtonWithSaveToEditWithCancelWithCopyDocumentsToolbar} 
            >
                <TaskForm />
            </SimpleFormAdv>
        </Create>
    );
};
