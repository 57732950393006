import React from "react";

import Grid from '@material-ui/core/Grid';

import { useSelector } from 'react-redux';
import { TextInput, ReferenceInput, SelectInput, usePermissions, maxLength, useDataProvider, useTranslate } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import { required } from '../../../utils/validation/required';
import { requiredIfNoFieldValue } from '../../../utils/validation/requiredIfNoFieldValue';

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { DateInput, TimeInput, NumberInputEx } from '../../../components/inputs';
import { SelectUppercaseInput, SelectDisabledInput } from '../../../components/inputs/SelectUppercaseInput';
import { YesNoInput } from '../../../components/inputs/YesNoInput';
import { SelectTextInput } from '../../../components/inputs/SelectTextInput';
import { PhoneInput } from '../../../components/inputs';
import { formatPhoneNumber } from '../../../components/fields/PhoneField';

import { TransporterInput } from './inputs/TransporterInput';
import { OrderReferenceInput } from '../../../components/inputs/OrderReferenceInput';
import { TripNumberInput } from './inputs/TripNumberInput';
import { LoadingOrderInput } from './inputs/LoadingOrderInput';
import { PrintedDocsInput } from "./inputs/PrintedDocsInput";

import { 
	OrderService, OrderStatus, 
	OrderStatusCustomerEdit, OrderStatusCustomerEditOrder, OrderStatusEditOrderTerminal,
	OrderStatusTransporterEditOrder_1, OrderStatusTransporterEditOrder_2,
	UserRoleByCompanyType
} from '../../../entities';

import { ServiceInput } from './inputs/ServiceInput';
import { CommodityInput } from './inputs/CommodityInput';

import { 
	ETAETDFormGroup, 
	DangerTextInputForInternalNotes,
	useDriverChange,
	use3dPartyChange,
	useEtaEtdSync, 
	etaEtdPorts
} from './shared';
import { PortInput } from "../../../components/inputs/PortInput";
import { validatePlaceLength, validateArea } from "../../../utils/validation/order";

import { LabelSource } from "../../../entities/LabelSource";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";

const allowFieldsEtaTrans = ["eta_date", "eta_time", "eta_driver", "eta_driver_id", "eta_driver_phone", "eta_truck", "eta_truck_id", "eta_trailer", "eta_trailer_id"];
const allowFieldsEtdTrans = ["etd_date", "etd_time", "etd_driver", "etd_driver_id", "etd_driver_phone", "etd_truck", "etd_truck_id", "etd_trailer", "etd_trailer_id"];

export const ETAETDBlock = ({ isAddMode, prefix, ...props }) => {
	const { values } = useFormState();
	// const companyId = values.company_id || props.companyId;
	const handleDriverChange = useDriverChange(prefix);
	const [handle3dPartyChangeOnTruck, handle3dPartyChangeOnTrailer] = use3dPartyChange(prefix);
	const handleEtaEtdSync = useEtaEtdSync(prefix);
	// const drivers = useSelector(driversSelector);

	const isDisabledForEditing = !!values[`${prefix}_3rd_party`];
	const isIntoPlukkStorage = values.service === OrderService.intoPlukkStorage;

	const dateRequiredValueKeyPrefix = prefix === 'eta' ? 'etd' : 'eta';
	const portKey = etaEtdPorts[prefix];

	// const currentDriver = drivers[values[`${prefix}_driver_id`]];
	// const currentTransportFilter = { is_deleted: false, is_customer: true };
	// if (currentDriver !== undefined) {
	// 	currentTransportFilter.company_id = currentDriver.company_id;
	// };
	const currentTransportFilter = {
		no_range: true,
		is_deleted: false,
		company_id: props.permissions.company_id || 0
	};
	const currentTransportSort = { field: "name", order: "ASC" };

	const currentDriverFilter = {	
		no_range: true,	
		is_blocked: false,
		is_deleted: false,
		company_id: props.permissions.company_id || 0,
	};
	const currentDriverSort = { field: "first_name", order: "ASC" };

	const byTerminalFilter = { terminal_id: [values["terminal_id"]] };
    // const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(props.permissions.role) == -1;
	const placePrefix = prefix === "eta" ? "from" : "to";

	const handle3dPartyChange = (e) => {
		handle3dPartyChangeOnTruck(e);
		handle3dPartyChangeOnTrailer(e);
	};

	return (
		<ETAETDFormGroup {...props} prefix={prefix}>
			<FormGroupGrid>
				<GridInput xs={6} sm={3} component={DateInput} source={`${prefix}_date`} validate={[isIntoPlukkStorage ? requiredIfNoFieldValue(`${dateRequiredValueKeyPrefix}_date`) : required()]} onChange={handleEtaEtdSync} />
				<GridInput xs={6} sm={3} component={TimeInput} source={`${prefix}_time`} />
				<GridInput xs={6} sm={3} component={TextInput} source={`${prefix}_slot_time`} disabled />
				<GridInput xs={6} sm={3} component={PortInput} source={portKey} 
						   hidden={isAddMode} filter={byTerminalFilter} disabled />
			</FormGroupGrid>
			<FormGroupGrid>
				<GridInput xs={12} sm={5} md={5} component={TransporterInput} source={`${prefix}_3rd_party`} prefix={prefix} onChange={handle3dPartyChange} />
				<GridInput  xs={12} sm={5} md={5} component={TextInput} source={`place_${placePrefix}`} validate={[validatePlaceLength]} />
				<GridInput xs={6} sm={2} md={2} component={YesNoInput} source={`${prefix}_show_doc_for_trans`} 
					label={"resources.orders.fields." + prefix + "_show_doc"} defaultValue={true} disabled={!isDisabledForEditing}/>
				<GridInput xs={12} sm={6} md={5} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
								 optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`} 
								 filter={currentDriverFilter} sort={currentDriverSort} disabled={isDisabledForEditing}
								 validate={[maxLength(64)]} onChange={handleDriverChange} /> 
				<GridInput xs={12} sm={6} md={5} component={PhoneInput} source={`${prefix}_driver_phone`} disabled={isDisabledForEditing}/>
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_truck`} sourceSelect={`${prefix}_truck_id`}
								 label={"resources.orders.fields." + prefix + "_truck"}  reference="trucks" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_trailer`} sourceSelect={`${prefix}_trailer_id`} 
								 label={"resources.orders.fields." + prefix + "_trailer"} reference="trailers" source3rdParty={`${prefix}_3rd_party`}
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
			</FormGroupGrid>
		</ETAETDFormGroup>
	);
}


export const FormCustomer = ({isAddMode, ...props}) => {
	const form = useForm();
	const { permissions } = usePermissions();
	const { values } = useFormState();
	const dataProvider = useDataProvider();
	const translate = useTranslate()

	React.useEffect(()=>{
		if (isAddMode && permissions && permissions.company_id){
			dataProvider.getOne("customer-companies", { id: permissions.company_id})
			.then((data) => {
				form.change("commodity", data.data.commodity);
				// form.change("area_id", data.data.area_id);
				form.change("plumb_seal", data.data.plumb_seal);
				form.change("terminal_id", data.data.terminal_id);
				form.change("is_copy_documents", data.data.copy_docs_on_clone)
				// form.change("set_order_completed", data.data.set_order_completed);
			});
		}
	}, [isAddMode, permissions])

	if (!permissions) return null;

	// const statusDisabled = OrderStatusCustomerEdit.indexOf(props.record.status) === -1;
	const isTerminalDisabled = values["status"] ? 
		!OrderStatusEditOrderTerminal.includes(values["status"]) : false;

	let statusDisabled;
	let enabledFields;
	let otherFieldsDisabled = false;

	if (!isAddMode){
		const status = props.record.status;
		if (OrderStatusCustomerEditOrder.indexOf(status) !== -1) {
			// otherFieldsDisabled = true;
			statusDisabled = OrderStatusCustomerEdit.indexOf(status) === -1;
		} else if (OrderStatusTransporterEditOrder_2.indexOf(status) !== -1){
			enabledFields = {
				notes: true, internal_notes: true
			};
			const eta_3rd_party = props.record.eta_3rd_party;
			if (!eta_3rd_party) {
				for (const key of allowFieldsEtaTrans) {
					enabledFields[key] = true;
				}				
			}
			const etd_3rd_party = props.record.etd_3rd_party;
			if (!etd_3rd_party) {
				for (const key of allowFieldsEtdTrans) {
					enabledFields[key] = true;
				}				
			}
			if (OrderStatusTransporterEditOrder_1.indexOf(status) !== -1) {
				enabledFields["loading_order"] = true;
			}
		} else if (status === OrderStatus.ORDER_COMPLETED || status === OrderStatus.DELETED){
			enabledFields = {};
		} else {
			enabledFields = {notes: true, internal_notes: true};
		}
	} else {
		// otherFieldsDisabled = true;
	}
	const handleLabelSourceChange = (event) =>{
		const val = event.target.value
		if(val == LabelSource.pallets){
			form.change("label", values.pallets)
		} else if(val == LabelSource.boxes){
			form.change("label", values.boxes)
		}
	};

	const onTerminalChange = val => {
		form.change("port_in", null);
		form.change("port_out", null);
		form.change("area_id", null);
		form.change("is_area_blocked",  false);
	};

	const byTerminalFilter = { terminal_id: [values["terminal_id"]] };

	return (
		<GridForm {...props} enabledFields={enabledFields}>
			<FormGroupGrid>
				<GridInput source="reference" sm={3} component={OrderReferenceInput} validate={[required()]} autoFocus />
				<GridInput source="service" sm={3} component={ServiceInput} validate={[required()]} defaultValue={OrderService.reloadCarCar} />
				<GridInput sm={3} hidden={isAddMode} disabled={statusDisabled} validate={statusDisabled ? undefined : [required()]}>
					<ReferenceInput source="status" reference="order-statuses">
						<SelectDisabledInput allowedIds={OrderStatusCustomerEdit} optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput sm={1} component={YesNoInput} source="priority" defaultValue={false} />
				{/* <GridInput sm={1} component={YesNoInput} source="set_order_completed" defaultValue={false} /> */}
				<GridInput sm={1} >
					<ReferenceInput source="terminal_id" reference="terminal" disabled={isTerminalDisabled} validate={[required()]} onChange={onTerminalChange} >
						<SelectInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				{/* <GridInput sm={2} component={LoadNoInput} source="load_no" /> */}
			</FormGroupGrid>
			
			{/* <Grid item xs={12} md={10} lg={6}> */}
				<ETAETDBlock xs={12} md={10} lg={6} prefix="eta" permissions={permissions} isAddMode={isAddMode} {...props} enabledFields={enabledFields} />
			{/* </Grid>
			<Grid item xs={12} md={10} lg={6}> */}
				<ETAETDBlock xs={12} md={10} lg={6} prefix="etd" permissions={permissions} isAddMode={isAddMode} {...props} enabledFields={enabledFields} />
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={4}> */}
				<FormGroupGrid xs={12} md={6} lg={4} title={translate("resources.orders.block-label.split-loads")}>
					<GridInput xs={4} component={YesNoInput} source="split_load" defaultValue={false} />
					<GridInput xs={4} component={TripNumberInput} source="trip_number" />
					<GridInput xs={4} component={LoadingOrderInput} source="loading_order" />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={4}> */}
				<FormGroupGrid xs={12} md={6} lg={4} title={translate("resources.orders.block-label.plumb-seal")}>
					<GridInput xs={6} sm={4} component={YesNoInput} source="plumb_seal" defaultValue={false} />
					<GridInput xs={6} sm={8} component={TextInput} source="plumb_number" />
				</FormGroupGrid>
			{/* </Grid> */}
			
			<FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.storage-allocation")}>
					<GridInput xs={8} sm={6} disabled>
						<ReferenceInput source="area_id" reference="areas" filter={{ ...byTerminalFilter, disabled: false }} allowEmpty validate={validateArea}>
							<SelectInput optionText="name" disableValue="disabled_by_any" />
						</ReferenceInput>
					</GridInput>
					<GridInput xs={4} sm={4} component={YesNoInput} source="is_area_blocked" disabled />
					<GridInput xs={6} sm={6} component={SelectEnumInput} onChange={handleLabelSourceChange} options={{ format: "" }} source="label_source" key={"label_source"} enumName="label_source" enumObj={LabelSource}/>
					<GridInput xs={6} sm={6} component={NumberInputEx} options={{ format: "" }} source="label" disabled={!values["label_source"]} />
			</FormGroupGrid>
			
			<FormGroupGrid>
				<GridInput xs={12} sm={6} md={3} hidden={values.service === OrderService.reloadCarCar} disabled={otherFieldsDisabled}>
					<ReferenceInput source="cooler_type_id" reference="cooler-types" filter={{ order_service: values.service }}>
						<SelectUppercaseInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={6} md={3} component={PrintedDocsInput} source="printed_doc" defaultValue={false} disabled={otherFieldsDisabled} />
				<GridInput xs={6} sm={6} md={3} component={CommodityInput} source="commodity" disabled={otherFieldsDisabled} />
				<GridInput xs={6} sm={6} md={3} component={YesNoInput} source="is_doc_for_nfsa" defaultValue={false} disabled={otherFieldsDisabled} />
			</FormGroupGrid>

			{/* <DimensionsBlock {...props} /> */}
			<FormGroupGrid xs={12} lg={6}>
				<GridInput xs={4} component={NumberInputEx} source="pallets" min={0} max={100} step={10} disabled={otherFieldsDisabled} />
				<GridInput xs={4} component={NumberInputEx} source="boxes" min={0} max={100} step={5} disabled={otherFieldsDisabled} />
				<GridInput xs={4} component={NumberInputEx} type="float" digitsCount={2} source="kilos" min={0} max={1000} step={100} disabled={otherFieldsDisabled} />
			</FormGroupGrid>

			<FormGroupGrid>
				<GridInput sm={12} md={6} component={TextInput} source="notes" multiline rows={6} validate={[maxLength(1024)]} />
				<GridInput sm={12} md={6} className="red-border" component={DangerTextInputForInternalNotes} source="internal_notes"
						   multiline rows={6} validate={[maxLength(1000)]} />
			</FormGroupGrid>
		</GridForm>
	);
};
