import React from 'react';
import { FunctionField } from 'react-admin';

import { UserTypeNames } from '../../entities/UserType';


const renderType = (record, source) => {
	return UserTypeNames[record[source]];
}

export const UserTypeField = (props) => {
	return (
		<FunctionField {...props} render={renderType} />
	);
};


UserTypeField.defaultProps = {
    addLabel: true,
};
