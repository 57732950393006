import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { FunctionField, TextField, useNotify, useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	button: {
		fontSize: 'inherit',
	},
});

function copyText(text, notify, translate) {
	if (navigator.clipboard) {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				notify(translate('osfc.buttons.copy.success'), 'info');
			})
			.catch(() => {
				notify(
					translate('osfc.buttons.copy.error.is_not_supported'),
					'error'
				);
			});
	} else {
		notify(translate('osfc.buttons.copy.error.is_not_supported'), 'error');
	}
}

export const CopyButtonWrapper = ({
	children,
	text,
	fontSizeInherited,
	...props
}) => {
	const classes = useStyles();
	const notify = useNotify();
	const translate = useTranslate();

	const handleClick = () => {
		copyText(text, notify, translate);
	};

	return (
		<>
			<Tooltip title="Copy">
				<Button
					classes={
						fontSizeInherited ? { root: classes.button } : undefined
					}
					onClick={handleClick}
				>
					{React.cloneElement(children, props)}
				</Button>
			</Tooltip>
		</>
	);
};

export const CopyField = ({ source, FieldComponent = TextField, ...props }) => (
	<FunctionField
		render={(record) => (
			<CopyButtonWrapper text={record[source]}>
				<FieldComponent record={record} source={source} {...props} />
			</CopyButtonWrapper>
		)}
	/>
);
