import React from 'react';

import {
	TopToolbar, EditButton, ShowButton, 
	CloneButton, DeleteButton, useRedirect, 
	useTranslate, usePermissions
} from 'react-admin';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { MuiButtonMainProps } from '../_helpers/props/MuiButtonProps';
import { OrderStatus , UserRole} from "../../entities";

import { ChangeCustomerButton } from '../dialogs/ChangeCustomerDialog';


export const ActionsWithBackButton = (props) => {
	const {
		basePath,
		data,
		hasList,
		hasEdit,
		hasShow,
		
		hasChange,
		cloneMutableValues,
		ifCustomerTransporterOrderEdit,
		redirectPath,
		ExtraActionButtons = null,
	} = props;
	let {
		hasClone,
		hasDelete,
	} = props;
	const redirect = useRedirect();
	const t = useTranslate();
	const {permissions} = usePermissions();

	if (!permissions || !data) return null;

	if (ifCustomerTransporterOrderEdit){
		if (permissions.company_id === data.company_id) {
			hasDelete = (record) => record?.status === OrderStatus.BOOKED;
			hasClone = true;
		}
	}
	//  TODO - Uncomment and correct after configuration access to orders for admin 
	// if (hasChange){
	// 	if (permissions.role !== UserRole.osfc_manager){
	// 		hasChange = false
	// 	}
	// }
	return (
		<TopToolbar>
			{hasList && <Box display="flex">
				<Button startIcon={<ArrowBack/>} {...MuiButtonMainProps} size="small"
					onClick={e => redirect(redirectPath || basePath)}>
					{t('ra.action.back')}
				</Button>
			</Box>}
			{hasEdit && <Box ml={1} display="flex">
				<EditButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasShow && <Box ml={1} display="flex">
				<ShowButton variant="outlined" basePath={basePath} record={data}/>
			</Box>}
			{hasClone && <Box ml={1} display="flex">
				<CloneButton variant="outlined" basePath={basePath} record={{...data, ...cloneMutableValues}}/>
			</Box>}
			{hasChange && <Box ml={1} display="flex">
				<ChangeCustomerButton userId={permissions.id} record={data} />
			</Box>}
			{hasDelete && (hasDelete instanceof Function ? hasDelete(data) : hasDelete) && <Box ml={1} display="flex">
				<DeleteButton undoable={false} basePath={basePath} record={data}/>
			</Box>}
			{ExtraActionButtons && <ExtraActionButtons {...props} />}
			<Box display="flex" flex="1"/>
		</TopToolbar>
	);
};
