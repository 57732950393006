import React from 'react';

import { Filter } from 'react-admin';

import { ToggleTerminalsFilter } from '../../../components/filters/ToggleTerminalsFilter';
import { ToggleDatesFilter } from './ToggleDatesFilter';


export const NeedOfWorkforceFilter = (props) => (
	<Filter {...props}>
		<ToggleDatesFilter source="eta_date|etd_date" defaultValue={null} alwaysOn key={"eta_date|etd_date"}/>
		<ToggleTerminalsFilter source="terminal_id" exclude_all={true} defaultValue={null} alwaysOn key={"terminal_id"}/>
	</Filter>
);
