import React from 'react';

import { Show, TextField, ReferenceField, SimpleShowLayout } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { AppManagerRoles, UserRole } from '../../../entities/';
import { isInArray } from '../../../utils/general';


export const TrucksShow = ({permissions, ...props}) => {
	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<Show actions={<ActionsWithBackButton />} {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				{isAdminOrOSFCManager && <ReferenceField source="company_id" reference="companies" link={false}>
					<TextField source="full_name" />
				</ReferenceField>}
                <TextField source="name" />
			</SimpleShowLayout>
		</Show>
	);
};
